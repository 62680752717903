import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { TextFormat, Translate, translate } from 'react-jhipster';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Card,
  Col,
  Row,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  UncontrolledAccordion,
  Table,
  Spinner,
} from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { getEntitiesByObjectType as getCustomPropertiesByObjectType } from 'app/entities/custom-attribute-definition/custom-attribute-definition.reducer';
import { AuditEntityName } from 'app/shared/model/audit.model';
import { CustomAttributeDtoKey } from 'app/shared/model/custom-attribute-definition.model';
import { CustomAttributeApplicableObjectType } from 'app/shared/model/enumerations/custom-attribute-applicable-object-type.model';
import { findCustomAttributeValueByKey } from 'app/shared/util/entity-utils';
import AuditModal from '../audit/audit-modal';
import { getEntity } from './employee.reducer';
import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import Details from './details-components/details';
import AccessRights from './details-components/access-rights';
import { convertDateFormat } from 'app/shared/util/date-utils';

const tabComponents = {
  Details: React.lazy(() => import('./details-components/details')),
  AccessRights: React.lazy(() => import('./details-components/access-rights')),
};

export const EmployeeDetail = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
    dispatch(getCustomPropertiesByObjectType(CustomAttributeApplicableObjectType.EMPLOYEE));
  }, [id]);

  const employeeEntity = useAppSelector(state => state.employee.entity);
  const customAttributeDefinitions: CustomAttributeDtoKey[] = useAppSelector(state => state.customAttributeDefinition.entities);
  const locale = useAppSelector(state => state.locale.currentLocale);

  const [auditModal, setAuditModal] = useState<boolean>(false);
  const toggleAuditModal = () => {
    setAuditModal(!auditModal);
  };

  return (
    <React.Fragment>
      <Row>
        <Col md="3">
          <Card className="jh-card">
            <h2 data-cy="employeeDetailsHeading">
              <Translate contentKey="iamdentityApp.employee.detail.title">Employee</Translate>
            </h2>
            <dl className="jh-entity-details">
              <dt>
                <span id="id">
                  <Translate contentKey="global.field.id">ID</Translate>
                </span>
              </dt>
              <dd>{employeeEntity.id}</dd>
              <dt>
                <span id="code">
                  <Translate contentKey="iamdentityApp.employee.code">Code</Translate>
                </span>
              </dt>
              <dd>{employeeEntity.code}</dd>
              <dt>
                <span id="contractStartDate">
                  <Translate contentKey="iamdentityApp.employee.contractStartDate">Contract Start Date</Translate>
                </span>
              </dt>
              <dd>
                {employeeEntity.contractStartDate ? (
                  <TextFormat value={employeeEntity.contractStartDate} type="date" format={convertDateFormat(locale, 'date')} />
                ) : null}
              </dd>
              <dt>
                <span id="contractEndDate">
                  <Translate contentKey="iamdentityApp.employee.contractEndDate">Contract End Date</Translate>
                </span>
              </dt>
              <dd>
                {employeeEntity.contractEndDate ? (
                  <TextFormat value={employeeEntity.contractEndDate} type="date" format={convertDateFormat(locale, 'date')} />
                ) : null}
              </dd>
              <dt>
                <span id="employeeStatus">
                  <Translate contentKey="iamdentityApp.employee.employeeStatus">Employee Status</Translate>
                </span>
              </dt>
              <dd>{employeeEntity.employeeStatus}</dd>
              <dt>
                <Translate contentKey="iamdentityApp.employee.organizationUnit">Organization Unit</Translate>
              </dt>
              <dd>{employeeEntity.organizationUnit ? employeeEntity.organizationUnit.name : ''}</dd>
              <dt>
                <Translate contentKey="iamdentityApp.employee.job">Job</Translate>
              </dt>
              <dd>{employeeEntity.job ? employeeEntity.job.name : ''}</dd>
              <dt>
                <Translate contentKey="iamdentityApp.employee.superior">Superior</Translate>
              </dt>
              <dd>{employeeEntity.superior ? employeeEntity.superior.code : ''}</dd>
              <dt>
                <Translate contentKey="iamdentityApp.employee.location">Location</Translate>
              </dt>
              <dd>{employeeEntity.location ? employeeEntity.location.name : ''}</dd>
              <dt>
                <Translate contentKey="iamdentityApp.employee.person">Person</Translate>
              </dt>
              <dd>{employeeEntity.person ? <Link to={`/person/${employeeEntity.person.id}`}>{employeeEntity.fullName}</Link> : ''}</dd>
              {customAttributeDefinitions?.map(cad => (
                <React.Fragment key={cad.id}>
                  <dt>
                    <span id={cad.displayName}>{cad.displayName}</span>
                  </dt>
                  <dd>{findCustomAttributeValueByKey(cad, employeeEntity)}</dd>
                </React.Fragment>
              ))}
              <dt>
                <Translate contentKey="global.field.createdBy">Created By</Translate>
              </dt>
              <dd>{employeeEntity.createdBy ? employeeEntity.createdBy : ''}</dd>
              <dt>
                <Translate contentKey="global.field.createdDate">Created Date</Translate>
              </dt>
              <dd>
                {employeeEntity.createdDate ? (
                  <TextFormat value={employeeEntity.createdDate} type="date" format={convertDateFormat(locale, 'date')} />
                ) : null}
              </dd>
              <dt>
                <Translate contentKey="global.field.lastModifiedBy">Last Modified By</Translate>
              </dt>
              <dd>{employeeEntity.lastModifiedBy ? employeeEntity.lastModifiedBy : ''}</dd>
              <dt>
                <Translate contentKey="global.field.lastModifiedDate">Last Modified Date</Translate>
              </dt>
              <dd>
                {employeeEntity.lastModifiedDate ? (
                  <TextFormat value={employeeEntity.lastModifiedDate} type="date" format={convertDateFormat(locale, 'date')} />
                ) : null}
              </dd>
            </dl>
            <div className="btn-group flex-btn-group-container flex-wrap">
              <Button id="cancel-save" data-cy="entityDetailsBackButton" onClick={() => navigate(-1)} color="info">
                <FontAwesomeIcon icon="arrow-left" />{' '}
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button tag={Link} to={`/employee/${employeeEntity.id}/edit`} color="primary">
                <FontAwesomeIcon icon="pencil-alt" />{' '}
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.edit">Edit</Translate>
                </span>
              </Button>
              <Button onClick={toggleAuditModal} color="light">
                <FontAwesomeIcon icon={faClockRotateLeft} />{' '}
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.history">History</Translate>
                </span>
              </Button>
            </div>
          </Card>
        </Col>

        <Col md="9">
          <EmployeeDetailTabs id={id} />
        </Col>
      </Row>
      <AuditModal isOpen={auditModal} toggle={toggleAuditModal} entityId={id} entityName={AuditEntityName.EMPLOYEE} />
    </React.Fragment>
  );
};

const EmployeeDetailTabs = ({ id }) => {
  const dispatch = useAppDispatch();
  const [currentActiveTab, setCurrentActiveTab] = useState<string>('details');
  const [isActiveTab, setActiveClass] = useState(false);

  // Toggle active state for Tab
  const toggle = (tab: string, isActiveTab: boolean) => {
    if (currentActiveTab !== tab) {
      setCurrentActiveTab(tab);
      setActiveClass(!isActiveTab);
    }
  };

  return (
    <React.Fragment>
      <div className="content-container ml-1">
        <Nav tabs className="justify-content-center">
          <NavItem key="details-nav-item">
            <NavLink
              key="details-tab"
              className={currentActiveTab == 'details' ? 'active' : ''}
              onClick={() => {
                toggle('details', true);
              }}
            >
              <Translate contentKey={`iamdentityApp.employee.details`}>Tab</Translate>
            </NavLink>
          </NavItem>
          <NavItem key="access-rights-nav-item">
            <NavLink
              key="access-rights-tab"
              className={currentActiveTab == 'accessRights' ? 'active' : ''}
              onClick={() => {
                toggle('accessRights', true);
              }}
            >
              <Translate contentKey={`iamdentityApp.employee.accessRights`}>Tab</Translate>
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent className="tab-content" activeTab={currentActiveTab}>
          <React.Suspense
            fallback={
              <div className="entity-spinner-container">
                <Spinner color="primary">Loading...</Spinner>
              </div>
            }
          >
            <TabPane key={'0'} tabId="details">
              <Details employeeId={Number(id)} />
            </TabPane>
            <TabPane key={'1'} tabId="accessRights">
              <AccessRights employeeId={Number(id)} />
            </TabPane>
          </React.Suspense>
        </TabContent>
      </div>
    </React.Fragment>
  );
};

export default EmployeeDetail;
