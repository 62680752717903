/* eslint-disable react/jsx-key */
import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { convertDateFormat } from 'app/shared/util/date-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntitiesByObjectType as getCustomPropertiesByObjectType } from 'app/entities/custom-attribute-definition/custom-attribute-definition.reducer';
import { CustomAttributeDtoKey } from 'app/shared/model/custom-attribute-definition.model';
import { CustomAttributeApplicableObjectType } from 'app/shared/model/enumerations/custom-attribute-applicable-object-type.model';
import { findCustomAttributeValueByKey } from 'app/shared/util/entity-utils';

import { getEntity } from './recertification-session-user-group.reducer';

export const RecertificationSessionUserGroupDetail = () => {
  const dispatch = useAppDispatch();

  const { recertificationSessionUserGroupId: id } = useParams<'recertificationSessionUserGroupId'>();

  useEffect(() => {
    dispatch(getEntity(id));
    dispatch(getCustomPropertiesByObjectType(CustomAttributeApplicableObjectType.USER_GROUP));
  }, []);

  const recertificationSessionUserGroupEntity = useAppSelector(state => state.recertificationSessionUserGroup.entity);
  const customAttributeDefinitions: CustomAttributeDtoKey[] = useAppSelector(state => state.customAttributeDefinition.entities);
  const locale = useAppSelector(state => state.locale.currentLocale);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="recertificationSessionUserGroupDetailsHeading">
          <Translate contentKey="iamdentityApp.recertificationSessionUserGroup.detail.title">Recertification Session User Group</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <Translate contentKey="global.field.id">ID</Translate>:
          </dt>
          <dd>{recertificationSessionUserGroupEntity.id}</dd>
          <dt>
            <Translate contentKey="iamdentityApp.recertificationSession.detail.title">Recertification Session</Translate>:
          </dt>
          <dd>
            {recertificationSessionUserGroupEntity.recertificationSession
              ? recertificationSessionUserGroupEntity.recertificationSession.name
              : ''}
          </dd>
          <dt>
            <Translate contentKey="iamdentityApp.recertificationSessionUserGroup.application">Application</Translate>:
          </dt>
          <dd>
            {recertificationSessionUserGroupEntity.userGroup ? (
              <Link
                to={`/application/${
                  recertificationSessionUserGroupEntity.userGroup.roleGroup
                    ? recertificationSessionUserGroupEntity.userGroup.roleGroup.application.id
                    : ''
                }`}
              >
                {recertificationSessionUserGroupEntity.userGroup.roleGroup
                  ? recertificationSessionUserGroupEntity.userGroup.roleGroup.application.name
                  : ''}
              </Link>
            ) : (
              ''
            )}
          </dd>
          <dt>
            <Translate contentKey="iamdentityApp.recertificationSessionUserGroup.roleGroup">Role Group</Translate>:
          </dt>
          <dd>
            {recertificationSessionUserGroupEntity.userGroup ? (
              <Link
                to={`/role-group/${
                  recertificationSessionUserGroupEntity.userGroup.roleGroup
                    ? recertificationSessionUserGroupEntity.userGroup.roleGroup.id
                    : ''
                }`}
              >
                {recertificationSessionUserGroupEntity.userGroup.roleGroup
                  ? recertificationSessionUserGroupEntity.userGroup.roleGroup.name
                  : ''}
              </Link>
            ) : (
              ''
            )}
          </dd>
          <dt>
            <Translate contentKey="iamdentityApp.recertificationSessionUserGroup.status">Status</Translate>:
          </dt>
          <dd>{recertificationSessionUserGroupEntity.userGroup ? recertificationSessionUserGroupEntity.userGroup.status : ''}</dd>
          <dt>
            <Translate contentKey="iamdentityApp.recertificationSessionUserGroup.grantedBy">Granted By</Translate>:
          </dt>
          <dd>{recertificationSessionUserGroupEntity.userGroup ? recertificationSessionUserGroupEntity.userGroup.grantedBy : ''}</dd>
          <dt>
            <Translate contentKey="iamdentityApp.recertificationSessionUserGroup.validFrom">Valid From</Translate>:
          </dt>
          <dd>
            {recertificationSessionUserGroupEntity.userGroup?.startDate ? (
              <TextFormat
                value={recertificationSessionUserGroupEntity.userGroup.startDate}
                type="date"
                format={convertDateFormat(locale, 'instant')}
              />
            ) : (
              ''
            )}
          </dd>
          <dt>
            <Translate contentKey="iamdentityApp.recertificationSessionUserGroup.validTo">Valid To</Translate>:
          </dt>
          <dd>
            {recertificationSessionUserGroupEntity.userGroup?.endDate ? (
              <TextFormat
                value={recertificationSessionUserGroupEntity.userGroup.endDate}
                type="date"
                format={convertDateFormat(locale, 'instant')}
              />
            ) : (
              ''
            )}
          </dd>
          {recertificationSessionUserGroupEntity.revoked !== null && recertificationSessionUserGroupEntity.revoked !== undefined ? (
            <div>
              <dt>
                <Translate contentKey="iamdentityApp.recertificationSessionUserGroup.revoked">Revoked</Translate>:
              </dt>
              <dd>
                {recertificationSessionUserGroupEntity.revoked === true ? (
                  recertificationSessionUserGroupEntity.order ? (
                    <div>
                      <Translate contentKey="iamdentityApp.recertificationSessionUserGroup.revokedByOrder">Revoked by Order</Translate>
                      :&nbsp;
                      <Link to={`/orders/${recertificationSessionUserGroupEntity.order.id}`}>
                        {recertificationSessionUserGroupEntity.order.id}
                      </Link>
                      &nbsp;
                      {recertificationSessionUserGroupEntity?.order?.status ? (
                        <span className="fw-bold text-white d-inline-block">({recertificationSessionUserGroupEntity?.order?.status})</span>
                      ) : (
                        ''
                      )}
                    </div>
                  ) : (
                    <Translate contentKey="iamdentityApp.recertificationSessionUserGroup.revokedByOrder">Revoked by Order</Translate>
                  )
                ) : (
                  <Translate contentKey="iamdentityApp.recertificationSessionUserGroup.confirmed">Confirmed</Translate>
                )}
              </dd>
            </div>
          ) : null}
          {customAttributeDefinitions?.map(cad => (
            <React.Fragment>
              <dt>
                <span id={cad.displayName}>{cad.displayName}</span>
              </dt>
              <dd>
                {recertificationSessionUserGroupEntity.userGroup
                  ? findCustomAttributeValueByKey(cad, recertificationSessionUserGroupEntity.userGroup)
                  : ''}
              </dd>
            </React.Fragment>
          ))}
          <dt>
            <Translate contentKey="global.field.createdBy">Created By</Translate>:
          </dt>
          <dd>{recertificationSessionUserGroupEntity.createdBy ? recertificationSessionUserGroupEntity.createdBy : ''}</dd>
          <dt>
            <Translate contentKey="global.field.createdDate">Created Date</Translate>:
          </dt>
          <dd>
            {recertificationSessionUserGroupEntity.createdDate ? (
              <TextFormat
                value={recertificationSessionUserGroupEntity.createdDate}
                type="date"
                format={convertDateFormat(locale, 'instant')}
              />
            ) : (
              ''
            )}
          </dd>
          <dt>
            <Translate contentKey="global.field.lastModifiedBy">Last Modify By</Translate>:
          </dt>
          <dd>{recertificationSessionUserGroupEntity.lastModifiedBy ? recertificationSessionUserGroupEntity.lastModifiedBy : ''}</dd>
          <dt>
            <Translate contentKey="global.field.lastModifiedDate">Last Modify Date</Translate>:
          </dt>
          <dd>
            {recertificationSessionUserGroupEntity.lastModifiedDate ? (
              <TextFormat
                value={recertificationSessionUserGroupEntity.lastModifiedDate}
                type="date"
                format={convertDateFormat(locale, 'instant')}
              />
            ) : (
              ''
            )}
          </dd>
        </dl>
        <Button
          tag={Link}
          to={`/recertification-session/${
            recertificationSessionUserGroupEntity.recertificationSession
              ? recertificationSessionUserGroupEntity.recertificationSession.id
              : ''
          }`}
          replace
          color="info"
          data-cy="entityDetailsBackButton"
        >
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default RecertificationSessionUserGroupDetail;
