import React from 'react';
import { Translate, translate } from 'react-jhipster';
import { NavDropdown } from './menu-components';
import { DropdownItem } from 'reactstrap';
import MenuItem from './menu-item';
import { faScrewdriverWrench } from '@fortawesome/free-solid-svg-icons';

export const ConfigurationData = ({ isAdmin = false }) => (
  <NavDropdown
    icon={faScrewdriverWrench}
    name={translate('global.menu.configuration')}
    id="entity-menu"
    data-cy="entity"
    className="max-height-80 overflow-a"
  >
    <DropdownItem className="menu-header" header>
      <Translate contentKey="global.menu.configuration" />
    </DropdownItem>
    <MenuItem icon="asterisk" to="/connector-type">
      <Translate contentKey="global.menu.entities.connectorType" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/iam-system">
      <Translate contentKey="global.menu.entities.iamSystem" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/application">
      <Translate contentKey="global.menu.entities.application" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/application-resource">
      <Translate contentKey="global.menu.entities.applicationResource" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/role-definition">
      <Translate contentKey="global.menu.entities.roleDefinition" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/role-group">
      <Translate contentKey="global.menu.entities.roleGroup" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/permission-definition">
      <Translate contentKey="global.menu.entities.permissionDefinition" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/custom-attribute-definition">
      <Translate contentKey="global.menu.entities.customAttributeDefinition" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/order-type">
      <Translate contentKey="global.menu.entities.orderType" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/order-approval-levels">
      <Translate contentKey="global.menu.entities.orderApprovalLevels" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/technical-order-action-create">
      <Translate contentKey="global.menu.entities.technicalOrderActionCreate" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/connector-command">
      <Translate contentKey="global.menu.entities.connectorCommand" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/connector-command-attribute">
      <Translate contentKey="global.menu.entities.connectorCommandAttribute" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/risk-rule">
      <Translate contentKey="global.menu.entities.riskRule" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/risk-combination">
      <Translate contentKey="global.menu.entities.riskCombination" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/email-template">
      <Translate contentKey="global.menu.entities.emailTemplate" />
    </MenuItem>
    {isAdmin && (
      <MenuItem icon="asterisk" to="/iamdentity-access-to-order-types">
        <Translate contentKey="global.menu.entities.iamDentityAccessToOrderTypes" />
      </MenuItem>
    )}
  </NavDropdown>
);
