import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Translate, translate, ValidatedField } from 'react-jhipster';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Button, Card, Col, Form, FormGroup, Label, Row, Spinner } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import {
  addCustomPropertiesToCustomAttributesMap,
  convertReactSelectValuesToEntityIds,
  extractCustomAttributesAsEntityProperties,
} from 'app/shared/util/entity-utils';

import { APP_GLOBAL_DATE_FORMAT } from 'app/config/constants';
import { getEntitiesByObjectType as getCustomPropertiesByObjectType } from 'app/entities/custom-attribute-definition/custom-attribute-definition.reducer';
import { CustomAttributeDtoKey } from 'app/shared/model/custom-attribute-definition.model';
import { CustomAttributeApplicableObjectType } from 'app/shared/model/enumerations/custom-attribute-applicable-object-type.model';
import { renderCustomAttributeField } from 'app/shared/util/component-utils';
import moment from 'moment';
import { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { createEntity, getEntity, reset, updateEntity, resetEntity } from './role-definition.reducer';

import { apiUrl as applicationUrl } from 'app/entities/application/application.reducer';
import PaginatedReactSelect from 'app/shared/components/react-select/paginated-react-select';
import { IRoleDefinition } from 'app/shared/model/role-definition.model';
import { buildSearchParamsObject } from 'app/shared/util/url-utils';
import { IApplication } from 'app/shared/model/application.model';

export const RoleDefinitionUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const [searchParamsObject, setSearchParamsObject] = useState(buildSearchParamsObject(searchParams));

  const { roleDefinitionUpdateId: id } = useParams<'roleDefinitionUpdateId'>();
  const isNew = id === undefined;

  const roleDefinitionEntity: IRoleDefinition = useAppSelector(state => state.roleDefinition.entity);
  const loading = useAppSelector(state => state.roleDefinition.loading);
  const updating = useAppSelector(state => state.roleDefinition.updating);
  const updateSuccess = useAppSelector(state => state.roleDefinition.updateSuccess);
  const customAttributeDefinitions: CustomAttributeDtoKey[] = useAppSelector(state => state.customAttributeDefinition.entities);
  const startDate = useRef('');
  const handleClose = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(resetEntity());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getCustomPropertiesByObjectType(CustomAttributeApplicableObjectType.ROLE_DEFINITION));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
      dispatch(resetEntity());
    }
  }, [updateSuccess]);

  useEffect(() => {
    formReset(defaultValues());
  }, [roleDefinitionEntity]);

  const saveEntity = values => {
    const entity = {
      ...roleDefinitionEntity,
      ...addCustomPropertiesToCustomAttributesMap(
        roleDefinitionEntity,
        convertReactSelectValuesToEntityIds(values),
        customAttributeDefinitions
      ),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () => {
    const result = isNew
      ? {
          startDate: moment().format(APP_GLOBAL_DATE_FORMAT),
          application: searchParamsObject['application']
            ? { id: searchParamsObject['application'].id, name: searchParamsObject['application'].name }
            : '',
        }
      : {
          ...roleDefinitionEntity,
          ...extractCustomAttributesAsEntityProperties(customAttributeDefinitions, roleDefinitionEntity),
          application: roleDefinitionEntity?.application,
        };
    startDate.current = result?.startDate;
    return result;
  };

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    reset: formReset,
  } = useForm({
    defaultValues: defaultValues(),
    mode: 'onChange',
  });

  const watchApplication: IApplication = watch('application');
  const watchStartDate: string = watch('startDate');
  const watchName: string = watch('name');

  const minEndDate = () => {
    if (searchParamsObject['application']?.startDate && searchParamsObject['application'].startDate >= watchStartDate) {
      return searchParamsObject['application'].startDate;
    }

    if (watchApplication?.startDate && watchApplication.startDate >= watchStartDate) {
      return watchApplication.startDate;
    }

    return watchStartDate;
  };

  return (
    <Card className="jh-card p-3">
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="iamdentityApp.roleDefinition.home.createOrEditLabel" data-cy="RoleDefinitionCreateUpdateHeading">
            <Translate contentKey="iamdentityApp.roleDefinition.home.createOrEditLabel">Create or edit a RoleDefinition</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading || updating ? (
            <div className="entity-spinner-container">
              <Spinner color="primary">Loading...</Spinner>
            </div>
          ) : (
            <Form onSubmit={handleSubmit(saveEntity)}>
              {!isNew ? (
                <ValidatedField
                  register={register}
                  name="id"
                  required
                  readOnly
                  id="role-definition-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                register={register}
                label={`* ${translate('iamdentityApp.roleDefinition.name')}`}
                required
                id="role-definition-name"
                name="name"
                data-cy="name"
                type="text"
              />
              <ValidatedField
                register={register}
                label={`*${translate('iamdentityApp.roleDefinition.code')}`}
                id="role-definition-code"
                name="code"
                data-cy="code"
                type="text"
                required
              />
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.roleDefinition.description')}
                id="role-definition-description"
                name="description"
                data-cy="description"
                type="text"
              />
              <FormGroup>
                <Label>
                  *
                  <Translate contentKey="iamdentityApp.roleDefinition.application" />
                </Label>
                <PaginatedReactSelect
                  control={control}
                  name="application"
                  validate={{ required: true }}
                  isDisabled={searchParamsObject['application'] ? true : false}
                  url={applicationUrl}
                  queryProps={{
                    name: 'name',
                    type: 'string',
                  }}
                />
              </FormGroup>
              <ValidatedField
                register={register}
                label={`*${translate('iamdentityApp.roleDefinition.startDate')}`}
                id="role-definition-startDate"
                name="startDate"
                data-cy="startDate"
                type="date"
                onChange={event => (startDate.current = event.target.value)}
                min={
                  searchParamsObject['application']?.startDate
                    ? searchParamsObject['application'].startDate
                    : watchApplication?.startDate
                    ? watchApplication?.startDate
                    : startDate.current
                }
                max={
                  searchParamsObject['application']?.endDate
                    ? searchParamsObject['application'].endDate
                    : watchApplication?.endDate
                    ? watchApplication.endDate
                    : null
                }
              />
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.roleDefinition.endDate')}
                id="role-definition-endDate"
                name="endDate"
                data-cy="endDate"
                type="date"
                min={minEndDate()}
                max={
                  searchParamsObject['application']?.endDate
                    ? searchParamsObject['application'].endDate
                    : watchApplication?.endDate
                    ? watchApplication.endDate
                    : null
                }
              />
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.roleDefinition.uniqueKey')}
                id="role-definition-uniqueKey"
                name="uniqueKey"
                data-cy="uniqueKey"
                type="text"
              />
              {customAttributeDefinitions?.map(cad =>
                renderCustomAttributeField({
                  register: register,
                  options: cad.selectableOptions,
                  id: `role-definition-${cad.displayName}`,
                  name: cad.displayName,
                  label: cad.displayName,
                  'data-cy': cad.displayName,
                  attributeType: cad.customAttributeType,
                })
              )}
              <Button id="cancel-save" data-cy="entityCreateCancelButton" onClick={() => navigate(-1)} color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </Form>
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default RoleDefinitionUpdate;
