import { INPUT_ENUM_SEPARATOR } from 'app/config/constants';
import React from 'react';
import { FieldValues, UseFormRegister } from 'react-hook-form';
import { ValidatedField, ValidatedFieldProps } from 'react-jhipster';
import { FormGroup, Input, Label } from 'reactstrap';
import { InputType } from 'reactstrap/types/lib/Input';
import { CustomAttributeType } from '../model/enumerations/custom-attribute-type.model';

interface IRenderAndRegisterCustomAttribute {
  attributeType: CustomAttributeType;
  name: string;
  label: string;
  register: UseFormRegister<FieldValues>;
  options?: string;
}

export const renderAndRegisterCustomAttributeField = ({
  attributeType,
  name,
  label,
  register,
  options,
}: IRenderAndRegisterCustomAttribute) => {
  let type: InputType = 'text';

  switch (attributeType) {
    case CustomAttributeType.ENUMERATION:
      type = 'select';
      break;
    case CustomAttributeType.BOOLEAN:
      type = 'checkbox';
      break;
    case CustomAttributeType.DATE:
      type = 'date';
      break;
    case CustomAttributeType.NUMBER:
      type = 'number';
      break;
    case CustomAttributeType.TEXT:
    default:
      type = 'text';
      break;
  }

  const optionsElements =
    attributeType === CustomAttributeType.ENUMERATION && options ? (
      <React.Fragment>
        <option value="" key="">
          Default
        </option>
        {options.split(INPUT_ENUM_SEPARATOR).map(op => (
          <option value={op} key={op}>
            {op}
          </option>
        ))}
      </React.Fragment>
    ) : null;

  const { ref: inputRef, ...inputProps } = register(name);

  return (
    <FormGroup key={`custom-attribute-${name}`}>
      <Label>{label}</Label>
      <Input type={type} {...inputProps} innerRef={inputRef}>
        {optionsElements}
      </Input>
    </FormGroup>
  );
};

export const renderCustomAttributeField = (
  props: ValidatedFieldProps & { attributeType: CustomAttributeType; register?: UseFormRegister<any>; options?: string; value?: string }
): JSX.Element => {
  let type: InputType = 'text';
  const defaultValue = props.value
    ? props.attributeType === CustomAttributeType.ENUMERATION && props.value.split('|').length > 1
      ? props.value.split('|').map(val => val)
      : props.value
    : null;

  switch (props.attributeType) {
    case CustomAttributeType.ENUMERATION:
      type = 'select';
      break;
    case CustomAttributeType.BOOLEAN:
      type = 'checkbox';
      break;
    case CustomAttributeType.DATE:
      type = 'date';
      break;
    case CustomAttributeType.NUMBER:
      type = 'number';
      break;
    case CustomAttributeType.TEXT:
    default:
      type = 'text';
      break;
  }

  const options =
    props.attributeType === CustomAttributeType.ENUMERATION && props.options ? (
      <React.Fragment>
        <option value="" key="">
          Default
        </option>
        {props.options.split(INPUT_ENUM_SEPARATOR).map(op => (
          <option value={op} key={op}>
            {op}
          </option>
        ))}
      </React.Fragment>
    ) : null;

  return (
    <ValidatedField
      key={props.name}
      register={props.register}
      label={props.label}
      id={props.id}
      name={props.name.replaceAll('.', '_#_')}
      data-cy={props['data-cy']}
      check={type == 'checkbox'}
      type={type}
      defaultValue={defaultValue}
    >
      {options}
    </ValidatedField>
  );
};

export const ReactSelectComponents = {
  DropdownIndicator: null,
  ClearIndicator: null,
};

export interface CreatableSelecOption {
  readonly label: string;
  readonly value: string;
}

export const createCreatableSelectOption = (label: string) => ({
  label,
  value: label,
});
