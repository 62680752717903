import applicationResource from 'app/entities/application-resource/application-resource.reducer';
import application from 'app/entities/application/application.reducer';
import audit from 'app/entities/audit/audit.reducer';
import connectorType from 'app/entities/connector-type/connector-type.reducer';
import customAttributeDefinition from 'app/entities/custom-attribute-definition/custom-attribute-definition.reducer';
import customAttributeObjectType from 'app/entities/custom-attribute-object-type/custom-attribute-object-type.reducer';
import customAttributeValue from 'app/entities/custom-attribute-value/custom-attribute-value.reducer';
import employeeDelegation from 'app/entities/employee-delegation/employee-delegation.reducer';
import employee from 'app/entities/employee/employee.reducer';
import iAMSystem from 'app/entities/iam-system/iam-system.reducer';
import jobDefinition from 'app/entities/job-definition/job-definition.reducer';
import location from 'app/entities/location/location.reducer';
import orderApprovalLevels from 'app/entities/order-approval-levels/order-approval-levels.reducer';
import orderComments from 'app/entities/order-comments/order-comments.reducer';
import orderType from 'app/entities/order-type/order-type.reducer';
import orderedGroup from 'app/entities/ordered-group/ordered-group.reducer';
import orderedRole from 'app/entities/ordered-role/ordered-role.reducer';
import orderedSpecificPermission from 'app/entities/ordered-specific-permission/ordered-specific-permission.reducer';
import orderedUserIdentity from 'app/entities/ordered-user-identity/ordered-user-identity.reducer';
import orders from 'app/entities/orders/orders.reducer';
import organizationUnitType from 'app/entities/organization-unit-type/organization-unit-type.reducer';
import organizationUnit from 'app/entities/organization-unit/organization-unit.reducer';
import permissionDefinition from 'app/entities/permission-definition/permission-definition.reducer';
import person from 'app/entities/person/person.reducer';
import profileDefinition from 'app/entities/profile-definition/profile-definition.reducer';
import profileSpecificPermission from 'app/entities/profile-specific-permission/profile-specific-permission.reducer';
import profileUserGroup from 'app/entities/profile-user-group/profile-user-group.reducer';
import profileUserIdentityRole from 'app/entities/profile-user-identity-role/profile-user-identity-role.reducer';
import profileUserIdentity from 'app/entities/profile-user-identity/profile-user-identity.reducer';
import roleDefinition from 'app/entities/role-definition/role-definition.reducer';
import roleGroup from 'app/entities/role-group/role-group.reducer';
import specificPermission from 'app/entities/specific-permission/specific-permission.reducer';
import standardPolicyRule from 'app/entities/standard-policy-rule/standard-policy-rule.reducer';
import task from 'app/entities/tasks/tasks.reducer';
import userGroup from 'app/entities/user-group/user-group.reducer';
import userIdentityRole from 'app/entities/user-identity-role/user-identity-role.reducer';
import userIdentity from 'app/entities/user-identity/user-identity.reducer';
import expression from 'app/shared/components/expression-builder/expression-builder.reducer';
import connectorCommand from 'app/entities/connector-command/connector-command.reducer';
import connectorCommandAttribute from 'app/entities/connector-command-attribute/connector-command-attribute.reducer';
import technicalOrderAction from 'app/entities/technical-order-action/technical-order-action.reducer';
import technicalOrderActionAttribute from 'app/entities/technical-order-action-attribute/technical-order-action-attribute.reducer';
import technicalOrderActionCreate from 'app/entities/technical-order-action-create/technical-order-action-create.reducer';
import riskRule from 'app/entities/risk-rule/risk-rule.reducer';
import riskCombination from 'app/entities/risk-combination/risk-combination.reducer';
import project from 'app/entities/project/project.reducer';
import projectAssignment from 'app/entities/project-assignment/project-assignment.reducer';
import emailTemplate from 'app/entities/email-template/email-template.reducer';
import importSession from 'app/entities/import-session/import-session.reducer';
import inUserIdentity from 'app/entities/in-user-identity/in-user-identity.reducer';
import inUserIdentityRole from 'app/entities/in-user-identity-role/in-user-identity-role.reducer';
import inUserGroup from 'app/entities/in-user-group/in-user-group.reducer';
import inSpecificPermission from 'app/entities/in-specific-permission/in-specific-permission.reducer';
import inEmployee from 'app/entities/in-employee/in-employee.reducer';
import inOrganizationUnit from 'app/entities/in-organization-unit/in-organization-unit.reducer';
import inLocation from 'app/entities/in-location/in-location.reducer';
import inEmployeeDelegation from 'app/entities/in-employee-delegation/in-employee-delegation.reducer';
import inProject from 'app/entities/in-project/in-project.reducer';
import inProjectAssignment from 'app/entities/in-project-assignment/in-project-assignment.reducer';
import inJobDefinition from 'app/entities/in-job-definition/in-job-definition.reducer';

import reconSessionUserIdentityIamNotInSystems from 'app/entities/recon-session-user-identity-iam-not-in-systems/recon-session-user-identity-iam-not-in-systems.reducer';
import reconSessionUserIdentityRoleIamNotInSystems from 'app/entities/recon-session-user-identity-role-iam-not-in-systems/recon-session-user-identity-role-iam-not-in-systems.reducer';
import reconSessionUserGroupIamNotInSystems from 'app/entities/recon-session-user-group-iam-not-in-systems/recon-session-user-group-iam-not-in-systems.reducer';
import reconSessionUserPermissionIamNotInSystems from 'app/entities/recon-session-user-permission-iam-not-in-systems/recon-session-user-permission-iam-not-in-systems.reducer';
import reconSessionUserIdentitySystemsNotInIam from 'app/entities/recon-session-user-identity-systems-not-in-iam/recon-session-user-identity-systems-not-in-iam.reducer';
import reconSessionUserIdentityRoleSystemsNotInIam from 'app/entities/recon-session-user-identity-role-systems-not-in-iam/recon-session-user-identity-role-systems-not-in-iam.reducer';
import reconSessionUserGroupSystemsNotInIam from 'app/entities/recon-session-user-group-systems-not-in-iam/recon-session-user-group-systems-not-in-iam.reducer';
import reconSessionUserPermissionSystemsNotInIam from 'app/entities/recon-session-user-permission-systems-not-in-iam/recon-session-user-permission-systems-not-in-iam.reducer';
import recertificationSession from 'app/entities/recertification-session/recertification-session.reducer';
import recertificationSessionUserIdentity from 'app/entities/recertification-session-user-identity/recertification-session-user-identity.reducer';
import recertificationSessionUserRole from 'app/entities/recertification-session-user-role/recertification-session-user-role.reducer';
import recertificationSessionUserGroup from 'app/entities/recertification-session-user-group/recertification-session-user-group.reducer';
import recertificationSessionSpecificPermission from 'app/entities/recertification-session-specific-permission/recertification-session-specific-permission.reducer';
import iAMdentityAccessToOrderTypes from 'app/entities/iamdentity-access-to-order-types/iamdentity-access-to-order-types.reducer';
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

const entitiesReducers = {
  customAttributeValue,
  person,
  location,
  organizationUnitType,
  organizationUnit,
  jobDefinition,
  employee,
  customAttributeObjectType,
  customAttributeDefinition,
  connectorType,
  iAMSystem,
  application,
  applicationResource,
  roleDefinition,
  permissionDefinition,
  userIdentity,
  roleGroup,
  userGroup,
  userIdentityRole,
  specificPermission,
  audit,
  orderType,
  orders,
  orderComments,
  orderedUserIdentity,
  orderedGroup,
  orderedRole,
  orderedSpecificPermission,
  profileDefinition,
  profileUserIdentity,
  profileUserIdentityRole,
  profileUserGroup,
  profileSpecificPermission,
  employeeDelegation,
  expression,
  standardPolicyRule,
  orderApprovalLevels,
  task,
  connectorCommand,
  connectorCommandAttribute,
  technicalOrderAction,
  technicalOrderActionAttribute,
  technicalOrderActionCreate,
  riskRule,
  riskCombination,
  project,
  projectAssignment,
  emailTemplate,
  importSession,
  inUserIdentity,
  inUserIdentityRole,
  inUserGroup,
  inSpecificPermission,
  inEmployee,
  inOrganizationUnit,
  inLocation,
  inEmployeeDelegation,
  inProject,
  inProjectAssignment,
  inJobDefinition,
  reconSessionUserIdentityIamNotInSystems,
  reconSessionUserIdentityRoleIamNotInSystems,
  reconSessionUserGroupIamNotInSystems,
  reconSessionUserPermissionIamNotInSystems,
  reconSessionUserIdentitySystemsNotInIam,
  reconSessionUserIdentityRoleSystemsNotInIam,
  reconSessionUserGroupSystemsNotInIam,
  reconSessionUserPermissionSystemsNotInIam,
  recertificationSession,
  recertificationSessionUserIdentity,
  recertificationSessionUserRole,
  recertificationSessionUserGroup,
  recertificationSessionSpecificPermission,
  iAMdentityAccessToOrderTypes,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
};

export default entitiesReducers;
