import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { Translate, translate, ValidatedField } from 'react-jhipster';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, Form, FormGroup, Label, Row, Spinner } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { APP_GLOBAL_DATE_FORMAT } from 'app/config/constants';
import { apiUrl as connectorTypeUrl, getEntities as getConnectorTypes } from 'app/entities/connector-type/connector-type.reducer';
import PaginatedReactSelect from 'app/shared/components/react-select/paginated-react-select';
import { IIAMSystem } from 'app/shared/model/iam-system.model';
import moment from 'moment';
import { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { createEntity, getEntity, reset, updateEntity } from './iam-system.reducer';
import { convertReactSelectValuesToEntityIds } from 'app/shared/util/entity-utils';
import { Controller } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import { convertDateFormat } from 'app/shared/util/date-utils';
import ro from 'date-fns/locale/ro';

export const IAMSystemUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const iAMSystemEntity: IIAMSystem = useAppSelector(state => state.iAMSystem.entity);
  const loading = useAppSelector(state => state.iAMSystem.loading);
  const updating = useAppSelector(state => state.iAMSystem.updating);
  const updateSuccess = useAppSelector(state => state.iAMSystem.updateSuccess);
  const startDate = useRef('');
  const handleClose = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getConnectorTypes({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  useEffect(() => {
    formReset(defaultValues());
  }, [iAMSystemEntity]);

  const saveEntity = values => {
    const entity = {
      ...iAMSystemEntity,
      ...convertReactSelectValuesToEntityIds(values),
      startDate: values.startDate ? moment(values.startDate).format('YYYY-MM-DD') : null, // Format startDate for server
      endDate: values.endDate ? moment(values.endDate).format('YYYY-MM-DD') : null, // Format endDate for server
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const locale = useAppSelector(state => state.locale.currentLocale);

  const defaultValues = (): any => {
    const result = isNew
      ? {
          startDate: moment(new Date()).format(convertDateFormat(locale, 'date')),
          endDate: null,
        }
      : {
          ...iAMSystemEntity,
          connectorType: iAMSystemEntity?.connectorType,
        };
    startDate.current = result?.startDate;
    return result;
  };

  const {
    register,
    handleSubmit,
    control,
    reset: formReset,
    watch,
  } = useForm({
    defaultValues: defaultValues(),
    mode: 'onChange',
  });

  const watchStartDate = watch('startDate');
  const watchEndDate = watch('endDate');

  return (
    <Card className="jh-card">
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="iamdentityApp.iAMSystem.home.createOrEditLabel" data-cy="IAMSystemCreateUpdateHeading">
            <Translate contentKey="iamdentityApp.iAMSystem.home.createOrEditLabel">Create or edit a IAMSystem</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading || updating ? (
            <div className="entity-spinner-container">
              <Spinner color="primary">Loading...</Spinner>
            </div>
          ) : (
            <Form onSubmit={handleSubmit(saveEntity)}>
              {!isNew ? (
                <ValidatedField
                  register={register}
                  name="id"
                  required
                  readOnly
                  id="iam-system-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.iAMSystem.name')}
                id="iam-system-name"
                name="name"
                data-cy="name"
                type="text"
              />
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.iAMSystem.code')}
                id="iam-system-code"
                name="code"
                data-cy="code"
                type="text"
              />
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.iAMSystem.description')}
                id="iam-system-description"
                name="description"
                data-cy="description"
                type="text"
              />
              <div className="date-picker-container mb-3">
                <Controller
                  control={control}
                  name="startDate"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <div className="form-group">
                      <label htmlFor="iam-system-startDate" className="form-label">
                        {translate('iamdentityApp.iAMSystem.startDate')}
                      </label>
                      <div className="position-relative">
                        <DatePicker
                          maxDate={watchEndDate ? moment(watchEndDate, 'YYYY-MM-DD').toDate() : null}
                          selected={value ? new Date(value) : new Date()}
                          onChange={date => {
                            onChange(date ? moment(date, convertDateFormat(locale, 'date')).toDate() : null);
                          }}
                          value={value ? moment(value).format(convertDateFormat(locale, 'date')) : convertDateFormat(locale, 'date')}
                          onBlur={onBlur}
                          locale={locale === 'ro' ? ro : 'en'}
                          todayButton={<Translate contentKey="global.today">Today</Translate>}
                          showMonthDropdown
                          showYearDropdown
                          className="form-control"
                          ref={ref}
                          id="iam-system-startDate"
                          data-cy="startDate"
                        />
                        <span
                          onClick={() => {
                            onChange(null);
                          }}
                          className="fw-semibold close-icon-custom"
                        >
                          {value !== null && value !== convertDateFormat(locale, 'date') ? 'X' : ''}
                        </span>
                      </div>
                    </div>
                  )}
                />
              </div>
              <div className="date-picker-container mb-3">
                <Controller
                  control={control}
                  name="endDate"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <div className="form-group">
                      <label htmlFor="iam-system-endDate" className="form-label">
                        {translate('iamdentityApp.iAMSystem.endDate')}
                      </label>
                      <div className="position-relative">
                        <DatePicker
                          minDate={watchStartDate ? moment(watchStartDate, 'YYYY-MM-DD').toDate() : null}
                          selected={value ? new Date(value) : new Date()}
                          onChange={date => {
                            onChange(date ? moment(date, convertDateFormat(locale, 'date')).toDate() : null);
                          }}
                          value={value ? moment(value).format(convertDateFormat(locale, 'date')) : convertDateFormat(locale, 'date')}
                          onBlur={onBlur}
                          locale={locale === 'ro' ? ro : 'en'}
                          todayButton={<Translate contentKey="global.today">Today</Translate>}
                          showMonthDropdown
                          showYearDropdown
                          className="form-control"
                          ref={ref}
                          id="iam-system-endDate"
                          data-cy="endDate"
                        />
                        <span
                          onClick={() => {
                            onChange(null);
                          }}
                          className="fw-semibold close-icon-custom"
                        >
                          {value !== null && value !== convertDateFormat(locale, 'date') ? 'X' : ''}
                        </span>
                      </div>
                    </div>
                  )}
                />
              </div>
              <FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.iAMSystem.connectorType" />
                </Label>
                <PaginatedReactSelect
                  control={control}
                  name="connectorType"
                  url={connectorTypeUrl}
                  queryProps={{
                    name: 'name',
                    type: 'string',
                  }}
                />
              </FormGroup>
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.iAMSystem.userOnSystem')}
                id="iam-system-userOnSystem"
                name="userOnSystem"
                data-cy="userOnSystem"
                check
                type="checkbox"
              />
              <div className="mt-1">
                <Button id="cancel-save" data-cy="entityCreateCancelButton" onClick={() => navigate(-1)} color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </div>
            </Form>
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default IAMSystemUpdate;
