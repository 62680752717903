import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CompactExpressionBuilder from 'app/shared/components/expression-builder/compact-expression-builder';
import { IProfileSpecificPermission } from 'app/shared/model/profile-specific-permission.model';
import React from 'react';
import { Translate } from 'react-jhipster';
import { Link } from 'react-router-dom';
import { Button, Table } from 'reactstrap';
import { generateAssignedSpecificPermissionUrl } from './profile-users';

interface IAssignedProfileSpecificPermissionsTableProps {
  profileSpecificPermissions: IProfileSpecificPermission[];
}

const AssignedProfileSpecificPermissionsTable = ({ profileSpecificPermissions }: IAssignedProfileSpecificPermissionsTableProps) => {
  return (
    <div className="table-responsive">
      {profileSpecificPermissions.length ? (
        <Table responsive>
          <thead>
            <tr>
              <th>
                <Translate contentKey="iamdentityApp.profileSpecificPermission.permissionDefinition">Permission Definition</Translate>
              </th>
              <th>
                <Translate contentKey="iamdentityApp.profileSpecificPermission.permissionDefinitionExpression">
                  Permission Definition Expression
                </Translate>
              </th>
              <th>
                <Translate contentKey="iamdentityApp.profileSpecificPermission.nameExpression">Name Expression</Translate>
              </th>
              <th>
                <Translate contentKey="iamdentityApp.profileSpecificPermission.descriptionExpresion">Description Expression</Translate>
              </th>
              <th />
            </tr>
          </thead>
          <tbody>
            {profileSpecificPermissions.map((profileSpecificPermission, i) => (
              <tr key={`specific-permission-${i}`}>
                <td>{profileSpecificPermission.permissionDefinition ? profileSpecificPermission.permissionDefinition.name : ''}</td>
                <td>
                  {profileSpecificPermission.permissionDefinitionExpression ? (
                    <CompactExpressionBuilder
                      tree={profileSpecificPermission.permissionDefinitionExpression}
                      expressionId={`profile-permission-definition-${profileSpecificPermission?.id || ''}`}
                    />
                  ) : null}
                </td>
                <td>
                  {profileSpecificPermission.nameExpression ? (
                    <CompactExpressionBuilder
                      tree={profileSpecificPermission.nameExpression}
                      expressionId={`profile-specific-permission-name-${profileSpecificPermission?.id || ''}`}
                    />
                  ) : null}
                </td>
                <td>
                  {profileSpecificPermission.descriptionExpresion ? (
                    <CompactExpressionBuilder
                      tree={profileSpecificPermission.descriptionExpresion}
                      expressionId={`profile-specific-permission-description-${profileSpecificPermission?.id || ''}`}
                    />
                  ) : null}
                </td>
                <td className="text-end">
                  <div className="btn-group flex-btn-group-container">
                    <Button
                      tag={Link}
                      to={generateAssignedSpecificPermissionUrl(
                        profileSpecificPermission.profileUserIdentity,
                        profileSpecificPermission.id,
                        'VIEW'
                      )}
                      className="btn-md"
                      color="info"
                      data-cy="entityDetailsButton"
                    >
                      <FontAwesomeIcon icon="eye" />
                    </Button>
                    <Button
                      tag={Link}
                      to={generateAssignedSpecificPermissionUrl(
                        profileSpecificPermission.profileUserIdentity,
                        profileSpecificPermission.id
                      )}
                      className="btn-md"
                      color="primary"
                      data-cy="entityEditButton"
                    >
                      <FontAwesomeIcon icon="pencil-alt" />
                    </Button>
                    <Button
                      className="btn-md"
                      tag={Link}
                      to={generateAssignedSpecificPermissionUrl(
                        profileSpecificPermission.profileUserIdentity,
                        profileSpecificPermission.id,
                        'DELETE'
                      )}
                      color="danger"
                      data-cy="entityDeleteButton"
                    >
                      <FontAwesomeIcon icon="trash" />
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <div className="alert alert-warning">
          <Translate contentKey="iamdentityApp.profileSpecificPermission.home.notFound">No Profile Specific Permissions found</Translate>
        </div>
      )}
    </div>
  );
};

export default AssignedProfileSpecificPermissionsTable;
