import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './in-job-definition.reducer';
import { CustomAttributeDtoKey } from 'app/shared/model/custom-attribute-definition.model';
import { findCustomAttributeValueByKey } from 'app/shared/util/entity-utils';

export const InJobDefinitionDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const inJobDefinitionEntity = useAppSelector(state => state.inJobDefinition.entity);
  const customAttributeDefinitions: CustomAttributeDtoKey[] = useAppSelector(state => state.customAttributeDefinition.entities);

  return (
    <Row>
      <Col md="8">
        <h2 data-cy="inJobDefinitionDetailsHeading">
          <Translate contentKey="iamdentityApp.inJobDefinition.detail.title">InJobDefinition</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{inJobDefinitionEntity.id}</dd>
          <dt>
            <span id="name">
              <Translate contentKey="iamdentityApp.inJobDefinition.name">Name</Translate>
            </span>
          </dt>
          <dd>{inJobDefinitionEntity.name}</dd>
          <dt>
            <span id="internalCode">
              <Translate contentKey="iamdentityApp.inJobDefinition.internalCode">Internal Code</Translate>
            </span>
          </dt>
          <dd>{inJobDefinitionEntity.internalCode}</dd>
          <dt>
            <span id="publicCode">
              <Translate contentKey="iamdentityApp.inJobDefinition.publicCode">Public Code</Translate>
            </span>
          </dt>
          <dd>{inJobDefinitionEntity.publicCode}</dd>
          <dt>
            <span id="validFrom">
              <Translate contentKey="iamdentityApp.inJobDefinition.validFrom">Valid From</Translate>
            </span>
          </dt>
          <dd>
            {inJobDefinitionEntity.validFrom ? (
              <TextFormat value={inJobDefinitionEntity.validFrom} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="validTo">
              <Translate contentKey="iamdentityApp.inJobDefinition.validTo">Valid To</Translate>
            </span>
          </dt>
          <dd>
            {inJobDefinitionEntity.validTo ? (
              <TextFormat value={inJobDefinitionEntity.validTo} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
          {customAttributeDefinitions?.map(cad => (
            <React.Fragment key={cad.id}>
              <dt>
                <span id={cad.displayName}>{cad.displayName}</span>
              </dt>
              <dd>{findCustomAttributeValueByKey(cad, inJobDefinitionEntity)}</dd>
            </React.Fragment>
          ))}
        </dl>
        <Button tag={Link} to="/in-job-definition" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default InJobDefinitionDetail;
