import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { getSortState, JhiItemCount, JhiPagination, TextFormat, Translate } from 'react-jhipster';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Card, Table, UncontrolledTooltip } from 'reactstrap';

import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import {
  findCustomAttributeValueByKey,
  getFiltersForCustomAttributes,
  overridePaginationStateWithQueryParams,
} from 'app/shared/util/entity-utils';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';

import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { getEntitiesByObjectType as getCustomPropertiesByObjectType } from 'app/entities/custom-attribute-definition/custom-attribute-definition.reducer';
import Filter from 'app/shared/FilterComponent';
import { CustomAttributeDtoKey } from 'app/shared/model/custom-attribute-definition.model';
import { CustomAttributeApplicableObjectType } from 'app/shared/model/enumerations/custom-attribute-applicable-object-type.model';
import { getQueryParamsIfExists } from 'app/shared/util/filter-utils';
import { exportEntities, getEntities, searchEntities } from './role-definition.reducer';
import PaginatedDropdown from 'app/shared/components/pagination-dropdown';
import { convertDateFormat } from 'app/shared/util/date-utils';
import { handleSortIconsTable } from 'app/shared/util/table-icons';

export const RoleDefinition = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();

  const [search, setSearch] = useState('');

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id'), location.search)
  );

  const handlePageSizeChange = size => {
    paginationState.activePage = 1;
    paginationState.itemsPerPage = size;
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        query,
      })
    );
  };
  const [filterShown, setFilterShown] = useState<boolean>(true);
  const [query, setQuery] = useState<string>(getQueryParamsIfExists(location.search));

  const roleDefinitionList = useAppSelector(state => state.roleDefinition.entities);
  const loading = useAppSelector(state => state.roleDefinition.loading);
  const totalItems = useAppSelector(state => state.roleDefinition.totalItems);
  const locale = useAppSelector(state => state.locale.currentLocale);
  const customAttributeDefinitions: CustomAttributeDtoKey[] = useAppSelector(state =>
    state.customAttributeDefinition.entities?.filter(def => def.visibleInList)
  );

  const toggleFilter = () => {
    if (filterShown) setQuery('');
    setFilterShown(!filterShown);
  };

  const getAllEntities = () => {
    if (search) {
      dispatch(
        searchEntities({
          query: search,
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
        })
      );
    } else {
      dispatch(
        getEntities({
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
          query,
        })
      );
    }
  };

  const startSearching = e => {
    if (search) {
      setPaginationState({
        ...paginationState,
        activePage: 1,
      });
      dispatch(
        searchEntities({
          query: search,
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
        })
      );
    }
    e.preventDefault();
  };

  const clear = () => {
    setSearch('');
    setPaginationState({
      ...paginationState,
      activePage: 1,
    });
    dispatch(getEntities({}));
  };

  const handleSearch = event => setSearch(event.target.value);

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}&query=${query}`;
    if (location.search !== endURL) {
      navigate(`${location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    dispatch(getCustomPropertiesByObjectType(CustomAttributeApplicableObjectType.ROLE_DEFINITION));
  }, []);

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort, search, query]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  const exportTable = () => {
    dispatch(
      exportEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        query,
      })
    );
  };

  return (
    <Card className="jh-card">
      <h2 id="role-definition-heading" data-cy="RoleDefinitionHeading">
        <Translate contentKey="iamdentityApp.roleDefinition.home.title">Role Definitions</Translate>
        <div className="d-flex justify-content-end">
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            <Translate contentKey="iamdentityApp.roleDefinition.home.refreshListLabel">Refresh List</Translate>
          </Button>
          <Button className="me-2" color="light" onClick={exportTable} disabled={loading}>
            <FontAwesomeIcon icon="file-excel" spin={loading} /> <Translate contentKey="entity.action.export">Export</Translate>
          </Button>
          <Link to="/role-definition/new" className="btn btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="iamdentityApp.roleDefinition.home.createLabel">Create new Role Definition</Translate>
          </Link>
        </div>
      </h2>
      <div className="table-responsive table-scroll-custom">
        <Table responsive>
          <thead className="table-scroll-custom_thead">
            <tr>
              <th className="hand" onClick={sort('id')}>
                <Translate contentKey="iamdentityApp.roleDefinition.id">ID</Translate> {handleSortIconsTable('id')}
              </th>
              <th className="hand" onClick={sort('name')}>
                <Translate contentKey="iamdentityApp.roleDefinition.name">Name</Translate> {handleSortIconsTable('name')}
              </th>
              <th className="hand" onClick={sort('code')}>
                <Translate contentKey="iamdentityApp.roleDefinition.code">Code</Translate> {handleSortIconsTable('code')}
              </th>
              <th className="hand" onClick={sort('description')}>
                <Translate contentKey="iamdentityApp.roleDefinition.description">Description</Translate>{' '}
                {handleSortIconsTable('description')}
              </th>
              <th className="hand" onClick={sort('startDate')}>
                <Translate contentKey="iamdentityApp.roleDefinition.startDate">Start Date</Translate> {handleSortIconsTable('startDate')}
              </th>
              <th className="hand" onClick={sort('endDate')}>
                <Translate contentKey="iamdentityApp.roleDefinition.endDate">End Date</Translate> {handleSortIconsTable('endDate')}
              </th>
              <th className="hand" onClick={sort('uniqueKey')}>
                <Translate contentKey="iamdentityApp.roleDefinition.uniqueKey">Unique Key</Translate> {handleSortIconsTable('uniqueKey')}
              </th>
              <th className="hand" onClick={sort('application.name')}>
                <Translate contentKey="iamdentityApp.roleDefinition.application">Application</Translate>{' '}
                {handleSortIconsTable('application.name')}
              </th>
              {customAttributeDefinitions?.map(cad => (
                <th key={cad.id} className="hand">
                  {cad.displayName}
                </th>
              ))}
              <th className="text-right">
                <span>
                  <Button size="sm" id="toggle-filter" color={filterShown ? 'secondary' : 'default'} onClick={toggleFilter}>
                    <FontAwesomeIcon icon="magnifying-glass" />
                  </Button>
                </span>
                <UncontrolledTooltip placement="top" target="toggle-filter">
                  <Translate contentKey={filterShown ? 'global.hideFilter' : 'global.showFilter'}>Show/Hide Filter</Translate>
                </UncontrolledTooltip>
              </th>
            </tr>
            {filterShown && (
              <Filter
                getEntities={setQuery}
                columns={[
                  {
                    name: 'id',
                    type: 'ignore',
                  },
                  {
                    name: 'name',
                    type: 'string',
                  },
                  {
                    name: 'code',
                    type: 'string',
                  },
                  {
                    name: 'description',
                    type: 'string',
                  },
                  {
                    name: 'startDate',
                    type: 'date',
                  },
                  {
                    name: 'endDate',
                    type: 'date',
                  },
                  {
                    name: 'uniqueKey',
                    type: 'string',
                  },
                  {
                    name: 'application',
                    type: 'string',
                  },
                  ...getFiltersForCustomAttributes(customAttributeDefinitions),
                ]}
              />
            )}
          </thead>
          <tbody>
            {roleDefinitionList.map((roleDefinition, i) => (
              <tr key={`entity-${i}`} data-cy="entityTable">
                <td>
                  <Button tag={Link} to={`/role-definition/${roleDefinition.id}`} color="link" size="sm">
                    {roleDefinition.id}
                  </Button>
                </td>
                <td>{roleDefinition.name}</td>
                <td>{roleDefinition.code}</td>
                <td>{roleDefinition.description}</td>
                <td>
                  {roleDefinition.startDate ? (
                    <TextFormat type="date" value={roleDefinition.startDate} format={convertDateFormat(locale, 'date')} />
                  ) : null}
                </td>
                <td>
                  {roleDefinition.endDate ? (
                    <TextFormat type="date" value={roleDefinition.endDate} format={convertDateFormat(locale, 'date')} />
                  ) : null}
                </td>
                <td>{roleDefinition.uniqueKey}</td>
                <td>
                  {roleDefinition.application ? (
                    <Link to={`/application/${roleDefinition.application.id}`}>{roleDefinition.application.name}</Link>
                  ) : (
                    ''
                  )}
                </td>
                {customAttributeDefinitions?.map(cad => (
                  <td key={cad.id}>{findCustomAttributeValueByKey(cad, roleDefinition)}</td>
                ))}
                <td className="text-end">
                  <div className="btn-group flex-btn-group-container">
                    <Button tag={Link} to={`/role-definition/${roleDefinition.id}`} color="info" size="sm" data-cy="entityDetailsButton">
                      <FontAwesomeIcon icon="eye" />{' '}
                      <span className="d-none d-md-inline">
                        <Translate contentKey="entity.action.view">View</Translate>
                      </span>
                    </Button>
                    <Button
                      tag={Link}
                      to={`/role-definition/${roleDefinition.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                      color="primary"
                      size="sm"
                      data-cy="entityEditButton"
                    >
                      <FontAwesomeIcon icon="pencil-alt" />{' '}
                      <span className="d-none d-md-inline">
                        <Translate contentKey="entity.action.edit">Edit</Translate>
                      </span>
                    </Button>
                    <Button
                      tag={Link}
                      to={`/role-definition/${roleDefinition.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                      color="danger"
                      size="sm"
                      data-cy="entityDeleteButton"
                    >
                      <FontAwesomeIcon icon="trash" />{' '}
                      <span className="d-none d-md-inline">
                        <Translate contentKey="entity.action.delete">Delete</Translate>
                      </span>
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {(!roleDefinitionList || roleDefinitionList.length < 1) && !loading && (
          <div className="alert alert-warning mt-1">
            <Translate contentKey="iamdentityApp.roleDefinition.home.notFound">No Role Definitions found</Translate>
          </div>
        )}
      </div>
      {totalItems ? (
        <div className={roleDefinitionList && roleDefinitionList.length > 0 ? '' : 'd-none'}>
          <div className="justify-content-center d-flex">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </div>
          <div className="justify-content-center d-flex">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
            <PaginatedDropdown itemsPerPage={paginationState.itemsPerPage} handlePageSizeChange={handlePageSizeChange}></PaginatedDropdown>
          </div>
        </div>
      ) : (
        ''
      )}
    </Card>
  );
};

export default RoleDefinition;
