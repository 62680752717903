import React, { useContext } from 'react';
import { translate } from 'react-jhipster';
import { Function } from '../classes';
import { ExpressioNBuilderContext, renderNode } from '../expression-builder';
import '../style.scss';
import { DropdownClickProps } from '../types';
import { newNodeByValueAndType } from '../utils';
import NodeComponent from './node.component';

interface FunctionComponentProps {
  node: Function;
}

const FunctionComponent = ({ node }: FunctionComponentProps) => {
  const { tree, setTree } = useContext(ExpressioNBuilderContext);

  const onChangeClick = ({ value, type }: DropdownClickProps) => {
    const newNode = newNodeByValueAndType(value, type);
    tree.changeNode(node, newNode);
    setTree(tree);
  };

  return (
    <div key={`function-row-${node.id}`} className="function-row">
      <NodeComponent onChange={onChangeClick} items={node.getAlternatives()} value={node.name} color="orange">
        {translate(`iamdentityApp.expressionBuilder.${node.name}`)}
      </NodeComponent>
      <div className="function-inner">
        {node.nodes.map((child, index) => {
          const showComma = node.nodes.length > 1 && index < node.nodes.length && index > 0;
          return (
            <React.Fragment>
              {showComma ? (
                <span key={`comma-${child.id}`} className="comma">
                  ,
                </span>
              ) : null}
              {node.paramsDescription[index].name}
              <span key={`comma-2-${child.id}`} className="comma">
                :
              </span>
              {renderNode(child)}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default FunctionComponent;
