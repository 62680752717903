export enum CustomAttributeType {
  TEXT = 'TEXT',

  BOOLEAN = 'BOOLEAN',

  DATE = 'DATE',

  ENUMERATION = 'ENUMERATION',

  NUMBER = 'NUMBER',
}
