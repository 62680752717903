import React, { useContext } from 'react';
import { DropdownItem } from 'reactstrap';
import { NavLink as Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ContextHeaderState } from '../header/header';

export interface IMenuItem {
  children: React.ReactNode;
  icon: IconProp;
  to: string;
  id?: string;
  className?: string;
  'data-cy'?: string;
}

const MenuItem = (props: IMenuItem) => {
  const { to, icon, id, children, className } = props;
  const { disableNavClick, setDisableNavClick } = useContext(ContextHeaderState);
  return (
    <DropdownItem tag={Link} to={to} id={id} data-cy={props['data-cy']} className={className} onClick={() => setDisableNavClick('close')}>
      <FontAwesomeIcon icon={icon} fixedWidth /> {children}
    </DropdownItem>
  );
};

export default MenuItem;
