import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText, Card, Spinner, Form, FormGroup, Label } from 'reactstrap';
import { Translate, translate, ValidatedField } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PaginatedReactSelect from 'app/shared/components/react-select/paginated-react-select';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { useForm } from 'react-hook-form';
import { IAMdentityAccessPermissionType } from 'app/shared/model/enumerations/iamdentity-access-permission-type.model';
import { getEntity, updateEntity, createEntity, reset } from './iamdentity-access-to-order-types.reducer';
import { apiUrl as applicationsUrl } from 'app/entities/application/application.reducer';
import { apiUrl as orderTypesUrl } from 'app/entities/order-type/order-type.reducer';
import { getRoles as getAuthorities } from 'app/shared/reducers/user-management';
import { Controller } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import { convertDateFormat } from 'app/shared/util/date-utils';
import ro from 'date-fns/locale/ro';
import moment from 'moment';

export const IAMdentityAccessToOrderTypesUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;
  const locale = useAppSelector(state => state.locale.currentLocale);

  const iAMdentityAccessToOrderTypesEntity = useAppSelector(state => state.iAMdentityAccessToOrderTypes.entity);
  const authorities = useAppSelector(state => state.userManagement.authorities);
  const loading = useAppSelector(state => state.iAMdentityAccessToOrderTypes.loading);
  const updating = useAppSelector(state => state.iAMdentityAccessToOrderTypes.updating);
  const updateSuccess = useAppSelector(state => state.iAMdentityAccessToOrderTypes.updateSuccess);
  const iAMdentityAccessPermissionTypeValues = Object.keys(IAMdentityAccessPermissionType);

  useEffect(() => {
    dispatch(getAuthorities());
  }, []);

  const defaultValues = () =>
    isNew
      ? {
          validFrom: moment(new Date(), convertDateFormat(locale, 'date')).toDate(),
          validTo: null,
        }
      : {
          ...iAMdentityAccessToOrderTypesEntity,
        };

  const {
    register,
    handleSubmit,
    watch,
    resetField,
    setValue,
    reset: formReset,
    control,
  } = useForm({
    defaultValues: defaultValues(),
    mode: 'onChange',
  });

  const watchValidFrom = watch('validFrom');
  const watchValidTo = watch('validTo');

  const { ref: orderTypeRef, ...orderTypeProps } = register('orderType', { required: true });
  const { ref: permissionTypeRef, ...permissionTypeProps } = register('permissionType', { required: true });
  const { ref: authorityNameRef, ...authorityNameProps } = register('authorityName', { required: true });
  const { ref: validFromRef, ...validFromProps } = register('validFrom', { required: true });

  const handleClose = () => {
    navigate('/iamdentity-access-to-order-types' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  useEffect(() => {
    formReset(defaultValues());
  }, [iAMdentityAccessToOrderTypesEntity]);

  const saveEntity = values => {
    const entity = {
      ...iAMdentityAccessToOrderTypesEntity,
      application: values?.application?.id ? { id: values.application.id } : null,
      authorityName: values?.authorityName ? values.authorityName : null,
      orderType: values?.orderType?.id ? { id: values.orderType.id } : null,
      permissionType: values?.permissionType ? values.permissionType : null,
      validFrom: values.validFrom ? moment(values.validFrom).format('YYYY-MM-DD') : null, // Format validFrom for server
      validTo: values.validTo ? moment(values.validTo).format('YYYY-MM-DD') : null, // Format validTo for server
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  return (
    <Card className="jh-card">
      <Row className="justify-content-center">
        <Col md="8">
          <h2
            id="iamdentityApp.iAMdentityAccessToOrderTypes.home.createOrEditLabel"
            data-cy="IAMdentityAccessToOrderTypesCreateUpdateHeading"
          >
            <Translate contentKey="iamdentityApp.iAMdentityAccessToOrderTypes.home.createOrEditLabel">
              Create or edit a IAMdentityAccessToOrderTypes
            </Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading || updating ? (
            <div className="entity-spinner-container">
              <Spinner color="primary">Loading...</Spinner>
            </div>
          ) : (
            <Form onSubmit={handleSubmit(saveEntity)}>
              <FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.iAMdentityAccessToOrderTypes.application" />
                </Label>
                <PaginatedReactSelect
                  control={control}
                  name="application"
                  isPageable={false}
                  url={applicationsUrl}
                  queryProps={{
                    name: 'name',
                    type: 'string',
                  }}
                />
              </FormGroup>
              <Label>
                *
                <Translate contentKey="iamdentityApp.iAMdentityAccessToOrderTypes.authority" />
              </Label>
              <ValidatedField
                register={register}
                id="iamdentity-access-to-order-types-authorityName"
                name="authorityName"
                data-cy="authorityName"
                type="select"
                required={true}
              >
                {authorities.map(authority => (
                  <option value={authority} key={authority}>
                    {authority}
                  </option>
                ))}
              </ValidatedField>
              <FormGroup>
                <Label>
                  *
                  <Translate contentKey="iamdentityApp.iAMdentityAccessToOrderTypes.orderType" />
                </Label>
                <PaginatedReactSelect
                  control={control}
                  isPageable={false}
                  name="orderType"
                  url={orderTypesUrl}
                  queryProps={{
                    name: 'name',
                    type: 'string',
                  }}
                />
              </FormGroup>
              <Label>
                *
                <Translate contentKey="iamdentityApp.iAMdentityAccessToOrderTypes.permissionType" />
              </Label>
              <ValidatedField
                register={register}
                id="iamdentity-access-to-order-types-permissionType"
                name="permissionType"
                data-cy="permissionType"
                type="select"
                required={true}
              >
                {iAMdentityAccessPermissionTypeValues.map(iAMdentityAccessPermissionType => (
                  <option value={iAMdentityAccessPermissionType} key={iAMdentityAccessPermissionType}>
                    {translate('iamdentityApp.IAMdentityAccessPermissionType.' + iAMdentityAccessPermissionType)}
                  </option>
                ))}
              </ValidatedField>
              <div className="date-picker-container mb-3">
                <Controller
                  control={control}
                  name="validFrom"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <div className="form-group">
                      <label htmlFor="iamdentity-access-to-order-types-validFrom" className="form-label">
                        *{translate('iamdentityApp.iAMdentityAccessToOrderTypes.validFrom')}
                      </label>
                      <DatePicker
                        maxDate={watchValidTo ? moment(watchValidTo, 'YYYY-MM-DD').toDate() : null}
                        selected={value ? new Date(value) : new Date()}
                        onChange={date => {
                          onChange(date ? moment(date, convertDateFormat(locale, 'date')).toDate() : null);
                        }}
                        value={value ? moment(value).format(convertDateFormat(locale, 'date')) : convertDateFormat(locale, 'date')}
                        onBlur={onBlur}
                        locale={locale === 'ro' ? ro : 'en'}
                        todayButton={<Translate contentKey="global.today">Today</Translate>}
                        showMonthDropdown
                        showYearDropdown
                        className="form-control"
                        ref={ref}
                        id="iamdentity-access-to-order-types-validFrom"
                        data-cy="validFrom"
                      />
                    </div>
                  )}
                />
              </div>
              <div className="date-picker-container mb-3">
                <Controller
                  control={control}
                  name="validTo"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <div className="form-group">
                      <label htmlFor="iamdentity-access-to-order-types-validTo" className="form-label">
                        {translate('iamdentityApp.iAMdentityAccessToOrderTypes.validTo')}
                      </label>
                      <div className="position-relative">
                        <DatePicker
                          minDate={watchValidFrom ? moment(watchValidFrom, 'YYYY-MM-DD').toDate() : null}
                          selected={value ? new Date(value) : new Date()}
                          onChange={date => {
                            onChange(date ? moment(date, convertDateFormat(locale, 'date')).toDate() : null);
                          }}
                          value={value ? moment(value).format(convertDateFormat(locale, 'date')) : convertDateFormat(locale, 'date')}
                          onBlur={onBlur}
                          locale={locale === 'ro' ? ro : 'en'}
                          todayButton={<Translate contentKey="global.today">Today</Translate>}
                          showMonthDropdown
                          showYearDropdown
                          className="form-control"
                          ref={ref}
                          id="iamdentity-access-to-order-types-validTo"
                          data-cy="validTo"
                        />
                        <span
                          onClick={() => {
                            onChange(null);
                          }}
                          className="fw-semibold close-icon-custom"
                        >
                          {value !== null && value !== convertDateFormat(locale, 'date') ? 'X' : ''}
                        </span>
                      </div>
                    </div>
                  )}
                />
              </div>
              <Button
                tag={Link}
                id="cancel-save"
                data-cy="entityCreateCancelButton"
                to="/iamdentity-access-to-order-types"
                replace
                color="info"
              >
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </Form>
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default IAMdentityAccessToOrderTypesUpdate;
