import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { Translate, translate, ValidatedField } from 'react-jhipster';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, Form, FormGroup, Label, Row, Spinner } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import {
  addCustomPropertiesToCustomAttributesMap,
  convertReactSelectValuesToEntityIds,
  extractCustomAttributesAsEntityProperties,
} from 'app/shared/util/entity-utils';

import { APP_GLOBAL_DATE_FORMAT } from 'app/config/constants';
import { getEntitiesByObjectType as getCustomPropertiesByObjectType } from 'app/entities/custom-attribute-definition/custom-attribute-definition.reducer';
import { apiUrl as employeesUrl } from 'app/entities/employee/employee.reducer';
import { apiUrl as locationsUrl } from 'app/entities/location/location.reducer';
import { apiUrl as organizationUnitsTypeUrl } from 'app/entities/organization-unit-type/organization-unit-type.reducer';
import { apiUrl as organizationUnitsUrl } from 'app/entities/organization-unit/organization-unit.reducer';
import PaginatedReactSelect from 'app/shared/components/react-select/paginated-react-select';
import { CustomAttributeDtoKey } from 'app/shared/model/custom-attribute-definition.model';
import { CustomAttributeApplicableObjectType } from 'app/shared/model/enumerations/custom-attribute-applicable-object-type.model';
import { renderCustomAttributeField } from 'app/shared/util/component-utils';
import moment from 'moment';
import { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { createEntity, getEntity, reset, updateEntity } from './organization-unit.reducer';

export const OrganizationUnitUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const organizationUnitEntity = useAppSelector(state => state.organizationUnit.entity);
  const loading = useAppSelector(state => state.organizationUnit.loading);
  const updating = useAppSelector(state => state.organizationUnit.updating);
  const updateSuccess = useAppSelector(state => state.organizationUnit.updateSuccess);
  const customAttributeDefinitions: CustomAttributeDtoKey[] = useAppSelector(state => state.customAttributeDefinition.entities);
  const currDate = new Date().toLocaleDateString;
  const startDate = useRef('');
  const handleClose = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getCustomPropertiesByObjectType(CustomAttributeApplicableObjectType.ORGANIZATION_UNIT));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  useEffect(() => {
    formReset(defaultValues());
  }, [organizationUnitEntity]);

  const saveEntity = values => {
    const entity = {
      ...addCustomPropertiesToCustomAttributesMap(
        organizationUnitEntity,
        convertReactSelectValuesToEntityIds(values),
        customAttributeDefinitions
      ),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () => {
    const result = isNew
      ? { startDate: moment().format(APP_GLOBAL_DATE_FORMAT) }
      : {
          ...extractCustomAttributesAsEntityProperties(customAttributeDefinitions, organizationUnitEntity),
          organizationUnitType: organizationUnitEntity?.organizationUnitType,
          location: organizationUnitEntity.location,
          parentOrganization: organizationUnitEntity?.parentOrganization,
          manager: organizationUnitEntity.manager,
        };
    startDate.current = result?.startDate;
    return result;
  };

  const {
    register,
    handleSubmit,
    control,
    reset: formReset,
  } = useForm({
    defaultValues: defaultValues(),
    mode: 'onChange',
  });

  return (
    <Card className="jh-card">
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="iamdentityApp.organizationUnit.home.createOrEditLabel" data-cy="OrganizationUnitCreateUpdateHeading">
            <Translate contentKey="iamdentityApp.organizationUnit.home.createOrEditLabel">Create or edit a OrganizationUnit</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading || updating ? (
            <div className="entity-spinner-container">
              <Spinner color="primary">Loading...</Spinner>
            </div>
          ) : (
            <Form onSubmit={handleSubmit(saveEntity)}>
              {!isNew ? (
                <ValidatedField
                  register={register}
                  name="id"
                  required
                  readOnly
                  id="organization-unit-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.organizationUnit.name')}
                id="organization-unit-name"
                name="name"
                readOnly
                data-cy="name"
                type="text"
              />
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.organizationUnit.shortName')}
                id="organization-unit-shortName"
                name="shortName"
                readOnly
                data-cy="shortName"
                type="text"
              />
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.organizationUnit.startDate')}
                id="organization-unit-startDate"
                name="startDate"
                readOnly
                data-cy="startDate"
                type="date"
                onChange={event => (startDate.current = event.target.value)}
              />
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.organizationUnit.endDate')}
                id="organization-unit-endDate"
                name="endDate"
                readOnly
                data-cy="endDate"
                type="date"
                min={startDate.current}
              />
              <FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.organizationUnit.organizationUnitType" />
                </Label>
                <PaginatedReactSelect
                  control={control}
                  name="organizationUnitType"
                  url={organizationUnitsTypeUrl}
                  queryProps={{
                    name: 'name',
                    type: 'string',
                  }}
                  isDisabled
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.organizationUnit.location" />
                </Label>
                <PaginatedReactSelect
                  control={control}
                  name="location"
                  url={locationsUrl}
                  queryProps={{
                    name: 'name',
                    type: 'string',
                  }}
                  isDisabled
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.organizationUnit.parentOrganization" />
                </Label>
                <PaginatedReactSelect
                  control={control}
                  name="parentOrganization"
                  url={organizationUnitsUrl}
                  queryProps={{
                    name: 'name',
                    type: 'string',
                  }}
                  isDisabled
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.organizationUnit.manager" />
                </Label>
                <PaginatedReactSelect
                  control={control}
                  name="manager"
                  url={employeesUrl}
                  queryProps={{
                    name: 'code',
                    secondName: 'fullName',
                    type: 'string',
                    criteria: 'nameWithCode',
                  }}
                  isDisabled
                />
              </FormGroup>
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.organizationUnit.code')}
                id="code"
                name="code"
                data-cy="code"
                type="text"
              />
              {customAttributeDefinitions?.map(cad =>
                renderCustomAttributeField({
                  register: register,
                  options: cad.selectableOptions,
                  id: `organization-unit-${cad.displayName}`,
                  name: cad.displayName,
                  label: cad.displayName,
                  'data-cy': cad.displayName,
                  attributeType: cad.customAttributeType,
                })
              )}
              <div className="mt-1">
                <Button id="cancel-save" data-cy="entityCreateCancelButton" onClick={() => navigate(-1)} color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </div>
            </Form>
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default OrganizationUnitUpdate;
