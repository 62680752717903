/* eslint-disable react/jsx-key */
import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { TextFormat } from 'react-jhipster';
import { convertDateFormat } from 'app/shared/util/date-utils';
import { getEntity } from './recertification-session-user-role.reducer';
import { getEntitiesByObjectType as getCustomPropertiesByObjectType } from 'app/entities/custom-attribute-definition/custom-attribute-definition.reducer';
import { CustomAttributeDtoKey } from 'app/shared/model/custom-attribute-definition.model';
import { CustomAttributeApplicableObjectType } from 'app/shared/model/enumerations/custom-attribute-applicable-object-type.model';
import { findCustomAttributeValueByKey } from 'app/shared/util/entity-utils';

export const RecertificationSessionUserRoleDetail = () => {
  const dispatch = useAppDispatch();

  const { recertificationSessionUserIdentityRoleId: id } = useParams<'recertificationSessionUserIdentityRoleId'>();

  useEffect(() => {
    dispatch(getEntity(id));
    dispatch(getCustomPropertiesByObjectType(CustomAttributeApplicableObjectType.USER_IDENTITY_ROLE));
  }, []);

  const recertificationSessionUserRoleEntity = useAppSelector(state => state.recertificationSessionUserRole.entity);
  const customAttributeDefinitions: CustomAttributeDtoKey[] = useAppSelector(state => state.customAttributeDefinition.entities);
  const locale = useAppSelector(state => state.locale.currentLocale);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="recertificationSessionUserRoleDetailsHeading">
          <Translate contentKey="iamdentityApp.recertificationSessionUserRole.detail.title">Recertification Session User Role</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <Translate contentKey="global.field.id">ID</Translate>:
          </dt>
          <dd>{recertificationSessionUserRoleEntity.id ? recertificationSessionUserRoleEntity.id : ''}</dd>
          <dt>
            <Translate contentKey="iamdentityApp.recertificationSession.detail.title">Recertification Session</Translate>:
          </dt>
          <dd>
            {recertificationSessionUserRoleEntity.recertificationSession
              ? recertificationSessionUserRoleEntity.recertificationSession.name
              : ''}
          </dd>
          <dt>
            <Translate contentKey="iamdentityApp.recertificationSessionUserRole.application">Application</Translate>:
          </dt>
          <dd>
            {recertificationSessionUserRoleEntity.userRole ? (
              <Link
                to={`/application/${
                  recertificationSessionUserRoleEntity.userRole.roleDefinition
                    ? recertificationSessionUserRoleEntity.userRole.roleDefinition.application.id
                    : ''
                }`}
              >
                {recertificationSessionUserRoleEntity.userRole.roleDefinition
                  ? recertificationSessionUserRoleEntity.userRole.roleDefinition.application.name
                  : ''}
              </Link>
            ) : (
              ''
            )}
          </dd>
          <dt>
            <Translate contentKey="iamdentityApp.recertificationSessionUserRole.roleDefinition">Role Definition</Translate>:
          </dt>
          <dd>
            {recertificationSessionUserRoleEntity.userRole ? (
              <Link
                to={`/role-definition/${
                  recertificationSessionUserRoleEntity.userRole.roleDefinition
                    ? recertificationSessionUserRoleEntity.userRole.roleDefinition.id
                    : ''
                }`}
              >
                {recertificationSessionUserRoleEntity.userRole.roleDefinition
                  ? recertificationSessionUserRoleEntity.userRole.roleDefinition.name
                  : ''}
              </Link>
            ) : (
              ''
            )}
          </dd>
          <dt>
            <Translate contentKey="iamdentityApp.recertificationSessionUserRole.status">Status</Translate>:
          </dt>
          <dd>{recertificationSessionUserRoleEntity.userRole ? recertificationSessionUserRoleEntity.userRole.status : ''}</dd>
          <dt>
            <Translate contentKey="iamdentityApp.recertificationSessionUserRole.grantedBy">Granted By</Translate>:
          </dt>
          <dd>{recertificationSessionUserRoleEntity.userRole ? recertificationSessionUserRoleEntity.userRole.grantedBy : ''}</dd>
          <dt>
            <Translate contentKey="iamdentityApp.recertificationSessionUserRole.validFrom">Valid From</Translate>:
          </dt>
          <dd>
            {recertificationSessionUserRoleEntity.userRole?.startDate ? (
              <TextFormat
                value={recertificationSessionUserRoleEntity.userRole.startDate}
                type="date"
                format={convertDateFormat(locale, 'instant')}
              />
            ) : (
              ''
            )}
          </dd>
          <dt>
            <Translate contentKey="iamdentityApp.recertificationSessionUserRole.validTo">Valid To</Translate>:
          </dt>
          <dd>
            {recertificationSessionUserRoleEntity.userRole?.endDate ? (
              <TextFormat
                value={recertificationSessionUserRoleEntity.userRole.endDate}
                type="date"
                format={convertDateFormat(locale, 'instant')}
              />
            ) : (
              ''
            )}
          </dd>
          {recertificationSessionUserRoleEntity.revoked !== null && recertificationSessionUserRoleEntity.revoked !== undefined ? (
            <div>
              <dt>
                <Translate contentKey="iamdentityApp.recertificationSessionUserRole.revoked">Revoked</Translate>:
              </dt>
              <dd>
                {recertificationSessionUserRoleEntity.revoked === true ? (
                  recertificationSessionUserRoleEntity.order ? (
                    <div>
                      <Translate contentKey="iamdentityApp.recertificationSessionUserRole.revokedByOrder">Revoked by Order</Translate>
                      :&nbsp;
                      <Link to={`/orders/${recertificationSessionUserRoleEntity.order.id}`}>
                        {recertificationSessionUserRoleEntity.order.id}
                      </Link>
                      &nbsp;
                      {recertificationSessionUserRoleEntity?.order?.status ? (
                        <span className="fw-bold text-white d-inline-block">({recertificationSessionUserRoleEntity?.order?.status})</span>
                      ) : (
                        ''
                      )}
                    </div>
                  ) : (
                    <Translate contentKey="iamdentityApp.recertificationSessionUserRole.revokedByOrder">Revoked by Order</Translate>
                  )
                ) : (
                  <Translate contentKey="iamdentityApp.recertificationSessionUserRole.confirmed">Confirmed</Translate>
                )}
              </dd>
            </div>
          ) : null}
          {customAttributeDefinitions?.map(cad => (
            <React.Fragment>
              <dt>
                <span id={cad.displayName}>{cad.displayName}</span>
              </dt>
              <dd>
                {recertificationSessionUserRoleEntity.userRole
                  ? findCustomAttributeValueByKey(cad, recertificationSessionUserRoleEntity.userRole)
                  : ''}
              </dd>
            </React.Fragment>
          ))}
          <dt>
            <Translate contentKey="global.field.createdBy">Created By</Translate>:
          </dt>
          <dd>{recertificationSessionUserRoleEntity.createdBy ? recertificationSessionUserRoleEntity.createdBy : ''}</dd>
          <dt>
            <Translate contentKey="global.field.createdDate">Created Date</Translate>:
          </dt>
          <dd>
            {recertificationSessionUserRoleEntity.createdDate ? (
              <TextFormat
                value={recertificationSessionUserRoleEntity.createdDate}
                type="date"
                format={convertDateFormat(locale, 'instant')}
              />
            ) : (
              ''
            )}
          </dd>
          <dt>
            <Translate contentKey="global.field.lastModifiedBy">Last Modify By</Translate>:
          </dt>
          <dd>{recertificationSessionUserRoleEntity.lastModifiedBy ? recertificationSessionUserRoleEntity.lastModifiedBy : ''}</dd>
          <dt>
            <Translate contentKey="global.field.lastModifiedDate">Last Modify Date</Translate>:
          </dt>
          <dd>
            {recertificationSessionUserRoleEntity.lastModifiedDate ? (
              <TextFormat
                value={recertificationSessionUserRoleEntity.lastModifiedDate}
                type="date"
                format={convertDateFormat(locale, 'instant')}
              />
            ) : (
              ''
            )}
          </dd>
        </dl>
        <Button
          tag={Link}
          to={`/recertification-session/${
            recertificationSessionUserRoleEntity.recertificationSession
              ? recertificationSessionUserRoleEntity.recertificationSession.id
              : ''
          }`}
          replace
          color="info"
          data-cy="entityDetailsBackButton"
        >
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default RecertificationSessionUserRoleDetail;
