import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, Spinner, Form, FormGroup, Label } from 'reactstrap';
import { Translate, translate, ValidatedField } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';
import PaginatedReactSelect from 'app/shared/components/react-select/paginated-react-select';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEmployeeManagers as getEmployeeManagers } from 'app/entities/employee/employee.reducer';
import { startRecertificationSessionProcess } from './recertification-session.reducer';
import { apiUrl as applicationsUrl } from 'app/entities/application/application.reducer';
import { RecertificationSessionPermissionType } from 'app/shared/model/enumerations/recertification-session-permission-type.model';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import PrimeReact from 'primereact/api';
import { toast, ToastContainer } from 'react-toastify';
import './details-components/theme/theme.css';

export const RecertificationSessionProcess = ({ setRecertificationIsModalOpen }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;
  const employeeManagers = useAppSelector(state => state.employee.entities);
  const employeeManagersLoading = useAppSelector(state => state.employee.loading);
  const recertificationSessionEntity = useAppSelector(state => state.recertificationSession.entity);
  const loading = useAppSelector(state => state.recertificationSession.loading);
  const updating = useAppSelector(state => state.recertificationSession.updating);
  const updateSuccess = useAppSelector(state => state.recertificationSession.updateSuccess);
  const recertificationSessionPermissionTypes = Object.keys(RecertificationSessionPermissionType);
  const [selectedEmployeeManagers, setSelectedEmployeeManagers] = useState([]);
  useEffect(() => {
    if (employeeManagers.length > 0) {
      setSelectedEmployeeManagers(employeeManagers);
    }
  }, [employeeManagers]);

  useEffect(() => {
    dispatch(getEmployeeManagers({}));
  }, []);

  // PrimeReact set overlay zIndex
  PrimeReact.zIndex = {
    overlay: 99999,
  };

  const [recertificationSessionPermissionTypeState, setPermissionType] = useState('ALL');

  const handlePermissionTypeChange = event => {
    setPermissionType(event.target.value);
  };

  useEffect(() => {
    formReset(defaultValues());
  }, [recertificationSessionEntity]);

  const saveEntity = values => {
    if (selectedEmployeeManagers.length === 0) {
      toast.error(translate('iamdentityApp.recertificationSession.employeeManagersLengthErrorMessage'));
      return;
    }
    const entity = {
      application: values.application ? values.application : null,
      managers: selectedEmployeeManagers,
      permissionType: recertificationSessionPermissionTypeState ? recertificationSessionPermissionTypeState : '',
    };
    dispatch(startRecertificationSessionProcess(entity));
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...recertificationSessionEntity,
        };

  const {
    register,
    handleSubmit,
    control,
    reset: formReset,
  } = useForm({
    defaultValues: defaultValues(),
    mode: 'onChange',
  });

  const handleCancelRecertificationModal = () => {
    if (setRecertificationIsModalOpen) {
      setRecertificationIsModalOpen(false);
    }
  };

  const handleClose = () => {
    handleCancelRecertificationModal();
    navigate('/recertification-session');
  };

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="12">
          {loading || updating ? (
            <div className="entity-spinner-container">
              <Spinner color="primary">Loading...</Spinner>
            </div>
          ) : (
            <Form onSubmit={handleSubmit(saveEntity)}>
              <div className="form-fields-container mb-3" style={{ maxHeight: 'calc(80vh - 95px)', overflowY: 'auto' }}>
                {!isNew ? (
                  <ValidatedField
                    register={register}
                    name="id"
                    required
                    readOnly
                    id="recertification-session-id"
                    label={translate('global.field.id')}
                    validate={{ required: true }}
                  />
                ) : null}
                <FormGroup>
                  <Label>
                    <Translate contentKey="iamdentityApp.recertificationSession.application" />
                  </Label>
                  <PaginatedReactSelect
                    control={control}
                    name="application"
                    url={applicationsUrl}
                    queryProps={{
                      name: 'name',
                      type: 'string',
                    }}
                  />
                </FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.recertificationSession.selectManager" />
                </Label>
                <DataTable
                  loading={employeeManagersLoading}
                  value={employeeManagers.map(item => ({ ...item, managerName: item.code + ' - ' + item.fullName }))}
                  sortMode="multiple"
                  filterDisplay="row"
                  selection={selectedEmployeeManagers}
                  onSelectionChange={e => setSelectedEmployeeManagers(e.value)}
                  dataKey="id"
                  paginator
                  selectionMode={'checkbox'}
                  rows={5}
                  rowsPerPageOptions={[5, 10, 25]}
                  emptyMessage={translate('iamdentityApp.recertificationSession.home.employeeManagersNotFound') + '.'}
                >
                  <Column selectionMode="multiple"></Column>
                  <Column
                    showFilterMenu
                    filterMatchMode="contains"
                    header={translate('iamdentityApp.recertificationSession.managerName')}
                    filter
                    filterPlaceholder={translate('iamdentityApp.recertificationSession.searchByManagerName')}
                    field="managerName"
                    sortable
                    body={(rowData: any) =>
                      rowData ? (
                        <Link target="blank" to={`/employee/${rowData.id}`}>
                          {rowData.managerName}
                        </Link>
                      ) : (
                        ''
                      )
                    }
                  />
                  <Column
                    header={translate('iamdentityApp.recertificationSession.organizationUnit')}
                    filter
                    filterPlaceholder={translate('iamdentityApp.recertificationSession.searchByOrganizationUnit')}
                    field="organizationUnit.name"
                    showFilterMenu
                    filterMatchMode="contains"
                    sortable
                    body={(rowData: any) =>
                      rowData.organizationUnit ? (
                        <Link target="blank" to={`/organization-unit/${rowData.organizationUnit.id}`}>
                          {rowData.organizationUnit.name}
                        </Link>
                      ) : (
                        ''
                      )
                    }
                  />
                </DataTable>
                <ValidatedField
                  control={control}
                  label={translate('iamdentityApp.recertificationSession.permissionType')}
                  className="mt-3"
                  id="recertification-session-permission-type"
                  name="recertificationSessionPermissionType"
                  data-cy="recertificationSessionPermissionType"
                  type="select"
                  onChange={handlePermissionTypeChange}
                >
                  {recertificationSessionPermissionTypes.map(recertificationSessionPermissionType => (
                    <option value={recertificationSessionPermissionType} key={recertificationSessionPermissionType}>
                      {translate('iamdentityApp.RecertificationSessionPermissionType.' + recertificationSessionPermissionType)}
                    </option>
                  ))}
                </ValidatedField>
              </div>
              <div style={{ borderTop: '1px solid #ffffff33', paddingTop: '20px' }} className="sticky-bottom">
                <div className="mt-1 text-right">
                  <Button onClick={handleCancelRecertificationModal} color="secondary">
                    <Translate contentKey="entity.action.cancel">Cancel</Translate>
                  </Button>
                  &nbsp;
                  <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                    <FontAwesomeIcon icon="sync" />
                    &nbsp;
                    <Translate contentKey="iamdentityApp.recertificationSession.home.start">Start</Translate>
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default RecertificationSessionProcess;
