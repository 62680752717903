import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Button, Card, Col, Form, FormGroup, Label, Row, Spinner } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import {
  addCustomPropertiesToCustomAttributesMap,
  convertReactSelectValuesToEntityIds,
  extractCustomAttributesAsEntityProperties,
} from 'app/shared/util/entity-utils';

import { getEntities as getPermissionDefinitions } from 'app/entities/permission-definition/permission-definition.reducer';
import { getEntities as getUserIdentities } from 'app/entities/user-identity/user-identity.reducer';
import { apiUrl as userIdentityUrl } from 'app/entities/user-identity/user-identity.reducer';
import { apiUrl as permisionDefinitionUrl } from 'app/entities/permission-definition/permission-definition.reducer';
import { getEntitiesByObjectType as getCustomPropertiesByObjectType } from 'app/entities/custom-attribute-definition/custom-attribute-definition.reducer';
import { CustomAttributeDtoKey } from 'app/shared/model/custom-attribute-definition.model';
import { CustomAttributeApplicableObjectType } from 'app/shared/model/enumerations/custom-attribute-applicable-object-type.model';
import { PermissionGrantLevel } from 'app/shared/model/enumerations/permission-grant-level.model';
import { renderCustomAttributeField } from 'app/shared/util/component-utils';
import { useRef } from 'react';
import { createEntity, getEntity, reset, updateEntity } from './specific-permission.reducer';
import { useForm } from 'react-hook-form';
import PaginatedReactSelect from 'app/shared/components/react-select/paginated-react-select';
import { ISpecificPermission } from 'app/shared/model/specific-permission.model';
import { apiUrl as ordersUrl } from 'app/entities/orders/orders.reducer';
import { buildSearchParamsObject } from 'app/shared/util/url-utils';

interface ISearchParamsObject {
  order?: { id: number };
}

export const SpecificPermissionUpdate = () => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchParamsObject, setSearchParamsObject] = useState<ISearchParamsObject>(buildSearchParamsObject(searchParams));
  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const specificPermissionEntity: ISpecificPermission = useAppSelector(state => state.specificPermission.entity);
  const loading = useAppSelector(state => state.specificPermission.loading);
  const updating = useAppSelector(state => state.specificPermission.updating);
  const updateSuccess = useAppSelector(state => state.specificPermission.updateSuccess);
  const permissionGrantLevelValues = Object.keys(PermissionGrantLevel);
  const customAttributeDefinitions: CustomAttributeDtoKey[] = useAppSelector(state => state.customAttributeDefinition.entities);
  const startDate = useRef('');
  const handleClose = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
    dispatch(getCustomPropertiesByObjectType(CustomAttributeApplicableObjectType.SPECIFIC_PERMISSION));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  useEffect(() => {
    formReset(defaultValues());
  }, [specificPermissionEntity]);

  const saveEntity = values => {
    values.startDate = convertDateTimeToServer(values.startDate);
    values.endDate = convertDateTimeToServer(values.endDate);

    const entity = {
      ...addCustomPropertiesToCustomAttributesMap(
        specificPermissionEntity,
        convertReactSelectValuesToEntityIds(values),
        customAttributeDefinitions
      ),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () => {
    const result = isNew
      ? {
          startDate: displayDefaultDateTime(),
          endDate: displayDefaultDateTime(),
          ...searchParamsObject,
        }
      : {
          ...specificPermissionEntity,
          ...extractCustomAttributesAsEntityProperties(customAttributeDefinitions, specificPermissionEntity),
          startDate: convertDateTimeFromServer(specificPermissionEntity.startDate),
          endDate: convertDateTimeFromServer(specificPermissionEntity.endDate),

          userIdentity: specificPermissionEntity?.userIdentity,
          permissionDefinition: specificPermissionEntity?.permissionDefinition,
          lastModifiedByOrder: specificPermissionEntity?.lastModifiedByOrder,
          ...searchParamsObject,
        };
    startDate.current = result?.startDate;
    return result;
  };

  const {
    register,
    handleSubmit,
    control,
    reset: formReset,
  } = useForm({
    defaultValues: defaultValues(),
    mode: 'onChange',
  });

  return (
    <Card className="jh-card">
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="iamdentityApp.specificPermission.home.createOrEditLabel" data-cy="SpecificPermissionCreateUpdateHeading">
            <Translate contentKey="iamdentityApp.specificPermission.home.createOrEditLabel">Create or edit a SpecificPermission</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading || updating ? (
            <div className="entity-spinner-container">
              <Spinner color="primary">Loading...</Spinner>
            </div>
          ) : (
            <Form onSubmit={handleSubmit(saveEntity)}>
              {!isNew ? (
                <ValidatedField
                  register={register}
                  name="id"
                  required
                  readOnly
                  id="specific-permission-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.specificPermission.description')}
                id="specific-permission-description"
                name="description"
                data-cy="description"
                type="text"
              />
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.specificPermission.startDate')}
                id="specific-permission-startDate"
                name="startDate"
                data-cy="startDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                onChange={event => (startDate.current = event.target.value)}
              />
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.specificPermission.endDate')}
                id="specific-permission-endDate"
                name="endDate"
                data-cy="endDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                min={startDate.current}
              />
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.specificPermission.grantedBy')}
                id="specific-permission-grantedBy"
                name="grantedBy"
                data-cy="grantedBy"
                type="select"
              >
                {permissionGrantLevelValues.map(permissionGrantLevel => (
                  <option value={permissionGrantLevel} key={permissionGrantLevel}>
                    {translate('iamdentityApp.PermissionGrantLevel.' + permissionGrantLevel)}
                  </option>
                ))}
              </ValidatedField>
              {customAttributeDefinitions?.map(cad =>
                renderCustomAttributeField({
                  register: register,
                  options: cad.selectableOptions,
                  id: `specific-permission-${cad.displayName}`,
                  name: cad.displayName,
                  label: cad.displayName,
                  'data-cy': cad.displayName,
                  attributeType: cad.customAttributeType,
                })
              )}
              <FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.specificPermission.userIdentity" />
                </Label>
                <PaginatedReactSelect
                  control={control}
                  name="userIdentity"
                  url={userIdentityUrl}
                  queryProps={{
                    name: 'userName',
                    type: 'string',
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.specificPermission.permissionDefinition" />
                </Label>
                <PaginatedReactSelect
                  control={control}
                  name="permissionDefinition"
                  url={permisionDefinitionUrl}
                  queryProps={{
                    name: 'name',
                    type: 'string',
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.specificPermission.lastModifiedByOrder" />
                </Label>
                <PaginatedReactSelect
                  control={control}
                  name="lastModifiedByOrder"
                  url={ordersUrl}
                  isDisabled={!!searchParamsObject?.order}
                  queryProps={{
                    name: 'id',
                    type: 'string',
                  }}
                />
              </FormGroup>
              <div className="mt-1">
                <Button id="cancel-save" data-cy="entityCreateCancelButton" onClick={() => navigate(-1)} color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </div>
            </Form>
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default SpecificPermissionUpdate;
