import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import '../style.scss';
import { DropdownItem, NodeType } from '../types';
import { useOutsideCloser } from '../utils';

interface IMenu {
  name: string;
  backTo?: string;
  values: { toMenu?: string; name: string; value: string; type: NodeType | '_COPY' }[];
}

interface IDropdownMenuProps {
  menus: IMenu[];
}

interface INodeProps {
  value?: string | number | boolean;
  color?: 'red' | 'white' | 'orange' | 'green' | 'blue';
  type?: 'node' | 'change' | 'add';
  items?: DropdownItem[];
  onChange?: (value: { value: string; type: NodeType | '_COPY' }) => void;
  children?: any;
}

const createMenu = (items: DropdownItem[], name = null, backTo = null) => {
  const menus = [];
  const menu: IMenu = {
    name,
    backTo,
    values: [],
  };
  for (const item of items) {
    if (Array.isArray(item.value)) {
      menus.push([
        ...createMenu(
          item.value.map(i => ({ name: i.name, value: i.value, type: item.type })),
          item.name,
          name
        ),
      ]);
      menu.values.push({
        name: item.name,
        value: item.name,
        type: item.type,
        toMenu: item.name,
      });
    } else {
      menu.values.push({
        name: item.name,
        value: item.value,
        type: item.type,
      });
    }
  }

  menus.splice(0, 0, menu);
  return menus.flat(1);
};

const createMenus = (items: DropdownItem[]) => {
  let menus = [];

  if (items.length) {
    if (items.length == 1 && Array.isArray(items[0].value)) {
      items = items[0].value.map(v => ({ name: v.name, value: v.value, type: items[0].type }));
    }
    menus = createMenu(items, 'main');
  }

  return menus;
};

function NodeComponent({ value, items = [], color = 'white', type = 'node', onChange, children }: INodeProps) {
  const [open, setOpen] = useState<boolean>(false);

  const wrapperRef = useRef(null);
  useOutsideCloser(wrapperRef, () => setOpen(false));

  const menus = createMenus(items);

  const DropdownMenu = ({ menus }: IDropdownMenuProps) => {
    const [activeMenu, setActiveMenu] = useState('main');
    const [menuHeight, setMenuHeight] = useState(null);
    const [animation, setAnimation] = useState<'left' | 'right'>('right');

    function calcHeight(el: any) {
      const height = el.offsetHeight;
      setMenuHeight(height);
    }

    const mainMenu = menus.find(men => men.name === 'main');
    const otherMenus = menus.filter(men => men.name !== 'main');

    const callback = (itm: { value: string; type: NodeType | '_COPY' }) => {
      if (onChange) {
        onChange(itm);
        setOpen(false);
      }
    };

    return (
      <div ref={wrapperRef} className="dropdown" style={{ height: menuHeight }}>
        <CSSTransition key="main" in={activeMenu === 'main'} unmountOnExit timeout={200} classNames="menu-primary" onEnter={calcHeight}>
          <div className="menu">
            {mainMenu.values.map(item => {
              const dis = item.value === value;

              if (item.toMenu) {
                return (
                  <div
                    key={`item-${item.name}`}
                    onClick={() => {
                      setAnimation('right');
                      setActiveMenu(item.toMenu);
                    }}
                    className="item"
                  >
                    <span>{item.name}</span>
                    <FontAwesomeIcon icon="arrow-right" />
                  </div>
                );
              } else {
                let extraClass = '';
                if (item.value == '_COPY') {
                  extraClass = 'strong italic';
                }
                return (
                  <div
                    key={`item-${item.name}`}
                    onClick={() => (dis ? null : callback({ value: item.value, type: item.type }))}
                    className={`item ${extraClass} ${dis ? 'disabled' : ''}`}
                  >
                    {item.name}
                  </div>
                );
              }
            })}
          </div>
        </CSSTransition>

        {otherMenus.map(men => (
          <CSSTransition
            key={men.name}
            in={activeMenu === men.name}
            unmountOnExit
            timeout={200}
            classNames={
              activeMenu === men.name
                ? animation === 'right'
                  ? 'menu-secondary'
                  : 'menu-primary'
                : animation === 'right'
                ? 'menu-primary'
                : 'menu-secondary'
            }
            onEnter={calcHeight}
          >
            <div className="menu">
              <div
                onClick={() => {
                  setAnimation('left');
                  setActiveMenu(men.backTo);
                }}
                className="item"
              >
                <FontAwesomeIcon icon="arrow-left" />
              </div>
              {men.values.map(item => {
                const dis = item.value === value;
                if (item.toMenu) {
                  return (
                    <div
                      key={`item-${item.name}`}
                      onClick={() => {
                        setAnimation('right');
                        setActiveMenu(item.toMenu);
                      }}
                      className="item"
                    >
                      <span>{item.name}</span>
                      <FontAwesomeIcon icon="arrow-right" />
                    </div>
                  );
                } else {
                  let extraClass = '';
                  if (item.value == '_COPY') {
                    extraClass = 'strong italic';
                  }
                  return (
                    <div
                      key={`item-${item.name}`}
                      onClick={() => (dis ? null : callback({ value: item.value, type: item.type }))}
                      className={`item ${extraClass} ${dis ? 'disabled' : ''}`}
                    >
                      {item.name}
                    </div>
                  );
                }
              })}
            </div>
          </CSSTransition>
        ))}
      </div>
    );
  };

  let buttonComponent = null;

  if (type == 'node') {
    buttonComponent = (
      <div className={`node ${color}`} onClick={() => setOpen(!open)}>
        <span className="text">{children}</span>
      </div>
    );
  } else if (type == 'change') {
    buttonComponent = <FontAwesomeIcon className="change-button" icon="ellipsis-v" size="lg" onClick={() => setOpen(!open)} />;
  } else if (type == 'add') {
    buttonComponent = <FontAwesomeIcon className="add-button" icon="plus" size="lg" onClick={() => setOpen(!open)} />;
  }

  return (
    <div className="node-div">
      <div className="vertical-center">{buttonComponent}</div>
      {items.length && open ? <DropdownMenu menus={menus} /> : null}
    </div>
  );
}

export default NodeComponent;
