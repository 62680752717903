import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import axios from 'axios';

import { APP_DATETIME_SAFE_FORMAT } from 'app/config/constants';
import { getEntities as getMyTasks } from 'app/entities/tasks/tasks.reducer';
import { CustomAttributeDtoKey, ICustomAttributeDefinition } from 'app/shared/model/custom-attribute-definition.model';
import { CustomAttributeApplicableObjectType } from 'app/shared/model/enumerations/custom-attribute-applicable-object-type.model';
import { defaultValue, ICreateOrder, IOrder } from 'app/shared/model/order.model';
import { createEntitySlice, EntityState, IQueryParams, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { generateQueryUrl } from 'app/shared/util/url-utils';
import saveAs from 'file-saver';
import moment from 'moment';
import { toast } from 'react-toastify';
import { getEntitiesByRecertificationSessionId as getRecertificationSessionUserIdentitiesByRecertificationSessionId } from 'app/entities/recertification-session-user-identity/recertification-session-user-identity.reducer';
import { getEntitiesByRecertificationSessionId as getRecertificationSessionUserRolesByRecertificationSessionId } from 'app/entities/recertification-session-user-role/recertification-session-user-role.reducer';
import { getEntitiesByRecertificationSessionId as getRecertificationSessionUserGroupsByRecertificationSessionId } from 'app/entities/recertification-session-user-group/recertification-session-user-group.reducer';
import { getEntitiesByRecertificationSessionId as getRecertificationSessionUserSpecificPermissionsByRecertificationSessionId } from 'app/entities/recertification-session-specific-permission/recertification-session-specific-permission.reducer';

const initialState: EntityState<IOrder> & {
  orderedRoleCustomAttributes: CustomAttributeDtoKey[];
  orderedRoleGroupCustomAttributes: CustomAttributeDtoKey[];
  orderedSpecificPermissionCustomAttributes: CustomAttributeDtoKey[];
  orderedUserIdentityCustomAttributes: CustomAttributeDtoKey[];
} = {
  loading: false,
  errorMessage: null,
  entities: [],
  orderedRoleCustomAttributes: [],
  orderedRoleGroupCustomAttributes: [],
  orderedSpecificPermissionCustomAttributes: [],
  orderedUserIdentityCustomAttributes: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

export const apiUrl = 'api/orders';
export const apiAppListUrl = 'api/order-create/application-list';
export const apiUsersListUrl = 'api/order-create/user-identity-list';
export const apiRoleDefListUrl = 'api/order-create/role-definition-list';
export const apiGroupDefListUrl = 'api/order-create/group-definition-list';
export const apiPermDefListUrl = 'api/order-create/permission-definition-list';
export const hrResyncParams = 'api/order-create/hr-resync-params';
export const sprResync = 'api/order-create/spr-resync';
export const checkAdminSprEmployeeProfileUrl = 'api/order-create/canInitialLoad';
export const sprEvaluateEmployeeProfileUrl = 'api/order-create/doInitialLoad';
export const checkCanSimulateResyncUrl = 'api/order-create/canSimulateResync';
export const simulateResyncUrl = 'api/order-create/simulateResync';
export const simulateResyncResultsUrl = 'api/order-create/simulateResyncResults';
const apiSearchUrl = 'api/_search/orders';

// Actions

export const getCheckAdminSprEmployeeProfile = createAsyncThunk('orders/fetch_canStartInitialLoadUrl', async () => {
  const requestUrl = checkAdminSprEmployeeProfileUrl;
  const result = await axios.get<{ value: boolean }>(requestUrl);
  return result;
});

export const getcheckCanSimulateResync = createAsyncThunk('orders/fetch_canSimulateResync', async () => {
  const requestUrl = checkCanSimulateResyncUrl;
  const result = await axios.get<{ value: boolean }>(requestUrl);
  return result;
});

export const getSimulateResyncResultsUrl = createAsyncThunk('orders/fetch_simulateResyncResultsUrl', async () => {
  const requestUrl = simulateResyncResultsUrl;
  const result = await axios.get<any>(requestUrl);
  return result;
});

export const getOrderedRoleCustomAttributes = createAsyncThunk('orders/fetch_OrderedRoleCustomAttributes', async () => {
  const requestUrl = `api/custom-attribute-definitions-for-objectType/${CustomAttributeApplicableObjectType.ORDERED_ROLE}`;
  return axios.get<ICustomAttributeDefinition[]>(requestUrl);
});

export const executeHrResyncParams = createAsyncThunk('orders/fetch_OrderHrResyncParams', async () => {
  const requestUrl = hrResyncParams;
  return axios.get<any>(requestUrl);
});
export const executeSprResync = createAsyncThunk('orders/fetch_OrderSprResync', async () => {
  const requestUrl = sprResync;
  return axios.get<any>(requestUrl);
});
export const getOrderedRoleGroupCustomAttributes = createAsyncThunk('orders/fetch_OrderedRoleGroupCustomAttributes', async () => {
  const requestUrl = `api/custom-attribute-definitions-for-objectType/${CustomAttributeApplicableObjectType.ORDERED_GROUP}`;
  return axios.get<ICustomAttributeDefinition[]>(requestUrl);
});

export const getOrderedSpecificPermissionCustomAttributes = createAsyncThunk(
  'orders/fetch_OrderedSpecificPermissionCustomAttributes',
  async () => {
    const requestUrl = `api/custom-attribute-definitions-for-objectType/${CustomAttributeApplicableObjectType.ORDERED_SPECIFIC_PERMISSION}`;
    return axios.get<ICustomAttributeDefinition[]>(requestUrl);
  }
);

export const getOrderedUserIdentityCustomAttributes = createAsyncThunk('orders/fetch_OrderedUserIdentityCustomAttributes', async () => {
  const requestUrl = `api/custom-attribute-definitions-for-objectType/${CustomAttributeApplicableObjectType.ORDERED_USER_IDENTITY}`;
  return axios.get<ICustomAttributeDefinition[]>(requestUrl);
});

export const searchEntities = createAsyncThunk('orders/search_entity', async ({ query, page, size, sort }: IQueryParams) => {
  const requestUrl = `${apiSearchUrl}?query=${query}${sort ? `&page=${page}&size=${size}&sort=${sort}` : ''}`;
  return axios.get<IOrder[]>(requestUrl);
});

export const getEntities = createAsyncThunk('orders/fetch_entity_list', async (params: IQueryParams) => {
  const requestUrl = `${apiUrl}${generateQueryUrl(params)}`;
  return axios.get<IOrder[]>(requestUrl);
});

export const exportEntities = createAsyncThunk('orders/export_entity_list', async (params: IQueryParams) => {
  const requestUrl = `${apiUrl}/export${generateQueryUrl(params)}`;
  return axios.get<any>(requestUrl, {
    responseType: 'blob',
  });
});

export const sprEvaluateEmployeeProfile = createAsyncThunk('orders/doInitialLoad', async () => {
  const requestUrl = sprEvaluateEmployeeProfileUrl;
  const results = await axios.post(requestUrl, {}, { timeout: 1000 * 60 * 60 });
  return results;
});

export const simulateResync = createAsyncThunk('orders/simulateResync', async () => {
  const requestUrl = simulateResyncUrl;
  const results = await axios.post(requestUrl);
  return results;
});

export const generateOrdered = createAsyncThunk(
  'orders/generate-ordered',
  async ({ profileId, orderId }: { profileId: number; orderId: number }) => {
    const requestUrl = `http://localhost:9000/api/generate-ordered`;
    axios.post(requestUrl, {
      profileId,
      orderId,
    });
  }
);

export const revokeUserIdentityById = createAsyncThunk(
  'orders/revoke-user-identity',
  async (
    {
      recertificationSessionEntityId,
      recertificationSessionUserIdentityIdForRevokeInit,
    }: { recertificationSessionEntityId: number; recertificationSessionUserIdentityIdForRevokeInit: number },
    thunkAPI
  ) => {
    const requestUrl = `api/order-create/revoke-user-identity/${recertificationSessionUserIdentityIdForRevokeInit}`;
    const result = await axios.post(requestUrl);
    thunkAPI.dispatch(getRecertificationSessionUserIdentitiesByRecertificationSessionId(recertificationSessionEntityId));
    thunkAPI.dispatch(getRecertificationSessionUserRolesByRecertificationSessionId(recertificationSessionEntityId));
    thunkAPI.dispatch(getRecertificationSessionUserGroupsByRecertificationSessionId(recertificationSessionEntityId));
    thunkAPI.dispatch(getRecertificationSessionUserSpecificPermissionsByRecertificationSessionId(recertificationSessionEntityId));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const revokeUserRoleById = createAsyncThunk(
  'orders/revoke-user-identity-role',
  async (
    {
      recertificationSessionEntityId,
      recertificationSessionRoleIdForRevokeInit,
    }: { recertificationSessionEntityId: number; recertificationSessionRoleIdForRevokeInit: number },
    thunkAPI
  ) => {
    const requestUrl = `api/order-create/revoke-user-identity-role/${recertificationSessionRoleIdForRevokeInit}`;
    const result = await axios.post(requestUrl);
    thunkAPI.dispatch(getRecertificationSessionUserRolesByRecertificationSessionId(recertificationSessionEntityId));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const revokeUserGroupById = createAsyncThunk(
  'orders/revoke-user-group',
  async (
    {
      recertificationSessionEntityId,
      recertificationSessionGroupIdForRevokeInit,
    }: { recertificationSessionEntityId: number; recertificationSessionGroupIdForRevokeInit: number },
    thunkAPI
  ) => {
    const requestUrl = `api/order-create/revoke-user-group/${recertificationSessionGroupIdForRevokeInit}`;
    const result = await axios.post(requestUrl);
    thunkAPI.dispatch(getRecertificationSessionUserGroupsByRecertificationSessionId(recertificationSessionEntityId));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const revokeUserSpecificPermissionById = createAsyncThunk(
  'orders/revoke-specific-permission',
  async (
    {
      recertificationSessionEntityId,
      recertificationSessionSpecificPermissionIdForRevokeInit,
    }: { recertificationSessionEntityId: number; recertificationSessionSpecificPermissionIdForRevokeInit: number },
    thunkAPI
  ) => {
    const requestUrl = `api/order-create/revoke-specific-permission/${recertificationSessionSpecificPermissionIdForRevokeInit}`;
    const result = await axios.post(requestUrl);
    thunkAPI.dispatch(getRecertificationSessionUserSpecificPermissionsByRecertificationSessionId(recertificationSessionEntityId));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const cancelProcess = createAsyncThunk(
  'orders/cancel_process',
  async (orderId: string | number, thunkAPI) => {
    const requestUrl = `api/user-process/cancel?orderId=${orderId}`;
    const result = await axios.post(requestUrl);
    thunkAPI.dispatch(getEntity(orderId));
    thunkAPI.dispatch(getEntities({}));
    thunkAPI.dispatch(getMyTasks({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const refreshRiskLevel = createAsyncThunk(
  'orders/refresh_risk_level',
  async (orderId: string | number, thunkAPI) => {
    const requestUrl = `api/orders/${orderId}/updateRiskLevel`;
    const result = await axios.get(requestUrl);
    thunkAPI.dispatch(getEntity(orderId));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const getEntity = createAsyncThunk(
  'orders/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IOrder>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'orders/create_entity',
  async (entity: ICreateOrder, thunkAPI) => {
    const result = await axios.post<IOrder>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'orders/update_entity',
  async (entity: IOrder, thunkAPI) => {
    const result = await axios.put<IOrder>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'orders/partial_update_entity',
  async (entity: IOrder, thunkAPI) => {
    const result = await axios.patch<IOrder>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'orders/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IOrder>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const startProcess = createAsyncThunk(
  'orders/start_process',
  async (orderId: string | number, thunkAPI) => {
    const requestUrl = `api/user-process?orderId=${orderId}`;
    const result = await axios.post(requestUrl);
    thunkAPI.dispatch(getEntity(orderId));
    thunkAPI.dispatch(getEntities({}));
    thunkAPI.dispatch(getMyTasks({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const OrdersSlice = createEntitySlice({
  name: 'orders',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(exportEntities), (state, action) => {
        const { data } = action.payload;

        const filename = `orders_${moment().format(APP_DATETIME_SAFE_FORMAT)}`;

        saveAs(data, `${filename}.xlsx`);
        return {
          ...state,
          loading: false,
        };
      })
      .addMatcher(isFulfilled(generateOrdered, revokeUserIdentityById), (state, action) => {
        return {
          ...state,
          updating: false,
        };
      })
      .addMatcher(isFulfilled(getEntities, searchEntities), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(getOrderedRoleCustomAttributes), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          orderedRoleCustomAttributes: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(getOrderedRoleGroupCustomAttributes), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          orderedRoleGroupCustomAttributes: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(getOrderedSpecificPermissionCustomAttributes), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          orderedSpecificPermissionCustomAttributes: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(getOrderedUserIdentityCustomAttributes), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          orderedUserIdentityCustomAttributes: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity, refreshRiskLevel), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(
        isPending(
          getEntities,
          getEntity,
          searchEntities,
          sprEvaluateEmployeeProfile,
          simulateResync,
          executeHrResyncParams,
          executeSprResync
        ),
        state => {
          state.errorMessage = null;
          state.updateSuccess = false;
          state.loading = true;
        }
      )
      .addMatcher(
        isPending(
          startProcess,
          createEntity,
          updateEntity,
          partialUpdateEntity,
          deleteEntity,
          sprEvaluateEmployeeProfile,
          simulateResync,
          executeHrResyncParams,
          executeSprResync,
          refreshRiskLevel,
          revokeUserIdentityById
        ),
        state => {
          state.errorMessage = null;
          state.updateSuccess = false;
          state.updating = true;
        }
      )
      .addMatcher(
        isFulfilled(startProcess, sprEvaluateEmployeeProfile, simulateResync, executeHrResyncParams, executeSprResync),
        (state, action) => {
          state.updating = false;
          state.loading = false;
          state.updateSuccess = true;
          toast.success('Process started successfully!');
        }
      );
  },
});

export const { reset } = OrdersSlice.actions;

// Reducer
export default OrdersSlice.reducer;
