import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { TextFormat, Translate, translate } from 'react-jhipster';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, Row } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntitiesByObjectType as getCustomPropertiesByObjectType } from 'app/entities/custom-attribute-definition/custom-attribute-definition.reducer';

import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { AuditEntityName } from 'app/shared/model/audit.model';
import { CustomAttributeDtoKey } from 'app/shared/model/custom-attribute-definition.model';
import { CustomAttributeApplicableObjectType } from 'app/shared/model/enumerations/custom-attribute-applicable-object-type.model';
import { findCustomAttributeValueByKey } from 'app/shared/util/entity-utils';
import AuditModal from '../audit/audit-modal';
import { getEntity } from './user-identity-role.reducer';
import { convertDateFormat } from 'app/shared/util/date-utils';

export const UserIdentityRoleDetail = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [auditModal, setAuditModal] = useState<boolean>(false);

  const toggleAuditModal = () => {
    setAuditModal(!auditModal);
  };

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
    dispatch(getCustomPropertiesByObjectType(CustomAttributeApplicableObjectType.USER_IDENTITY_ROLE));
  }, []);

  const userIdentityRoleEntity = useAppSelector(state => state.userIdentityRole.entity);
  const customAttributeDefinitions: CustomAttributeDtoKey[] = useAppSelector(state => state.customAttributeDefinition.entities);
  const locale = useAppSelector(state => state.locale.currentLocale);

  return (
    <React.Fragment>
      <Row>
        <Card className="jh-card">
          <Col md="8">
            <h2 data-cy="userIdentityRoleDetailsHeading">
              <Translate contentKey="iamdentityApp.userIdentityRole.detail.title">UserIdentityRole</Translate>
            </h2>
            <dl className="jh-entity-details">
              <dt>
                <span id="id">
                  <Translate contentKey="global.field.id">ID</Translate>
                </span>
              </dt>
              <dd>{userIdentityRoleEntity.id}</dd>
              <dt>
                <Translate contentKey="iamdentityApp.userIdentityRole.employee">Employee</Translate>
              </dt>
              <dd>
                {userIdentityRoleEntity.userIdentity?.employee
                  ? userIdentityRoleEntity.userIdentity.employee.fullName
                    ? userIdentityRoleEntity.userIdentity.employee.code + ' - ' + userIdentityRoleEntity.userIdentity.employee.fullName
                    : userIdentityRoleEntity.userIdentity.employee.code
                  : ''}
              </dd>
              <dt>
                <Translate contentKey="iamdentityApp.userIdentityRole.application">Application</Translate>
              </dt>
              <dd>{userIdentityRoleEntity.userIdentity?.application ? userIdentityRoleEntity.userIdentity.application.name : ''}</dd>
              <dt>
                <span id="description">
                  <Translate contentKey="iamdentityApp.userIdentityRole.description">Description</Translate>
                </span>
              </dt>
              <dd>{userIdentityRoleEntity.description}</dd>
              <dt>
                <span id="status">
                  <Translate contentKey="iamdentityApp.userIdentityRole.status">Status</Translate>
                </span>
              </dt>
              <dd>{userIdentityRoleEntity.status}</dd>
              <dt>
                <span id="startDate">
                  <Translate contentKey="iamdentityApp.userIdentityRole.startDate">Start Date</Translate>
                </span>
              </dt>
              <dd>
                {userIdentityRoleEntity.startDate ? (
                  <TextFormat value={userIdentityRoleEntity.startDate} type="date" format={convertDateFormat(locale, 'instant')} />
                ) : null}
              </dd>
              <dt>
                <span id="endDate">
                  <Translate contentKey="iamdentityApp.userIdentityRole.endDate">End Date</Translate>
                </span>
              </dt>
              <dd>
                {userIdentityRoleEntity.endDate ? (
                  <TextFormat value={userIdentityRoleEntity.endDate} type="date" format={convertDateFormat(locale, 'instant')} />
                ) : null}
              </dd>
              <dt>
                <span id="grantedBy">
                  <Translate contentKey="iamdentityApp.userIdentityRole.grantedBy">Granted By</Translate>
                </span>
              </dt>
              <dd>{translate(`iamdentityApp.PermissionGrantLevel.${userIdentityRoleEntity.grantedBy}`)}</dd>
              <dt>
                <span id="explicitlyRemoved">
                  <Translate contentKey="iamdentityApp.userIdentityRole.explicitlyRemoved">Explicitly Removed</Translate>
                </span>
              </dt>
              <dd>
                {userIdentityRoleEntity.explicitlyRemoved === null || userIdentityRoleEntity.explicitlyRemoved === undefined
                  ? ''
                  : userIdentityRoleEntity.explicitlyRemoved === true
                  ? translate('global.yes')
                  : translate('global.no')}
              </dd>
              <dt>
                <Translate contentKey="iamdentityApp.userIdentityRole.user">User</Translate>
              </dt>
              <dd>
                {userIdentityRoleEntity.userIdentity ? (
                  <Link to={`/user-identity/${userIdentityRoleEntity.userIdentity.id}`}>
                    {userIdentityRoleEntity.userIdentity.userName}
                  </Link>
                ) : (
                  ''
                )}
              </dd>
              <dt>
                <Translate contentKey="iamdentityApp.userIdentityRole.roleDefinition">Role Definition</Translate>
              </dt>
              <dd>{userIdentityRoleEntity.roleDefinition ? userIdentityRoleEntity.roleDefinition.name : ''}</dd>
              <dt>
                <Translate contentKey="iamdentityApp.userIdentityRole.lastModifiedByOrder">Last Modified By Order</Translate>
              </dt>
              <dd>{userIdentityRoleEntity.lastModifiedByOrder ? userIdentityRoleEntity.lastModifiedByOrder.id : ''}</dd>
              {customAttributeDefinitions?.map(cad => (
                <React.Fragment>
                  <dt>
                    <span id={cad.displayName}>{cad.displayName}</span>
                  </dt>
                  <dd>{findCustomAttributeValueByKey(cad, userIdentityRoleEntity)}</dd>
                </React.Fragment>
              ))}
              <dt>
                <Translate contentKey="global.field.createdBy">Created By</Translate>
              </dt>
              <dd>{userIdentityRoleEntity.createdBy ? userIdentityRoleEntity.createdBy : ''}</dd>
              <dt>
                <Translate contentKey="global.field.createdDate">Created Date</Translate>
              </dt>
              <dd>
                {userIdentityRoleEntity.createdDate ? (
                  <TextFormat value={userIdentityRoleEntity.createdDate} type="date" format={convertDateFormat(locale, 'date')} />
                ) : null}
              </dd>
              <dt>
                <Translate contentKey="global.field.lastModifiedBy">Last Modified By</Translate>
              </dt>
              <dd>{userIdentityRoleEntity.lastModifiedBy ? userIdentityRoleEntity.lastModifiedBy : ''}</dd>
              <dt>
                <Translate contentKey="global.field.lastModifiedDate">Last Modified Date</Translate>
              </dt>
              <dd>
                {userIdentityRoleEntity.lastModifiedDate ? (
                  <TextFormat value={userIdentityRoleEntity.lastModifiedDate} type="date" format={convertDateFormat(locale, 'date')} />
                ) : null}
              </dd>
            </dl>
            <div className="btn-group flex-btn-group-container">
              <Button id="cancel-save" data-cy="entityDetailsBackButton" onClick={() => navigate(-1)} color="info">
                <FontAwesomeIcon icon="arrow-left" />{' '}
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              {/* Comentat deoarece este posibil sa revenim la acest buton de "edit" */}
              {/* <Button tag={Link} to={`/user-identity-role/${userIdentityRoleEntity.id}/edit`} color="primary">
                <FontAwesomeIcon icon="pencil-alt" />{' '}
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.edit">Edit</Translate>
                </span>
              </Button> */}
              <Button onClick={toggleAuditModal} color="light">
                <FontAwesomeIcon icon={faClockRotateLeft} />{' '}
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.history">History</Translate>
                </span>
              </Button>
            </div>
          </Col>
        </Card>
      </Row>
      <AuditModal isOpen={auditModal} toggle={toggleAuditModal} entityId={id} entityName={AuditEntityName.USER_IDENTITY_ROLE} />
    </React.Fragment>
  );
};

export default UserIdentityRoleDetail;
