import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRecertificationSessionUserGroup } from 'app/shared/model/recertification-session-user-group.model';
import React from 'react';
import { Translate, TextFormat, translate } from 'react-jhipster';
import { convertDateFormat } from 'app/shared/util/date-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { Link } from 'react-router-dom';
import { Button, Table } from 'reactstrap';
import { generateAssignedRoleGroupUrl } from './recertification-session-users/recertification-session-users';
import useModal from 'app/shared/components/modal/modal.hook';
import { revokeUserGroupById } from 'app/entities/orders/orders.reducer';

interface IAssignedRecertificationSessionGroupsTablesProps {
  recertificationSessionGroups: IRecertificationSessionUserGroup[];
  isTask?: boolean | false;
}

const AssignedRecertificationSessionGroupsTable = ({
  recertificationSessionGroups,
  isTask,
}: IAssignedRecertificationSessionGroupsTablesProps) => {
  const dispatch = useAppDispatch();
  const locale = useAppSelector(state => state.locale.currentLocale);

  // recertification session revoke user role from task
  const startRevokeUserGroup = (recertificationSessionEntityId, recertificationSessionGroupIdForRevokeInit) => {
    dispatch(revokeUserGroupById({ recertificationSessionEntityId, recertificationSessionGroupIdForRevokeInit }));
  };

  const modal = useModal();
  const revokeUserGroup = (
    recertificationSessionEntityId,
    recertificationSessionGroupIdForRevoke,
    groupNameParam,
    applicationNameParam,
    employeeNameParam
  ) => {
    modal.show({
      size: 'LARGE',
      title: translate('iamdentityApp.recertificationSessionUserGroup.revokeGroup'),
      body: translate('iamdentityApp.recertificationSessionUserGroup.revokeGroupDialog', {
        groupName: groupNameParam,
        applicationName: applicationNameParam,
        employeeName: employeeNameParam,
      }),
      callback: startRevokeUserGroup.bind(null, recertificationSessionEntityId, recertificationSessionGroupIdForRevoke),
    });
  };

  return (
    <div className="table-responsive">
      {recertificationSessionGroups.length ? (
        <Table responsive>
          <thead>
            <tr>
              <th>
                <Translate contentKey="iamdentityApp.recertificationSessionUserGroup.application">Application</Translate>
              </th>
              <th>
                <Translate contentKey="iamdentityApp.recertificationSessionUserGroup.roleGroup">Role Group</Translate>
              </th>
              <th>
                <Translate contentKey="iamdentityApp.recertificationSessionUserGroup.status">Status</Translate>
              </th>
              <th>
                <Translate contentKey="iamdentityApp.recertificationSessionUserGroup.grantedBy">Granted By</Translate>
              </th>
              <th>
                <Translate contentKey="iamdentityApp.recertificationSessionUserGroup.validFrom">Valid From</Translate>
              </th>
              <th>
                <Translate contentKey="iamdentityApp.recertificationSessionUserGroup.validTo">Valid To</Translate>
              </th>
              <th>
                <Translate contentKey="iamdentityApp.recertificationSessionUserGroup.revoked">Revoked</Translate>
              </th>
              <th />
            </tr>
          </thead>
          <tbody>
            {recertificationSessionGroups.map((recertificationSessionGroup, i) => (
              <tr key={`group-${i}`}>
                <td>
                  {recertificationSessionGroup.userGroup.roleGroup ? (
                    <Link to={`/application/${recertificationSessionGroup.userGroup.roleGroup.application.id}`}>
                      {recertificationSessionGroup.userGroup.roleGroup.application.name}
                    </Link>
                  ) : (
                    ''
                  )}
                </td>
                <td>
                  {recertificationSessionGroup.userGroup.roleGroup ? (
                    <Link to={`/role-group/${recertificationSessionGroup.userGroup.roleGroup.id}`}>
                      {recertificationSessionGroup.userGroup.roleGroup.name}
                    </Link>
                  ) : (
                    ''
                  )}
                </td>
                <td>{recertificationSessionGroup.userGroup.status ? recertificationSessionGroup.userGroup.status : ''}</td>
                <td>{recertificationSessionGroup.userGroup.grantedBy ? recertificationSessionGroup.userGroup.grantedBy : ''}</td>
                <td>
                  {recertificationSessionGroup.userGroup.startDate ? (
                    <TextFormat
                      value={recertificationSessionGroup.userGroup.startDate}
                      type="date"
                      format={convertDateFormat(locale, 'instant')}
                    />
                  ) : (
                    ''
                  )}
                </td>
                <td>
                  {recertificationSessionGroup.userGroup.endDate ? (
                    <TextFormat
                      value={recertificationSessionGroup.userGroup.endDate}
                      type="date"
                      format={convertDateFormat(locale, 'instant')}
                    />
                  ) : (
                    ''
                  )}
                </td>
                <td>
                  {recertificationSessionGroup.revoked !== null && recertificationSessionGroup.revoked !== undefined ? (
                    recertificationSessionGroup.revoked === true ? (
                      recertificationSessionGroup.order ? (
                        <div>
                          <Translate contentKey="iamdentityApp.recertificationSessionUserGroup.revokedByOrder">Revoked by Order</Translate>
                          :&nbsp;
                          <Link to={`/orders/${recertificationSessionGroup.order.id}`}>{recertificationSessionGroup.order.id}</Link>
                          &nbsp;
                          {recertificationSessionGroup?.order?.status ? (
                            <span className="fw-bold text-white d-inline-block">({recertificationSessionGroup?.order?.status})</span>
                          ) : (
                            ''
                          )}
                        </div>
                      ) : (
                        <Translate contentKey="iamdentityApp.recertificationSessionUserGroup.revokedByOrder">Revoked by Order</Translate>
                      )
                    ) : (
                      <Translate contentKey="iamdentityApp.recertificationSessionUserGroup.confirmed">Confirmed</Translate>
                    )
                  ) : (
                    ''
                  )}
                </td>
                <td className="text-end">
                  <div className="btn-group flex-btn-group-container">
                    {!isTask ? (
                      <Button
                        tag={Link}
                        to={generateAssignedRoleGroupUrl(
                          recertificationSessionGroup.recertificationSessionUserIdentity,
                          recertificationSessionGroup.id,
                          'VIEW'
                        )}
                        className="btn-md"
                        color="info"
                        data-cy="entityDetailsButton"
                      >
                        <FontAwesomeIcon icon="eye" />
                      </Button>
                    ) : null}
                    {isTask ? (
                      <Button
                        disabled={
                          recertificationSessionGroup?.revoked === true || recertificationSessionGroup?.revoked === false ? true : false
                        }
                        onClick={() =>
                          revokeUserGroup(
                            recertificationSessionGroup?.recertificationSession?.id,
                            recertificationSessionGroup?.id,
                            recertificationSessionGroup?.userGroup?.roleGroup?.name,
                            recertificationSessionGroup?.userGroup.roleGroup.application.name,
                            recertificationSessionGroup?.employee?.code + ' - ' + recertificationSessionGroup?.employee?.fullName
                          )
                        }
                        className="btn-md"
                        color="primary"
                      >
                        <FontAwesomeIcon icon="ban" />
                        &nbsp;
                        {translate('iamdentityApp.recertificationSession.revoke')}
                      </Button>
                    ) : null}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <div className="alert alert-warning">
          <Translate contentKey="iamdentityApp.recertificationSessionUserGroup.home.notFound">
            No Recertification Session User Roles Group found
          </Translate>
        </div>
      )}
    </div>
  );
};

export default AssignedRecertificationSessionGroupsTable;
