import React from 'react';
import MenuItem from 'app/shared/layout/menus/menu-item';
import { DropdownItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Translate, translate } from 'react-jhipster';
import { getLoginUrl } from 'app/shared/util/url-utils';
import { NavDropdown } from './menu-components';
import AdminMenu from './admin';
import { LocaleMenu } from './locale';

const accountMenuItemsAuthenticated = props => (
  <>
    <DropdownItem className="d-inline-block submenu dropdown-custom" toggle={false} tag="span">
      {props.isAuthenticated && props.isAdmin && <AdminMenu showOpenAPI={props.isOpenAPIEnabled} />}
    </DropdownItem>
    <DropdownItem className="d-inline-block submenu dropdown-custom" toggle={false} tag="span">
      <LocaleMenu currentLocale={props.currentLocale} onClick={props.handleLocaleChange} />
    </DropdownItem>
    <MenuItem icon="user" to="/my-profile" data-cy="my-profile">
      <Translate contentKey="global.menu.myProfile">My Profile</Translate>
    </MenuItem>
    <MenuItem icon="sign-out-alt" to="/logout" data-cy="logout">
      <Translate contentKey="global.menu.account.logout">Sign out</Translate>
    </MenuItem>
  </>
);

const accountMenuItems = () => (
  <>
    <DropdownItem id="login-item" tag="a" href={getLoginUrl()} data-cy="login">
      <FontAwesomeIcon icon="sign-in-alt" /> <Translate contentKey="global.menu.account.login">Sign in</Translate>
    </DropdownItem>
  </>
);

export const AccountMenu = ({ isAuthenticated = false, isAdmin = false, isOpenAPIEnabled, currentLocale, handleLocaleChange }) => (
  <NavDropdown icon="user" name={translate('global.menu.account.main')} id="account-menu" data-cy="accountMenu" className="right-0">
    {isAuthenticated
      ? accountMenuItemsAuthenticated({ isAuthenticated, isAdmin, isOpenAPIEnabled, currentLocale, handleLocaleChange })
      : accountMenuItems()}
  </NavDropdown>
);

export default AccountMenu;
