import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useMemo, useState } from 'react';
import { Translate, translate, ValidatedField } from 'react-jhipster';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Button, Card, Col, Form, FormGroup, Label, Row, Spinner } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { APP_GLOBAL_DATE_FORMAT } from 'app/config/constants';
import { apiUrl as connectorCommandsUrl } from 'app/entities/connector-command/connector-command.reducer';
import { apiUrl as customAttributeDefinitionsUrl } from 'app/entities/custom-attribute-definition/custom-attribute-definition.reducer';
import { ValueType } from 'app/shared/components/expression-builder/types';
import UncontrolledExpressionBuilder from 'app/shared/components/expression-builder/uncontrolled-expression-builder';
import PaginatedReactSelect from 'app/shared/components/react-select/paginated-react-select';
import ValidatedInput from 'app/shared/components/validated-input';
import { IConnectorCommandAttribute, ENTITY_NAME } from 'app/shared/model/connector-command-attribute.model';
import { ConnectorCommandAttributeDirection } from 'app/shared/model/enumerations/connector-command-attribute-direction.model';
import { ConnectorCommandAttributeType } from 'app/shared/model/enumerations/connector-command-attribute-type.model';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { createEntity, getEntity, resetEntity, updateEntity } from './connector-command-attribute.reducer';
import { buildSearchParamsObject } from 'app/shared/util/url-utils';
import { Controller } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import { convertDateFormat } from 'app/shared/util/date-utils';
import ro from 'date-fns/locale/ro';

const attributeTypeToValueType = (dataType: ConnectorCommandAttributeType) => {
  switch (dataType) {
    case ConnectorCommandAttributeType.NUMBER:
    case ConnectorCommandAttributeType.INTEGER:
    case ConnectorCommandAttributeType.LONG:
      return ValueType.NUMBER;
    case ConnectorCommandAttributeType.DATE:
    case ConnectorCommandAttributeType.DATETIME:
      return ValueType.DATE;
    case ConnectorCommandAttributeType.STRING:
      return ValueType.STRING;
    case ConnectorCommandAttributeType.BOOLEAN:
      return ValueType.BOOLEAN;
    default:
      return ValueType.STRING;
  }
};

export const ConnectorCommandAttributeUpdate = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const [searchParamsObject, setSearchParamsObject] = useState(buildSearchParamsObject(searchParams));

  const { connectorCommandAtributeUpdateId: id } = useParams<'connectorCommandAtributeUpdateId'>();
  const isNew = id === undefined;

  const connectorCommandAttributeEntity: IConnectorCommandAttribute = useAppSelector(state => state.connectorCommandAttribute.entity);
  const loading: boolean = useAppSelector(state => state.connectorCommandAttribute.loading);
  const updating: boolean = useAppSelector(state => state.connectorCommandAttribute.updating);
  const updateSuccess: boolean = useAppSelector(state => state.connectorCommandAttribute.updateSuccess);
  const connectorCommandAttributeTypeValues = Object.values(ConnectorCommandAttributeType);
  const connectorCommandAttributeDirectionValues = Object.values(ConnectorCommandAttributeDirection);

  const handleClose = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(resetEntity());
    } else {
      dispatch(getEntity(id));
    }
  }, []);

  useEffect(() => {
    formReset(defaultValues());
  }, [connectorCommandAttributeEntity]);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
      dispatch(resetEntity());
    }
  }, [updateSuccess]);

  const locale = useAppSelector(state => state.locale.currentLocale);

  const saveEntity = values => {
    const entity = {
      ...connectorCommandAttributeEntity,
      ...values,
      validFrom: values.validFrom ? moment(values.validFrom).format('YYYY-MM-DD') : null, // Format validFrom for server
      validTo: values.validTo ? moment(values.validTo).format('YYYY-MM-DD') : null, // Format validTo for server
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () => {
    const result = isNew
      ? {
          name: '',
          dataType: ConnectorCommandAttributeType.STRING,
          ...searchParamsObject,
          validFrom: moment(new Date(), convertDateFormat(locale, 'date')).toDate(),
          validTo: null,
        }
      : {
          ...connectorCommandAttributeEntity,
        };

    return result;
  };

  const {
    register,
    handleSubmit,
    control,
    reset: formReset,
    watch,
  } = useForm({
    defaultValues: defaultValues(),
    mode: 'onChange',
    shouldUnregister: true,
  });

  const watchDataType = watch('dataType');
  const watchValidFrom = watch('validFrom');
  const watchValidTo = watch('validTo');

  const calculatedExpressionRestriction = useMemo<ValueType>(() => attributeTypeToValueType(watchDataType), [watchDataType]);

  return (
    <Card className="jh-card p-3">
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="iamdentityApp.connectorCommandAttribute.home.createOrEditLabel" data-cy="ConnectorCommandAttributeCreateUpdateHeading">
            <Translate contentKey="iamdentityApp.connectorCommandAttribute.home.createOrEditLabel">
              Create or edit a ConnectorCommandAttribute
            </Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading || updating ? (
            <div className="entity-spinner-container">
              <Spinner color="primary">Loading...</Spinner>
            </div>
          ) : (
            <Form onSubmit={handleSubmit(saveEntity)}>
              {!isNew ? (
                <ValidatedInput control={control} name="id" isDisabled label={translate('global.field.id')} validate={{ required: true }} />
              ) : null}
              <ValidatedInput
                control={control}
                label={translate('iamdentityApp.connectorCommandAttribute.name')}
                name="name"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.connectorCommandAttribute.direction')}
                id="connector-command-attribute-direction"
                name="direction"
                data-cy="direction"
                type="select"
              >
                {connectorCommandAttributeDirectionValues.map(connectorCommandAttributeDirection => (
                  <option value={connectorCommandAttributeDirection} key={connectorCommandAttributeDirection}>
                    {translate('iamdentityApp.ConnectorCommandAttributeDirection.' + connectorCommandAttributeDirection)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.connectorCommandAttribute.description')}
                id="connector-command-attribute-description"
                name="description"
                data-cy="description"
                type="text"
              />
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.connectorCommandAttribute.dataType')}
                id="connector-command-attribute-dataType"
                name="dataType"
                data-cy="dataType"
                type="select"
              >
                {connectorCommandAttributeTypeValues.map(connectorCommandAttributeType => (
                  <option value={connectorCommandAttributeType} key={connectorCommandAttributeType}>
                    {translate('iamdentityApp.ConnectorCommandAttributeType.' + connectorCommandAttributeType)}
                  </option>
                ))}
              </ValidatedField>
              <FormGroup key={`attributeValueExpression-${id}`}>
                <Label>
                  <Translate contentKey="iamdentityApp.connectorCommandAttribute.attributeValueExpression" />
                </Label>
                <UncontrolledExpressionBuilder
                  entityKey={ENTITY_NAME}
                  control={control}
                  name="attributeValueExpression"
                  restrictedValueType={calculatedExpressionRestriction}
                />
              </FormGroup>
              <div className="date-picker-container mb-3">
                <Controller
                  control={control}
                  name="validFrom"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <div className="form-group">
                      <label htmlFor="connector-command-attribute-validFrom" className="form-label">
                        {translate('iamdentityApp.connectorCommandAttribute.validFrom')}
                      </label>
                      <DatePicker
                        maxDate={watchValidTo ? moment(watchValidTo, 'YYYY-MM-DD').toDate() : null}
                        selected={value ? new Date(value) : new Date()}
                        onChange={date => {
                          onChange(date ? moment(date, convertDateFormat(locale, 'date')).toDate() : null);
                        }}
                        value={value ? moment(value).format(convertDateFormat(locale, 'date')) : convertDateFormat(locale, 'date')}
                        onBlur={onBlur}
                        locale={locale === 'ro' ? ro : 'en'}
                        todayButton={<Translate contentKey="global.today">Today</Translate>}
                        showMonthDropdown
                        showYearDropdown
                        className="form-control"
                        ref={ref}
                        id="connector-command-attribute-validFrom"
                        data-cy="validFrom"
                      />
                    </div>
                  )}
                />
              </div>
              <div className="date-picker-container mb-3">
                <Controller
                  control={control}
                  name="validTo"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <div className="form-group">
                      <label htmlFor="connector-command-attribute-validTo" className="form-label">
                        {translate('iamdentityApp.connectorCommandAttribute.validTo')}
                      </label>
                      <div className="position-relative">
                        <DatePicker
                          minDate={watchValidFrom ? moment(watchValidFrom, 'YYYY-MM-DD').toDate() : null}
                          selected={value ? new Date(value) : new Date()}
                          onChange={date => {
                            onChange(date ? moment(date, convertDateFormat(locale, 'date')).toDate() : null);
                          }}
                          value={value ? moment(value).format(convertDateFormat(locale, 'date')) : convertDateFormat(locale, 'date')}
                          onBlur={onBlur}
                          locale={locale === 'ro' ? ro : 'en'}
                          todayButton={<Translate contentKey="global.today">Today</Translate>}
                          showMonthDropdown
                          showYearDropdown
                          className="form-control"
                          ref={ref}
                          id="connector-command-attribute-validTo"
                          data-cy="validTo"
                        />
                        <span
                          onClick={() => {
                            onChange(null);
                          }}
                          className="fw-semibold close-icon-custom"
                        >
                          {value !== null && value !== convertDateFormat(locale, 'date') ? 'X' : ''}
                        </span>
                      </div>
                    </div>
                  )}
                />
              </div>
              <FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.connectorCommandAttribute.connectorCommand" />
                </Label>
                <PaginatedReactSelect
                  isDisabled={!!searchParamsObject['connectorCommand']}
                  control={control}
                  name="connectorCommand"
                  url={connectorCommandsUrl}
                  queryProps={{
                    name: 'description',
                    secondName: 'code',
                    type: 'string',
                    separeteByBracket: true,
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.connectorCommandAttribute.attributeDefinition" />
                </Label>
                <PaginatedReactSelect
                  control={control}
                  name="attributeDefinition"
                  url={customAttributeDefinitionsUrl}
                  queryProps={{
                    name: 'displayName',
                    type: 'string',
                  }}
                />
              </FormGroup>
              <Button id="cancel-save" data-cy="entityCreateCancelButton" onClick={() => navigate(-1)} color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </Form>
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default ConnectorCommandAttributeUpdate;
