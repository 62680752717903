import { useAppDispatch, useAppSelector } from 'app/config/store';
import React, { useEffect, useState } from 'react';
import { TextFormat, Translate, getSortState, translate } from 'react-jhipster';
import { Button, Card, Table, UncontrolledTooltip } from 'reactstrap';
import { getEntities, searchEntities } from 'app/entities/orders/orders.reducer';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getQueryParamsIfExists } from 'app/shared/util/filter-utils';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { IOrder } from 'app/shared/model/order.model';
import { convertDateFormat } from 'app/shared/util/date-utils';

interface IRecentOrders {
  id: number;
}

export const RecentOrders = ({ id }: IRecentOrders) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const ordersList: IOrder[] = useAppSelector(state => state.orders.entities);
  const loading = useAppSelector(state => state.orders.loading);
  const locale = useAppSelector(state => state.locale.currentLocale);
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, 5, 'id', 'desc'), location.search)
  );

  const [search, setSearch] = useState('');
  const [query, setQuery] = useState<string>(getQueryParamsIfExists(location.search));
  useEffect(() => {
    dispatch(getEntities({ page: 0, size: 5, query: `createdById.equals=${id}`, sort: 'id,desc' }));
  }, [id]);
  const customQuery = query ? query + `&createdById.equals=${id}` : `createdById.equals=${id}`;

  const getAllEntities = () => {
    if (search) {
      dispatch(
        searchEntities({
          page: 0,
          size: 5,
          sort: `${paginationState.sort},${paginationState.order}`,
          query: customQuery,
        })
      );
    } else {
      dispatch(
        getEntities({
          page: 0,
          size: 5,
          sort: `${paginationState.sort},${paginationState.order}`,
          query: customQuery,
        })
      );
    }
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}&query=${query}`;
    if (location.search !== endURL) {
      navigate(`${location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort, search, query]);

  return (
    <Card className="jh-card mt-1">
      <h2 id="orders-heading" data-cy="OrdersHeading">
        <Translate contentKey="iamdentityApp.orders.home.recentOrders">Orders</Translate>
      </h2>
      <div className="table-responsive">
        <Table responsive>
          <thead>
            <tr>
              <th>
                <Translate contentKey="iamdentityApp.orders.id">ID</Translate>
              </th>
              <th>
                <Translate contentKey="iamdentityApp.orders.employee">Employee</Translate>
              </th>
              <th>
                <Translate contentKey="iamdentityApp.orders.orderType">Order Type</Translate>
              </th>
              <th>
                <Translate contentKey="iamdentityApp.orders.status">Status</Translate>
              </th>
              <th>
                <Translate contentKey="iamdentityApp.orders.scheduleTime">Schedule Time</Translate>
              </th>
              <th>
                <Translate contentKey="iamdentityApp.orders.executionStartTime">Execution Start Time</Translate>
              </th>
              <th>
                <Translate contentKey="iamdentityApp.orders.executionActualDuration">Execution Actual Duration</Translate>
              </th>
            </tr>
          </thead>
          <tbody>
            {ordersList.map((orders, i) => (
              <tr key={`entity-${i}`} data-cy="entityTable">
                <td>
                  <Button tag={Link} to={`/orders/${orders.id}`} color="link" size="sm">
                    {orders.id}
                  </Button>
                </td>
                <td>
                  {orders.employee ? (
                    <Link to={`/employee/${orders.employee.id}`}>
                      {orders.employee.code} - {orders.employee.fullName}
                    </Link>
                  ) : (
                    ''
                  )}
                </td>
                <td>{orders.orderType ? <Link to={`/order-type/${orders.orderType.id}`}>{orders.orderType.name}</Link> : ''}</td>
                <td>{orders.status ? translate(`iamdentityApp.OrderStatus.${orders.status}`) : null}</td>
                <td>
                  {orders.scheduleTime ? (
                    <TextFormat type="date" value={orders.scheduleTime} format={convertDateFormat(locale, 'instant')} />
                  ) : null}
                </td>
                <td>
                  {orders.executionStartTime ? (
                    <TextFormat type="date" value={orders.executionStartTime} format={convertDateFormat(locale, 'instant')} />
                  ) : null}
                </td>
                <td>{orders.executionActualDuration}</td>
                <td className="text-end">
                  <div className="btn-group flex-btn-group-container">
                    <Button tag={Link} to={`/orders/${orders.id}`} color="info" size="sm" data-cy="entityDetailsButton">
                      <FontAwesomeIcon icon="eye" />{' '}
                      <span className="d-none d-md-inline">
                        <Translate contentKey="entity.action.view">View</Translate>
                      </span>
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {(!ordersList || ordersList.length < 1) && !loading && (
          <div className="alert alert-warning mt-1">
            <Translate contentKey="iamdentityApp.orders.home.notFound">No Orders found</Translate>
          </div>
        )}
      </div>
    </Card>
  );
};

export default RecentOrders;
