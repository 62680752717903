import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef } from 'react';
import { Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, Row, Spinner } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntitiesByObjectType as getCustomPropertiesByObjectType } from 'app/entities/custom-attribute-definition/custom-attribute-definition.reducer';
import { CustomAttributeDtoKey } from 'app/shared/model/custom-attribute-definition.model';
import { CustomAttributeApplicableObjectType } from 'app/shared/model/enumerations/custom-attribute-applicable-object-type.model';
import { Gender } from 'app/shared/model/enumerations/gender.model';
import { renderCustomAttributeField } from 'app/shared/util/component-utils';
import { addCustomPropertiesToCustomAttributesMap, extractCustomAttributesAsEntityProperties } from 'app/shared/util/entity-utils';
import { createEntity, getEntity, reset, updateEntity } from './person.reducer';
import { Nationality } from 'app/shared/model/enumerations/nationality.model';
import { is_valid_cnp } from 'app/shared/util/cnp';
import { is_valid_email } from 'app/shared/util/email';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { is_valid_age } from 'app/shared/util/ageValidation';

export const PersonUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const personEntity = useAppSelector(state => state.person.entity);
  const loading = useAppSelector(state => state.person.loading);
  const updating = useAppSelector(state => state.person.updating);
  const updateSuccess = useAppSelector(state => state.person.updateSuccess);
  const customAttributeDefinitions: CustomAttributeDtoKey[] = useAppSelector(state => state.customAttributeDefinition.entities);
  const genderValues = Object.keys(Gender);
  const nationalityValues = Object.keys(Nationality);
  const nationality = useRef('');

  const handleClose = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
    dispatch(getCustomPropertiesByObjectType(CustomAttributeApplicableObjectType.PERSON));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = addCustomPropertiesToCustomAttributesMap(personEntity, values, customAttributeDefinitions);

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () => {
    const result = isNew
      ? {}
      : {
          gender: 'MALE',
          nationality: 'RO',
          ...extractCustomAttributesAsEntityProperties(customAttributeDefinitions, personEntity),
        };
    nationality.current = result?.nationality;
    return result;
  };

  return (
    <Card className="jh-card">
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="iamdentityApp.person.home.createOrEditLabel" data-cy="PersonCreateUpdateHeading">
            <Translate contentKey="iamdentityApp.person.home.createOrEditLabel">Create or edit a Person</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading || updating ? (
            <div className="entity-spinner-container">
              <Spinner color="primary">Loading...</Spinner>
            </div>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="person-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField label={translate('iamdentityApp.person.name')} id="person-name" name="name" data-cy="name" type="text" />
              <ValidatedField
                label={translate('iamdentityApp.person.firstName')}
                id="person-firstName"
                name="firstName"
                readOnly
                data-cy="firstName"
                type="text"
              />
              <ValidatedField
                label={translate('iamdentityApp.person.nationalIdentifier')}
                id="person-nationalIdentifier"
                name="nationalIdentifier"
                readOnly
                data-cy="nationalIdentifier"
                type="text"
                validate={{
                  validate: value => {
                    if (value && nationality.current && nationality.current == 'RO') {
                      return is_valid_cnp(value) ? true : translate('entity.validation.nationalIdentifierNotValid');
                    }
                  },
                }}
              />
              <ValidatedField
                label={translate('iamdentityApp.person.gender')}
                id="person-gender"
                name="gender"
                readOnly
                data-cy="gender"
                type="select"
              >
                {genderValues.map(gender => (
                  <option value={gender} key={gender}>
                    {translate('iamdentityApp.Gender.' + gender)}
                  </option>
                ))}
              </ValidatedField>
              {/* //validate phone number per country or region? */}
              <ValidatedField
                label={translate('iamdentityApp.person.phoneNumber')}
                id="person-phoneNumber"
                name="phoneNumber"
                readOnly
                data-cy="phoneNumber"
                type="text"
              />
              <ValidatedField
                label={translate('iamdentityApp.person.communicationEmail')}
                id="person-communicationEmail"
                name="communicationEmail"
                readOnly
                data-cy="communicationEmail"
                type="text"
                validate={{
                  validate: value => {
                    return is_valid_email(value) ? true : translate('entity.validation.emailNotValid');
                  },
                }}
              />
              <ValidatedField
                label={translate('iamdentityApp.person.dateOfBirth')}
                id="person-dateOfBirth"
                name="dateOfBirth"
                readOnly
                data-cy="dateOfBirth"
                type="date"
                validate={{
                  validate: value => {
                    return is_valid_age(value) ? true : translate('entity.validation.ageNotValid');
                  },
                }}
              />
              <ValidatedField
                label={translate('iamdentityApp.person.nationality')}
                id="person-nationality"
                name="nationality"
                readOnly
                data-cy="nationality"
                type="select"
                onChange={event => (nationality.current = event.target.value)}
              >
                {nationalityValues.map(nationality => (
                  <option value={nationality} key={nationality}>
                    {translate('iamdentityApp.Nationality.' + nationality)}
                  </option>
                ))}
              </ValidatedField>
              {customAttributeDefinitions?.map(cad =>
                renderCustomAttributeField({
                  options: cad.selectableOptions,
                  id: `person-${cad.displayName}`,
                  name: cad.displayName,
                  label: cad.displayName,
                  'data-cy': cad.displayName,
                  attributeType: cad.customAttributeType,
                })
              )}
              <div className="mt-1">
                <Button id="cancel-save" data-cy="entityCreateCancelButton" onClick={() => navigate(-1)} color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </div>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default PersonUpdate;
