import { apiUrl as profileUserIdentitiesUrl } from 'app/entities/profile-user-identity/profile-user-identity.reducer';
import { apiUrl as roleDefinitionsUrl } from 'app/entities/role-definition/role-definition.reducer';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntitiesByObjectType as getCustomPropertiesByObjectType } from 'app/entities/custom-attribute-definition/custom-attribute-definition.reducer';
import ExpressionBuilder from 'app/shared/components/expression-builder/expression-builder';
import PaginatedReactSelect from 'app/shared/components/react-select/paginated-react-select';
import { CustomAttributeDtoKey } from 'app/shared/model/custom-attribute-definition.model';
import { CustomAttributeApplicableObjectType } from 'app/shared/model/enumerations/custom-attribute-applicable-object-type.model';
import { renderCustomAttributeField } from 'app/shared/util/component-utils';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import {
  addCustomPropertiesToCustomAttributesMap,
  convertReactSelectValuesToEntityIds,
  extractCustomAttributesAsEntityProperties,
} from 'app/shared/util/entity-utils';
import { buildSearchParamsObject } from 'app/shared/util/url-utils';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Button, Card, Col, FormGroup, Label, Row, Spinner } from 'reactstrap';
import { createEntity, getEntity, resetEntity, updateEntity } from './profile-user-identity-role.reducer';
import { ENTITY_NAME } from 'app/shared/model/profile-user-identity-role.model';
import { ValueType } from 'app/shared/components/expression-builder/types';
import { Controller } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import { convertDateFormat } from 'app/shared/util/date-utils';
import ro from 'date-fns/locale/ro';
import moment from 'moment';
import { renderToString } from 'react-dom/server';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface ISearchParamsObject {
  profileUserIdentity?: { id: number };
  applicationId?: number;
}

export const ProfileUserIdentityRoleUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const [searchParamsObject, setSearchParamsObject] = useState<ISearchParamsObject>(buildSearchParamsObject(searchParams));
  const applicationId = searchParamsObject?.applicationId;
  const profileUserIdentityId = searchParamsObject?.profileUserIdentity?.id;

  const { profileUserIdentityRoleId: id } = useParams<'profileUserIdentityRoleId'>();
  const isNew = id === undefined;

  const profileUserIdentityRoleEntity = useAppSelector(state => state.profileUserIdentityRole.entity);
  const loading = useAppSelector(state => state.profileUserIdentityRole.loading);
  const updating = useAppSelector(state => state.profileUserIdentityRole.updating);
  const updateSuccess = useAppSelector(state => state.profileUserIdentityRole.updateSuccess);
  const customAttributeDefinitions: CustomAttributeDtoKey[] = useAppSelector(state => state.customAttributeDefinition.entities);
  const startDate = useRef('');

  const handleClose = () => {
    navigate(-1);
  };
  const [descriptionExpression, setDescriptionExpression] = useState(null);
  const [roleDefinitionExpression, setRoleDefinitionExpression] = useState(null);

  useEffect(() => {
    if (isNew) {
      dispatch(resetEntity());
    } else {
      dispatch(getEntity(id));
    }
    dispatch(getCustomPropertiesByObjectType(CustomAttributeApplicableObjectType.PROFILE_USER_IDENTITY_ROLE));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
      dispatch(resetEntity());
    }
  }, [updateSuccess]);

  useEffect(() => {
    setDescriptionExpression(profileUserIdentityRoleEntity.descriptionExpression);
    setRoleDefinitionExpression(profileUserIdentityRoleEntity.roleDefinitionExpression);
    formReset(defaultValues());
  }, [profileUserIdentityRoleEntity]);

  const locale = useAppSelector(state => state.locale.currentLocale);
  const [errorMessage] = useState('');

  const saveEntity = values => {
    const start = moment(values.startDate, convertDateFormat(locale, 'instant'));
    const end = moment(values.endDate, convertDateFormat(locale, 'instant'));

    if (start.isAfter(end)) {
      toast.error(translate('iamdentityApp.profileUserIdentityRole.startDateErrorMessage'));
      return;
    } else if (end.isBefore(start)) {
      toast.error(translate('iamdentityApp.profileUserIdentityRole.endDateErrorMessage'));
      return;
    }
    values.startDate = convertDateTimeToServer(values.startDate);
    values.endDate = convertDateTimeToServer(values.endDate);

    const entity = {
      ...addCustomPropertiesToCustomAttributesMap(
        profileUserIdentityRoleEntity,
        convertReactSelectValuesToEntityIds(values),
        customAttributeDefinitions
      ),
      descriptionExpression: descriptionExpression,
      roleDefinitionExpression: roleDefinitionExpression,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () => {
    const result = isNew
      ? {
          startDate: moment(new Date(), convertDateFormat(locale, 'instant')).toDate(),
          endDate: null,
          ...searchParamsObject,
        }
      : {
          ...profileUserIdentityRoleEntity,
          ...extractCustomAttributesAsEntityProperties(customAttributeDefinitions, profileUserIdentityRoleEntity),
          startDate: convertDateTimeFromServer(profileUserIdentityRoleEntity.startDate),
          endDate: convertDateTimeFromServer(profileUserIdentityRoleEntity.endDate),
          profileUserIdentity: profileUserIdentityRoleEntity?.profileUserIdentity,
          roleDefinition: profileUserIdentityRoleEntity?.roleDefinition,
          ...searchParamsObject,
        };
    startDate.current = result?.startDate;
    return result;
  };

  const {
    register,
    handleSubmit,
    control,
    watch,
    reset: formReset,
  } = useForm({
    defaultValues: defaultValues(),
    mode: 'onChange',
  });

  const watchStartDate = watch('startDate');
  const watchEndDate = watch('endDate');

  const translateTimeElement = <Translate contentKey="global.time">Time</Translate>;
  const convertTimeElementToString = renderToString(translateTimeElement);
  const timeTranslateReplace = convertTimeElementToString.replace('<span>', '');
  const timeReplaceFinal = timeTranslateReplace.replace('</span>', '');

  return (
    <Card className="jh-card pad-1">
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="iamdentityApp.profileUserIdentityRole.home.createOrEditLabel" data-cy="ProfileUserIdentityRoleCreateUpdateHeading">
            <Translate contentKey="iamdentityApp.profileUserIdentityRole.home.createOrEditLabel">
              Create or edit a ProfileUserIdentityRole
            </Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading || updating ? (
            <div className="entity-spinner-container">
              <Spinner color="primary">Loading...</Spinner>
            </div>
          ) : (
            <ValidatedForm onSubmit={handleSubmit(saveEntity)}>
              {!isNew ? (
                <ValidatedField
                  register={register}
                  name="id"
                  required
                  readOnly
                  id="profile-user-identity-role-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.profileUserIdentityRole.profileUserIdentity" />
                </Label>
                <PaginatedReactSelect
                  control={control}
                  name="profileUserIdentity"
                  isDisabled={!!searchParamsObject?.profileUserIdentity}
                  url={profileUserIdentitiesUrl}
                  queryProps={{
                    name: 'profileDefinitionName',
                    secondName: 'period',
                    type: 'string',
                    predefinedQuery: profileUserIdentityId ? `id.equals=${profileUserIdentityId}` : null,
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  *
                  <Translate contentKey="iamdentityApp.profileUserIdentityRole.roleDefinition" />
                </Label>
                <PaginatedReactSelect
                  control={control}
                  name="roleDefinition"
                  isDisabled={!applicationId}
                  url={roleDefinitionsUrl}
                  validate={{ required: true }}
                  queryProps={{
                    name: 'name',
                    type: 'string',
                    predefinedQuery: applicationId ? `applicationId.equals=${applicationId}` : null,
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.profileUserIdentityRole.roleDefinitionExpression" />
                </Label>
                <ExpressionBuilder
                  entityKey={ENTITY_NAME}
                  initialState={roleDefinitionExpression}
                  restrictedValueType={ValueType.STRING}
                  onChange={expression => setRoleDefinitionExpression(expression)}
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.profileUserIdentityRole.descriptionExpression" />
                </Label>
                <ExpressionBuilder
                  entityKey={ENTITY_NAME}
                  initialState={descriptionExpression}
                  restrictedValueType={ValueType.STRING}
                  onChange={expression => setDescriptionExpression(expression)}
                />
              </FormGroup>
              <div className="date-picker-container date-picker-custom-content mb-3">
                <Controller
                  control={control}
                  name="startDate"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <div className="form-group">
                      <label htmlFor="profile-user-identity-role-startDate" className="form-label">
                        {translate('iamdentityApp.profileUserIdentityRole.startDate')}
                      </label>
                      <DatePicker
                        popperClassName="datepicker-calendar-popper"
                        maxDate={
                          watchEndDate
                            ? moment(watchEndDate).toDate()
                            : profileUserIdentityRoleEntity.endDate
                            ? moment(profileUserIdentityRoleEntity.endDate).toDate()
                            : null
                        }
                        portalId="root-portal"
                        selected={value ? new Date(value) : new Date()}
                        onChange={date => {
                          onChange(date);
                        }}
                        value={value ? moment(value).format(convertDateFormat(locale, 'instant')) : convertDateFormat(locale, 'instant')}
                        onBlur={onBlur}
                        locale={locale === 'ro' ? ro : 'en'}
                        todayButton={<Translate contentKey="global.today">Today</Translate>}
                        showMonthDropdown
                        showYearDropdown
                        timeInputLabel={timeReplaceFinal + ':'}
                        showTimeInput
                        className="form-control"
                        ref={ref}
                        id="profile-user-identity-role-startDate"
                        data-cy="startDate"
                      />
                    </div>
                  )}
                />
              </div>
              <div className="date-picker-container date-picker-custom-content mb-3">
                <Controller
                  control={control}
                  name="endDate"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <div className="form-group">
                      <label htmlFor="profile-user-identity-role-endDate" className="form-label">
                        {translate('iamdentityApp.profileUserIdentityRole.endDate')}
                      </label>
                      <div className="position-relative">
                        <DatePicker
                          popperClassName="datepicker-calendar-popper"
                          minDate={
                            watchStartDate
                              ? moment(watchStartDate).toDate()
                              : profileUserIdentityRoleEntity.startDate
                              ? moment(profileUserIdentityRoleEntity.startDate).toDate()
                              : null
                          }
                          portalId="root-portal"
                          selected={value ? new Date(value) : new Date()}
                          onChange={date => {
                            onChange(date);
                          }}
                          value={value ? moment(value).format(convertDateFormat(locale, 'instant')) : convertDateFormat(locale, 'instant')}
                          onBlur={onBlur}
                          locale={locale === 'ro' ? ro : 'en'}
                          todayButton={<Translate contentKey="global.today">Today</Translate>}
                          showMonthDropdown
                          showYearDropdown
                          timeInputLabel={timeReplaceFinal + ':'}
                          showTimeInput
                          className="form-control"
                          ref={ref}
                          id="profile-user-identity-role-endDate"
                          data-cy="endDate"
                        />
                        <span
                          onClick={() => {
                            onChange(null);
                          }}
                          className="fw-semibold close-icon-custom"
                        >
                          {value !== null ? 'X' : ''}
                        </span>
                      </div>
                    </div>
                  )}
                />
              </div>
              {customAttributeDefinitions?.map(cad =>
                renderCustomAttributeField({
                  register: register,
                  options: cad.selectableOptions,
                  id: `profile-user-identity-role-${cad.displayName}`,
                  name: cad.displayName,
                  label: cad.displayName,
                  'data-cy': cad.displayName,
                  attributeType: cad.customAttributeType,
                })
              )}
              <div className="mt-1">
                <Button id="cancel-save" data-cy="entityCreateCancelButton" onClick={() => navigate(-1)} color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </div>
            </ValidatedForm>
          )}
        </Col>
      </Row>
      {errorMessage && <ToastContainer />}
    </Card>
  );
};

export default ProfileUserIdentityRoleUpdate;
