/* eslint-disable react/no-children-prop */
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { BPMType } from 'app/shared/model/enumerations/bpm-type.model';
import { IOrderTask, IRecertificationSessionTask, IToaTask } from 'app/shared/model/task.model';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { translate, Translate, ValidatedField } from 'react-jhipster';
import { useNavigate } from 'react-router-dom';
import { Button, Card, FormGroup, Input, InputGroup, InputGroupText, Spinner } from 'reactstrap';
import EmployeeCard from './components/employee-card';
import OrderCard from './components/order-card';
import OrderTypeCard from './components/orderType-card';
import TaskCard from './components/task-card';
import './style.scss';
import { getEntities, refreshCompleted } from './tasks.reducer';
import TaskDetailsCard from './components/application-card';
import { sortBy, debounce } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDownWideShort, faArrowUpShortWide, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import RecertificationSessionCard from './components/recertification-session-card';
import RecertificationSessionDetailCard from './components/recertification-session-detail-card';
import RecertificationSessionTypeCard from './components/recertification-session-type-card';

enum SortBy {
  id = 'id',
  order = 'order',
}

export const MyTasks = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);
  const [tasks, setTasks] = useState<IToaTask[]>([]);
  const [search, setSearch] = useState<string>('');
  const { entities, totalItems, refresh, loading } = useAppSelector(state => state.task);
  const [sort, setSort] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<string>('id');

  useEffect(() => {
    if (entities && entities.length > 0 && !search) {
      const newTasks = [...tasks, ...entities];
      setHasMore(newTasks.length < totalItems);
      setTasks(newTasks);
    } else {
      setHasMore(false);
    }
  }, [entities, search]);

  useEffect(() => {
    if (refresh) {
      resetComponent();
    }
  }, [refresh]);

  const resetComponent = () => {
    // setHasMore(true);  //dispatch all the items after user approves a task
    setTasks([]);
    fetchData(0);
    setSearch('');
    dispatch(refreshCompleted());
  };

  const fetchData = (pg: number = null) => {
    if (search && search.length > 0) {
      setTasks(sortAndFilterBy(tasks, orderBy, sort));
    } else {
      const nextPage = pg != null ? pg : (page ? page : 0) + 1;
      dispatch(getEntities({ page: nextPage, size: ITEMS_PER_PAGE, sort: `id,asc` }));
      setPage(nextPage);
    }
  };

  const onTaskClick = taskId => {
    navigate('/my-tasks/' + taskId);
  };

  const sortAndFilterBy = (array, filter, sort) => {
    if (filter === SortBy.id && sort === 'asc') {
      return sortBy(array, function (item) {
        return item.id;
      });
    } else if (filter === SortBy.id && sort === 'desc') {
      return sortBy(array, function (item) {
        return item.id;
      }).reverse();
    } else if (filter === SortBy.order && sort === 'asc') {
      return sortBy(array, function (item) {
        return item.order.id;
      });
    } else if (filter === SortBy.order && sort === 'desc') {
      return sortBy(array, function (item) {
        return item.order.id;
      }).reverse();
    }
    return array;
  };

  const changeSort = e => {
    setOrderBy(e.target.value);
    setTasks(sortAndFilterBy(tasks, e.target.value, sort));
  };

  const changeOrderBy = val => {
    setSort(val);
    setTasks(sortAndFilterBy(tasks, orderBy, val));
  };

  const debounced = debounce(async value => {
    if (value.length > 0) {
      await dispatch(getEntities({ page: 0, size: 200, sort: 'id,asc' }));
      const results = entities.filter(item => {
        const jsonString = JSON.stringify(item).toLowerCase();
        return jsonString.includes(value.toLowerCase());
      });
      setTasks(sortAndFilterBy(results, orderBy, sort));
    } else {
      resetComponent();
    }
  }, 1000);

  const handleSearch = e => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    debounced(search);
  }, [search]);

  const showArrowSort =
    sort === 'asc' ? (
      <FontAwesomeIcon fontSize={30} cursor="pointer" icon={faArrowUpShortWide} onClick={() => changeOrderBy('desc')} />
    ) : (
      <FontAwesomeIcon fontSize={30} cursor="pointer" icon={faArrowDownWideShort} onClick={() => changeOrderBy('asc')} />
    );

  const handleSyncList = () => {
    resetComponent();
  };

  return (
    <Card className="jh-card">
      <div className="d-flex justify-content-between align-items-center flex-wrap">
        <h2 id="person-heading" data-cy="PersonHeading">
          <Translate contentKey="iamdentityApp.tasks.home.title">My Tasks</Translate>
        </h2>
        <div className="d-flex justify-content-end flex-wrap">
          <div className="d-flex align-items-center">
            <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
              <FontAwesomeIcon icon="sync" spin={loading} />{' '}
              <Translate contentKey="iamdentityApp.employee.home.refreshListLabel">Refresh List</Translate>
            </Button>
          </div>
          <FormGroup style={{ margin: 'auto 0' }}>
            <InputGroup>
              <InputGroupText>
                <FontAwesomeIcon icon={faMagnifyingGlass} />
              </InputGroupText>
              <Input
                type="text"
                value={search}
                placeholder={`${translate(`iamdentityApp.tasks.search`)}...`}
                id="search"
                onChange={handleSearch}
              />
            </InputGroup>
          </FormGroup>
          <ValidatedField
            className="ml-1"
            label={translate(`iamdentityApp.tasks.sortBy`)}
            id="sort-by"
            name="sort-by"
            data-cy="sort-by"
            type="select"
            onChange={changeSort}
          >
            {Object.values(SortBy).map(val => (
              <option value={val} key={val}>
                {translate(`iamdentityApp.tasks.${val}`)}
              </option>
            ))}
          </ValidatedField>
          <div className="d-flex align-items-center ml-1">{showArrowSort}</div>
        </div>
      </div>
      <div className="table-responsive">
        <InfiniteScroll
          className="tasks-list"
          dataLength={tasks.length}
          next={fetchData}
          hasMore={hasMore}
          scrollableTarget="app-container"
          loader={
            <div className="entity-spinner-container">
              <Spinner color="primary">Loading...</Spinner>
            </div>
          }
          endMessage={
            <p style={{ textAlign: 'center' }}>
              <b>{tasks.length ? translate('iamdentityApp.tasks.home.endMessage') : translate('iamdentityApp.tasks.home.notFound')}</b>
            </p>
          }
        >
          {tasks.map((task, index) => {
            if (!task.processDefinitionKey || task.processDefinitionKey == BPMType.Process_Order_Approval) {
              const orderTask = task as IOrderTask;
              return (
                <TaskCard
                  key={`task-${index}`}
                  onClick={taskId => onTaskClick(taskId)}
                  task={orderTask}
                  children={
                    <React.Fragment>
                      <div className="task-grid-container">
                        <div className="task-left">{orderTask?.order ? <OrderCard order={orderTask.order} /> : null}</div>
                        <div className="task-middle">
                          {orderTask?.order?.orderType ? (
                            <OrderTypeCard orderType={orderTask.order.orderType} taskType={BPMType.Process_Order_Approval} />
                          ) : null}
                        </div>
                        <div className="task-right">
                          {orderTask?.order?.employee ? <EmployeeCard employee={orderTask.order.employee} /> : null}
                        </div>
                      </div>
                    </React.Fragment>
                  }
                />
              );
            } else if (task.processDefinitionKey == BPMType.Process_TOA_Execution) {
              const toaTask = task as IToaTask;
              return (
                <TaskCard
                  key={`task-${index}`}
                  onClick={taskId => onTaskClick(taskId)}
                  task={toaTask}
                  children={
                    <div className="task-grid-container">
                      <div className="task-left">{toaTask?.order ? <OrderCard order={toaTask.order} /> : null}</div>
                      <div className="task-middle">
                        {toaTask?.order?.orderType ? (
                          <OrderTypeCard
                            orderType={toaTask.order.orderType}
                            taskType={BPMType.Process_TOA_Execution}
                            employee={toaTask.order.employee}
                          />
                        ) : null}
                      </div>
                      <div className="task-right">{<TaskDetailsCard task={toaTask} />}</div>
                    </div>
                  }
                />
              );
            } else if (task.processDefinitionKey == BPMType.Process_Recertification) {
              const rcTask = task as IRecertificationSessionTask;
              return (
                <TaskCard
                  key={`task-${index}`}
                  onClick={taskId => onTaskClick(taskId)}
                  task={rcTask}
                  children={
                    <div className="task-grid-container">
                      <div className="task-left">
                        {rcTask?.recertificationSession ? (
                          <RecertificationSessionCard recertificationSession={rcTask.recertificationSession} />
                        ) : null}
                      </div>
                      <div className="task-middle">
                        <RecertificationSessionTypeCard />
                      </div>
                      <div className="task-right">
                        {rcTask?.recertificationSession ? (
                          <RecertificationSessionDetailCard recertificationSession={rcTask?.recertificationSession} />
                        ) : null}
                      </div>
                    </div>
                  }
                />
              );
            }
          })}
        </InfiniteScroll>
      </div>
    </Card>
  );
};

export default MyTasks;
