import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './in-employee.reducer';
import { CustomAttributeDtoKey } from 'app/shared/model/custom-attribute-definition.model';
import { findCustomAttributeValueByKey } from 'app/shared/util/entity-utils';

export const InEmployeeDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const inEmployeeEntity = useAppSelector(state => state.inEmployee.entity);
  const customAttributeDefinitions: CustomAttributeDtoKey[] = useAppSelector(state => state.customAttributeDefinition.entities);

  return (
    <Row>
      <Col md="8">
        <h2 data-cy="inEmployeeDetailsHeading">
          <Translate contentKey="iamdentityApp.inEmployee.detail.title">InEmployee</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{inEmployeeEntity.id}</dd>
          <dt>
            <span id="code">
              <Translate contentKey="iamdentityApp.inEmployee.code">Code</Translate>
            </span>
          </dt>
          <dd>{inEmployeeEntity.code}</dd>
          <dt>
            <span id="contractStartDate">
              <Translate contentKey="iamdentityApp.inEmployee.contractStartDate">Contract Start Date</Translate>
            </span>
          </dt>
          <dd>
            {inEmployeeEntity.contractStartDate ? (
              <TextFormat value={inEmployeeEntity.contractStartDate} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="contractEndDate">
              <Translate contentKey="iamdentityApp.inEmployee.contractEndDate">Contract End Date</Translate>
            </span>
          </dt>
          <dd>
            {inEmployeeEntity.contractEndDate ? (
              <TextFormat value={inEmployeeEntity.contractEndDate} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="employeeStatus">
              <Translate contentKey="iamdentityApp.inEmployee.employeeStatus">Employee Status</Translate>
            </span>
          </dt>
          <dd>{inEmployeeEntity.employeeStatus}</dd>
          <dt>
            <span id="organizationUnitCode">
              <Translate contentKey="iamdentityApp.inEmployee.organizationUnitCode">Organization Unit Code</Translate>
            </span>
          </dt>
          <dd>{inEmployeeEntity.organizationUnitCode}</dd>
          <dt>
            <span id="jobDefinitionInternalCode">
              <Translate contentKey="iamdentityApp.inEmployee.jobDefinitionInternalCode">Job Definition Internal Code</Translate>
            </span>
          </dt>
          <dd>{inEmployeeEntity.jobDefinitionInternalCode}</dd>
          <dt>
            <span id="superiorEmployeeCode">
              <Translate contentKey="iamdentityApp.inEmployee.superiorEmployeeCode">Superior Employee Code</Translate>
            </span>
          </dt>
          <dd>{inEmployeeEntity.superiorEmployeeCode}</dd>
          <dt>
            <span id="locationCode">
              <Translate contentKey="iamdentityApp.inEmployee.locationCode">Location Code</Translate>
            </span>
          </dt>
          <dd>{inEmployeeEntity.locationCode}</dd>
          <dt>
            <span id="personName">
              <Translate contentKey="iamdentityApp.inEmployee.personName">Person Name</Translate>
            </span>
          </dt>
          <dd>{inEmployeeEntity.personName}</dd>
          <dt>
            <span id="personFirstName">
              <Translate contentKey="iamdentityApp.inEmployee.personFirstName">Person First Name</Translate>
            </span>
          </dt>
          <dd>{inEmployeeEntity.personFirstName}</dd>
          <dt>
            <span id="personNationalIdentifier">
              <Translate contentKey="iamdentityApp.inEmployee.personNationalIdentifier">Person National Identifier</Translate>
            </span>
          </dt>
          <dd>{inEmployeeEntity.personNationalIdentifier}</dd>
          <dt>
            <span id="personPhoneNumber">
              <Translate contentKey="iamdentityApp.inEmployee.personPhoneNumber">Person Phone Number</Translate>
            </span>
          </dt>
          <dd>{inEmployeeEntity.personPhoneNumber}</dd>
          <dt>
            <span id="personCommunicationEmail">
              <Translate contentKey="iamdentityApp.inEmployee.personCommunicationEmail">Person Communication Email</Translate>
            </span>
          </dt>
          <dd>{inEmployeeEntity.personCommunicationEmail}</dd>
          <dt>
            <span id="personDateOfBirth">
              <Translate contentKey="iamdentityApp.inEmployee.personDateOfBirth">Person Date Of Birth</Translate>
            </span>
          </dt>
          <dd>
            {inEmployeeEntity.personDateOfBirth ? (
              <TextFormat value={inEmployeeEntity.personDateOfBirth} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="personNationality">
              <Translate contentKey="iamdentityApp.inEmployee.personNationality">Person Nationality</Translate>
            </span>
          </dt>
          <dd>{inEmployeeEntity.personNationality}</dd>
          {customAttributeDefinitions?.map(cad => (
            <React.Fragment key={cad.id}>
              <dt>
                <span id={cad.displayName}>{cad.displayName}</span>
              </dt>
              <dd>{findCustomAttributeValueByKey(cad, inEmployeeEntity)}</dd>
            </React.Fragment>
          ))}
        </dl>
        <Button tag={Link} to="/in-employee" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default InEmployeeDetail;
