import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { getSortState, JhiItemCount, JhiPagination, TextFormat, Translate } from 'react-jhipster';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Card, Table, UncontrolledTooltip } from 'reactstrap';

import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';

import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import CompactExpressionBuilder from 'app/shared/components/expression-builder/compact-expression-builder';
import Filter from 'app/shared/FilterComponent';
import { IStandardPolicyRule } from 'app/shared/model/standard-policy-rule.model';
import { getQueryParamsIfExists } from 'app/shared/util/filter-utils';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { exportEntities, getEntities, reorderEntities, searchEntities } from './standard-policy-rule.reducer';
import PaginatedDropdown from 'app/shared/components/pagination-dropdown';
import { convertDateFormat } from 'app/shared/util/date-utils';
import { handleSortIconsTable } from 'app/shared/util/table-icons';

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'lightblue' : 'none',
});

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  background: isDragging ? 'lightgreen' : 'none',

  ...draggableStyle,
});

export const StandardPolicyRule = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();

  const [search, setSearch] = useState('');

  const handlePageSizeChange = size => {
    paginationState.activePage = 1;
    paginationState.itemsPerPage = size;
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        query,
      })
    );
  };

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'priority'), location.search)
  );
  const [filterShown, setFilterShown] = useState<boolean>(true);
  const [query, setQuery] = useState<string>(getQueryParamsIfExists(location.search));

  const standardPolicyRuleList: IStandardPolicyRule[] = useAppSelector(state => state.standardPolicyRule.entities);
  const loading = useAppSelector(state => state.standardPolicyRule.loading);
  const updateSuccess = useAppSelector(state => state.standardPolicyRule.updateSuccess);
  const totalItems = useAppSelector(state => state.standardPolicyRule.totalItems);
  const locale = useAppSelector(state => state.locale.currentLocale);

  const toggleFilter = () => {
    if (filterShown) setQuery('');
    setFilterShown(!filterShown);
  };

  const getAllEntities = () => {
    if (search) {
      dispatch(
        searchEntities({
          query: search,
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
        })
      );
    } else {
      dispatch(
        getEntities({
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
          query,
        })
      );
    }
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}&query=${query}`;
    if (location.search !== endURL) {
      navigate(`${location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort, search, query, updateSuccess]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  const exportTable = () => {
    dispatch(
      exportEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        query,
      })
    );
  };

  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }

    const changedEntityId = standardPolicyRuleList[result.source.index].id;
    const anchorEntityId = standardPolicyRuleList[result.destination.index].id;

    dispatch(reorderEntities({ changedEntityId, anchorEntityId }));
  };

  return (
    <Card className="jh-card no-backdrop">
      <h2 id="standard-policy-rule-heading" data-cy="StandardPolicyRuleHeading">
        <Translate contentKey="iamdentityApp.standardPolicyRule.home.title">Standard Policy Rules</Translate>
        <div className="d-flex justify-content-end">
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            <Translate contentKey="iamdentityApp.standardPolicyRule.home.refreshListLabel">Refresh List</Translate>
          </Button>
          <Button className="me-2" color="light" onClick={exportTable} disabled={loading}>
            <FontAwesomeIcon icon="file-excel" spin={loading} /> <Translate contentKey="entity.action.export">Export</Translate>
          </Button>
          <Link
            to="/standard-policy-rule/new"
            className="btn btn-primary jh-create-entity"
            id="jh-create-entity"
            data-cy="entityCreateButton"
          >
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="iamdentityApp.standardPolicyRule.home.createLabel">Create new Standard Policy Rule</Translate>
          </Link>
        </div>
      </h2>
      <div className="table-responsive table-scroll-custom">
        <Table responsive>
          <thead className="table-scroll-custom_thead">
            <tr>
              <th className="hand" onClick={sort('id')}>
                <Translate contentKey="iamdentityApp.standardPolicyRule.id">ID</Translate> {handleSortIconsTable('id')}
              </th>
              <th className="hand" onClick={sort('name')}>
                <Translate contentKey="iamdentityApp.standardPolicyRule.name">Name</Translate> {handleSortIconsTable('name')}
              </th>
              <th className="hand" onClick={sort('conditionExpression')}>
                <Translate contentKey="iamdentityApp.standardPolicyRule.conditionExpression">Condition Expression</Translate>{' '}
                {handleSortIconsTable('conditionExpression')}
              </th>
              <th className="hand" onClick={sort('priority')}>
                <Translate contentKey="iamdentityApp.standardPolicyRule.priority">Priority</Translate> {handleSortIconsTable('priority')}
              </th>
              <th className="hand" onClick={sort('validFrom')}>
                <Translate contentKey="iamdentityApp.standardPolicyRule.validFrom">Valid From</Translate>{' '}
                {handleSortIconsTable('validFrom')}
              </th>
              <th className="hand" onClick={sort('validTo')}>
                <Translate contentKey="iamdentityApp.standardPolicyRule.validTo">Valid To</Translate> {handleSortIconsTable('validTo')}
              </th>
              <th className="hand" onClick={sort('profile')}>
                <Translate contentKey="iamdentityApp.standardPolicyRule.profile">Profile</Translate> {handleSortIconsTable('profile')}
              </th>
              <th className="text-right">
                <span>
                  <Button size="sm" id="toggle-filter" color={filterShown ? 'secondary' : 'default'} onClick={toggleFilter}>
                    <FontAwesomeIcon icon="magnifying-glass" />
                  </Button>
                </span>
                <UncontrolledTooltip placement="top" target="toggle-filter">
                  <Translate contentKey={filterShown ? 'global.hideFilter' : 'global.showFilter'}>Show/Hide Filter</Translate>
                </UncontrolledTooltip>
              </th>
            </tr>
            {filterShown && (
              <Filter
                getEntities={setQuery}
                columns={[
                  {
                    name: 'id',
                    type: 'ignore',
                  },
                  {
                    name: 'name',
                    type: 'string',
                  },
                  {
                    name: 'conditionExpression',
                    type: 'string',
                  },
                  {
                    name: 'priority',
                    type: 'number',
                  },
                  {
                    name: 'validFrom',
                    type: 'date',
                  },
                  {
                    name: 'validTo',
                    type: 'date',
                  },
                  {
                    name: 'profile',
                    type: 'string',
                  },
                ]}
              />
            )}
          </thead>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <tbody {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                  {standardPolicyRuleList.map((standardPolicyRule, i) => (
                    <React.Fragment key={standardPolicyRule.id}>
                      <Draggable draggableId={String(standardPolicyRule.id)} index={i}>
                        {(provided, snapshot) => (
                          <tr
                            key={`entity-${i}`}
                            data-cy="entityTable"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                          >
                            <td>
                              <Button tag={Link} to={`/standard-policy-rule/${standardPolicyRule.id}`} color="link" size="sm">
                                {standardPolicyRule.id}
                              </Button>
                            </td>
                            <td>{standardPolicyRule.name}</td>
                            <td id={`conditionExpression-${i}`}>
                              {standardPolicyRule.conditionExpression ? (
                                <CompactExpressionBuilder tree={standardPolicyRule.conditionExpression} />
                              ) : null}
                            </td>
                            <td>{standardPolicyRule.priority}</td>
                            <td>
                              {standardPolicyRule.validFrom ? (
                                <TextFormat type="date" value={standardPolicyRule.validFrom} format={convertDateFormat(locale, 'date')} />
                              ) : null}
                            </td>
                            <td>
                              {standardPolicyRule.validTo ? (
                                <TextFormat type="date" value={standardPolicyRule.validTo} format={convertDateFormat(locale, 'date')} />
                              ) : null}
                            </td>
                            <td>
                              {standardPolicyRule.profile ? (
                                <Link to={`/profile-definition/${standardPolicyRule.profile.id}`}>{standardPolicyRule.profile.name}</Link>
                              ) : (
                                ''
                              )}
                            </td>
                            <td className="text-end">
                              <div className="btn-group flex-btn-group-container">
                                <Button
                                  tag={Link}
                                  to={`/standard-policy-rule/${standardPolicyRule.id}`}
                                  color="info"
                                  size="sm"
                                  data-cy="entityDetailsButton"
                                >
                                  <FontAwesomeIcon icon="eye" />{' '}
                                  <span className="d-none d-md-inline">
                                    <Translate contentKey="entity.action.view">View</Translate>
                                  </span>
                                </Button>
                                <Button
                                  tag={Link}
                                  to={`/standard-policy-rule/${standardPolicyRule.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                                  color="primary"
                                  size="sm"
                                  data-cy="entityEditButton"
                                >
                                  <FontAwesomeIcon icon="pencil-alt" />{' '}
                                  <span className="d-none d-md-inline">
                                    <Translate contentKey="entity.action.edit">Edit</Translate>
                                  </span>
                                </Button>
                                <Button
                                  tag={Link}
                                  to={`/standard-policy-rule/${standardPolicyRule.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                                  color="danger"
                                  size="sm"
                                  data-cy="entityDeleteButton"
                                >
                                  <FontAwesomeIcon icon="trash" />{' '}
                                  <span className="d-none d-md-inline">
                                    <Translate contentKey="entity.action.delete">Delete</Translate>
                                  </span>
                                </Button>
                              </div>
                            </td>
                          </tr>
                        )}
                      </Draggable>
                    </React.Fragment>
                  ))}
                  {provided.placeholder}
                </tbody>
              )}
            </Droppable>
          </DragDropContext>
        </Table>
        {(!standardPolicyRuleList || standardPolicyRuleList.length < 1) && !loading && (
          <div className="alert alert-warning">
            <Translate contentKey="iamdentityApp.standardPolicyRule.home.notFound">No Standard Policy Rules found</Translate>
          </div>
        )}
      </div>
      {totalItems ? (
        <div className={standardPolicyRuleList && standardPolicyRuleList.length > 0 ? '' : 'd-none'}>
          <div className="justify-content-center d-flex">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </div>
          <div className="justify-content-center d-flex">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
            <PaginatedDropdown itemsPerPage={paginationState.itemsPerPage} handlePageSizeChange={handlePageSizeChange}></PaginatedDropdown>
          </div>
        </div>
      ) : (
        ''
      )}
    </Card>
  );
};

export default StandardPolicyRule;
