import { FieldError } from 'react-hook-form';
import { ISelectQueryProps } from '../components/react-select/types';

export const generateOptionValue = (op: any, queryProps: ISelectQueryProps) => {
  if (queryProps.valueName) {
    if (queryProps.valueName.includes('.')) {
      const splitPropsName = queryProps.valueName.split('.');
      if (splitPropsName.length == 2 && op[splitPropsName[0]][splitPropsName[1]]) {
        return op[splitPropsName[0]][splitPropsName[1]];
      }
    } else {
      if (op[queryProps.valueName]) {
        return op[queryProps.name];
      }
    }
  }

  return op.id;
};

export const generateOptionLabel = (op: any, queryProps: ISelectQueryProps) => {
  let result = '';

  //name
  if (queryProps.name) {
    if (queryProps.name.includes('.')) {
      const splitPropsName = queryProps.name.split('.');
      if (splitPropsName.length == 2 && op[splitPropsName[0]][splitPropsName[1]]) {
        result += `${op[splitPropsName[0]][splitPropsName[1]]}`;
      }
    } else {
      if (op[queryProps.name]) {
        result += `${op[queryProps.name]}`;
      }
    }
  }

  //secondName
  if (queryProps.secondName) {
    if (queryProps.secondName.includes('.')) {
      const splitPropsName = queryProps.secondName.split('.');
      if (splitPropsName.length == 2 && op[splitPropsName[0]][splitPropsName[1]] && op[splitPropsName[0]][splitPropsName[1]]) {
        if (queryProps.separeteByBracket) {
          result += ` (${op[splitPropsName[0]][splitPropsName[1]]})`;
        } else {
          result += ' - ';
          result += `${op[splitPropsName[0]][splitPropsName[1]]}`;
        }
      }
    } else {
      if (queryProps.secondName && op[queryProps.secondName]) {
        if (queryProps.separeteByBracket) {
          result += ` (${op[queryProps.secondName]})`;
        } else {
          result += ' - ';
          result += `${op[queryProps.secondName]}`;
        }
      }
    }
  }
  return result;
};

export const generateSelectStyles = ({ error, isTouched, isDisabled }: { error: FieldError; isTouched: boolean; isDisabled: boolean }) => ({
  control: styles => {
    let newStyles = {
      ...styles,
      backgroundColor: 'transparent',
      borderRadius: '0.5rem',
      borderWidth: '1px',
      borderStyle: 'solid',
    };
    if (!!error) {
      newStyles = {
        ...newStyles,
        borderColor: '#fd7e14',
        backgroundImage:
          'url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23fd7e14%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23fd7e14%27 stroke=%27none%27/%3e%3c/svg%3e")',

        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right calc(2.5em + 0.375rem) center',
        backgroundSize: 'calc(0.75em + 0.75rem) calc(0.75em + 0.75rem)',
      };
    } else if (isTouched) {
      newStyles = {
        ...newStyles,
        borderColor: '#41d7a7',
        backgroundImage:
          'url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 8 8%27%3e%3cpath fill=%27%2341d7a7%27 d=%27M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z%27/%3e%3c/svg%3e")',

        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right calc(4em + 0.500rem) center',
        backgroundSize: 'calc(0.75em + 0.75rem) calc(0.75em + 0.75rem)',
      };
    } else if (isDisabled) {
      newStyles = {
        ...newStyles,
        backgroundColor: '#e9e9e8',
      };
    } else {
      newStyles = {
        ...newStyles,
        borderColor: 'rgba(255, 255, 255, 0.4)',
      };
    }

    return newStyles;
  },
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const blue = '#0066cc';
    const white = 'white';
    const black = 'black';
    return {
      ...styles,
      backgroundColor: isDisabled ? undefined : isSelected ? blue : isFocused ? blue : undefined,
      color: isDisabled ? '#ccc' : isSelected ? white : isFocused ? white : black,
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled ? (isSelected ? blue : blue) : undefined,
      },
    };
  },
  input: styles => ({ ...styles, padding: '0.75rem 4.5rem 0.75rem 1.5rem' }),
  placeholder: styles => ({ ...styles, color: 'white' }),
  singleValue: (styles, { data }) => ({ ...styles, color: isDisabled ? 'rgb(100, 100, 100)' : 'white' }),
  menuPortal: styles => ({ ...styles, zIndex: '9999' }),
});
