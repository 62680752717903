import { useAppDispatch, useAppSelector } from 'app/config/store';
import { IEmployee } from 'app/shared/model/employee.model';
import React, { useEffect } from 'react';
import { TextFormat, Translate } from 'react-jhipster';
import { Button, Card, Col, Row } from 'reactstrap';
import { IPerson } from 'app/shared/model/person.model';
import './my-profile.scss';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { convertDateFormat } from 'app/shared/util/date-utils';
import { CustomAttributeDtoKey } from 'app/shared/model/custom-attribute-definition.model';
import { findCustomAttributeValueByKey } from 'app/shared/util/entity-utils';
import { getEntitiesByObjectType as getCustomPropertiesByObjectType } from 'app/entities/custom-attribute-definition/custom-attribute-definition.reducer';
import { CustomAttributeApplicableObjectType } from 'app/shared/model/enumerations/custom-attribute-applicable-object-type.model';
import axios from 'axios';
import { useQuery } from 'react-query';
import { faShieldHalved } from '@fortawesome/free-solid-svg-icons';

interface IAccount {
  id?: string | null;
  login?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  imageUrl?: string | null;
  activated?: boolean;
  langKey?: string | null;
  createdBy?: string | null;
  createdDate?: string | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: string | null;
  employee: IEmployee;
}

export const MyProfile = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const locale = useAppSelector(state => state.locale.currentLocale);
  const urlKeyClock: string = useAppSelector(state => state.applicationProfile.keycloakUrl);
  const user: IAccount = useAppSelector(state => state.authentication.account);
  const employee: IEmployee = user.employee;
  const person: IPerson = user.employee.person;

  const customAttributeDefinitions: CustomAttributeDtoKey[] = useAppSelector(state => state.customAttributeDefinition.entities);

  const startProcessQueryPerson = async () => {
    return await axios.get(`api/custom-attribute-definitions-for-objectType/${CustomAttributeApplicableObjectType.PERSON}`);
  };

  const { isSuccess, data: customAttributePerson } = useQuery('person', startProcessQueryPerson, {
    refetchOnWindowFocus: false,
    enabled: true,
  });

  useEffect(() => {
    dispatch(getCustomPropertiesByObjectType(CustomAttributeApplicableObjectType.EMPLOYEE));
  }, []);

  return (
    <Card>
      <h2 id="my-profile" data-cy="MyProfile">
        <Translate contentKey="iamdentityApp.myProfile.title">My Profile</Translate>
      </h2>
      <div className="my-profile">
        <Card className="user-card">
          <h4>
            <Translate contentKey="iamdentityApp.myProfile.userData.title">User Data</Translate>
          </h4>
          <dl>
            <dt>
              <Translate contentKey="iamdentityApp.myProfile.userData.logIn">LogIn</Translate>
            </dt>
            <dd>{user.login}</dd>

            <dt>
              <Translate contentKey="iamdentityApp.myProfile.userData.email">Email</Translate>
            </dt>
            <dd>{user.email}</dd>

            <dt>
              <Translate contentKey="iamdentityApp.myProfile.userData.firstName">First Name</Translate>
            </dt>
            <dd>{user.firstName}</dd>

            <dt>
              <Translate contentKey="iamdentityApp.myProfile.userData.lastName">Last Name</Translate>
            </dt>
            <dd>{user.lastName}</dd>

            <dt>
              <Translate contentKey="iamdentityApp.myProfile.userData.language">Language</Translate>
            </dt>
            <dd>{user.langKey}</dd>

            <dt>
              <Translate contentKey="global.field.createdBy">Created By</Translate>
            </dt>
            <dd>{user.createdBy ? user.createdBy : ''}</dd>
            <dt>
              <Translate contentKey="global.field.createdDate">Created Date</Translate>
            </dt>
            <dd>
              {user.createdDate ? <TextFormat value={user.createdDate} type="date" format={convertDateFormat(locale, 'date')} /> : null}
            </dd>
            <dt>
              <Translate contentKey="global.field.lastModifiedBy">Last Modified By</Translate>
            </dt>
            <dd>{user.lastModifiedBy ? user.lastModifiedBy : ''}</dd>
            <dt>
              <Translate contentKey="global.field.lastModifiedDate">Last Modified Date</Translate>
            </dt>
            <dd>
              {user.lastModifiedDate ? (
                <TextFormat value={user.lastModifiedDate} type="date" format={convertDateFormat(locale, 'date')} />
              ) : null}
            </dd>
          </dl>
        </Card>
        <Card className="employee-card">
          <h4>
            <Translate contentKey="iamdentityApp.myProfile.employeeData.title">Employee Data</Translate>
          </h4>
          <dl>
            <dt>
              <Translate contentKey="iamdentityApp.employee.code">Code</Translate>
            </dt>
            <dd>{employee?.code}</dd>

            <dt>
              <Translate contentKey="iamdentityApp.employee.contractStartDate">Contract Start Date</Translate>
            </dt>
            <dd>
              {employee?.contractStartDate ? (
                <TextFormat type="date" value={employee.contractStartDate} format={convertDateFormat(locale, 'date')} />
              ) : (
                ''
              )}
            </dd>

            <dt>
              <Translate contentKey="iamdentityApp.employee.contractEndDate">Contract End Date</Translate>
            </dt>
            <dd>
              {employee?.contractEndDate ? (
                <TextFormat type="date" value={employee.contractEndDate} format={convertDateFormat(locale, 'date')} />
              ) : (
                ''
              )}
            </dd>

            <dt>
              <Translate contentKey="iamdentityApp.employee.organizationUnit">Organization Unit</Translate>
            </dt>
            <dd>
              {employee?.organizationUnit?.name ? (
                <Link to={`/organization-unit/${employee?.organizationUnit?.id}`}>{employee?.organizationUnit?.name}</Link>
              ) : (
                ''
              )}
            </dd>

            <dt>
              <Translate contentKey="iamdentityApp.employee.job">Job</Translate>
            </dt>
            <dd>{employee?.job?.name ? <Link to={`/job-definition/${employee?.job?.id}`}>{employee?.job?.name}</Link> : ''}</dd>

            <dt>
              <Translate contentKey="iamdentityApp.employee.superior">Superior</Translate>
            </dt>
            <dd>{employee?.superior ? <Link to={`/employee/${employee?.superior.id}`}>{employee?.superior?.code}</Link> : ''}</dd>

            <dt>
              <Translate contentKey="iamdentityApp.employee.location">Location</Translate>
            </dt>
            <dd>{employee?.location?.name}</dd>

            {customAttributeDefinitions?.map(cad => (
              <React.Fragment key={cad.displayName}>
                <dt>
                  <span id={cad.displayName}>{cad.displayName}</span>
                </dt>
                <dd>{findCustomAttributeValueByKey(cad, employee)}</dd>
              </React.Fragment>
            ))}

            <dt>
              <Translate contentKey="global.field.createdBy">Created By</Translate>
            </dt>
            <dd>{employee.createdBy ? employee.createdBy : ''}</dd>
            <dt>
              <Translate contentKey="global.field.createdDate">Created Date</Translate>
            </dt>
            <dd>
              {employee.createdDate ? (
                <TextFormat value={employee.createdDate} type="date" format={convertDateFormat(locale, 'date')} />
              ) : null}
            </dd>
            <dt>
              <Translate contentKey="global.field.lastModifiedBy">Last Modified By</Translate>
            </dt>
            <dd>{employee.lastModifiedBy ? employee.lastModifiedBy : ''}</dd>
            <dt>
              <Translate contentKey="global.field.lastModifiedDate">Last Modified Date</Translate>
            </dt>
            <dd>
              {employee.lastModifiedDate ? (
                <TextFormat value={employee.lastModifiedDate} type="date" format={convertDateFormat(locale, 'date')} />
              ) : null}
            </dd>
          </dl>
        </Card>
        <Card className="person-card">
          <h4>
            <Translate contentKey="iamdentityApp.myProfile.personData.title">Person Data</Translate>
          </h4>
          <dl>
            <dt>
              <Translate contentKey="iamdentityApp.person.name">Name</Translate>
            </dt>
            <dd>{person?.name}</dd>

            <dt>
              <Translate contentKey="iamdentityApp.person.firstName">First Name</Translate>
            </dt>
            <dd>{person?.firstName}</dd>

            <dt>
              <Translate contentKey="iamdentityApp.person.nationalIdentifier">National Identifier</Translate>
            </dt>
            <dd>{person?.nationalIdentifier}</dd>

            <dt>
              <Translate contentKey="iamdentityApp.person.communicationEmail">Communication Email</Translate>
            </dt>
            <dd>{person?.communicationEmail}</dd>

            <dt>
              <Translate contentKey="iamdentityApp.person.phoneNumber">Phone Number</Translate>
            </dt>
            <dd>{person?.phoneNumber}</dd>

            <dt>
              <Translate contentKey="iamdentityApp.person.dateOfBirth">Date Of Birth</Translate>
            </dt>
            <dd>
              {person?.dateOfBirth ? <TextFormat type="date" value={person?.dateOfBirth} format={convertDateFormat(locale, 'date')} /> : ''}
            </dd>

            <dt>
              <Translate contentKey="iamdentityApp.person.nationality">Nationality</Translate>
            </dt>
            <dd>{person?.nationality}</dd>

            {isSuccess &&
              customAttributePerson.data?.map(cad => (
                <React.Fragment key={cad.displayName}>
                  <dt>
                    <span id={cad.displayName}>{cad.displayName}</span>
                  </dt>
                  <dd>{findCustomAttributeValueByKey(cad, person)}</dd>
                </React.Fragment>
              ))}

            <dt>
              <Translate contentKey="global.field.createdBy">Created By</Translate>
            </dt>
            <dd>{person.createdBy ? person.createdBy : ''}</dd>
            <dt>
              <Translate contentKey="global.field.createdDate">Created Date</Translate>
            </dt>
            <dd>
              {person.createdDate ? <TextFormat value={person.createdDate} type="date" format={convertDateFormat(locale, 'date')} /> : null}
            </dd>
            <dt>
              <Translate contentKey="global.field.lastModifiedBy">Last Modified By</Translate>
            </dt>
            <dd>{person.lastModifiedBy ? person.lastModifiedBy : ''}</dd>
            <dt>
              <Translate contentKey="global.field.lastModifiedDate">Last Modified Date</Translate>
            </dt>
            <dd>
              {person.lastModifiedDate ? (
                <TextFormat value={person.lastModifiedDate} type="date" format={convertDateFormat(locale, 'date')} />
              ) : null}
            </dd>
          </dl>
        </Card>
      </div>
      <div>
        <div className="btn-group flex-btn-group-container max-width">
          <Button id="cancel-save" data-cy="entityDetailsBackButton" onClick={() => navigate(-1)} color="info">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
          <a href={urlKeyClock}>
            <Button id="save" data-cy="saveButton" color="primary">
              <FontAwesomeIcon icon={faShieldHalved} />{' '}
              <span className="d-none d-md-inline">
                <Translate contentKey="iamdentityApp.myProfile.accountSecurityDetails">Account Security Details</Translate>
              </span>
            </Button>
          </a>
        </div>
      </div>
    </Card>
  );
};

export default MyProfile;
