import { IEmployee } from 'app/shared/model/employee.model';
import { OrderStatus } from 'app/shared/model/enumerations/order-status.model';
import { IOrderType } from 'app/shared/model/order-type.model';
import { IUser } from 'app/shared/model/user.model';
import { IOrderedGroup } from './ordered-group.model';
import { IOrderedRole } from './ordered-role.model';
import { IOrderedSpecificPermission } from './ordered-specific-permission.model';
import { IOrderedUserIdentity } from './ordered-user-identity.model';
import { IUserIdentity } from './user-identity.model';
import { IEmployeeDelegation } from './employee-delegation.model';
import { IUserIdentityRole } from './user-identity-role.model';
import { IUserGroup } from './user-group.model';
import { ISpecificPermission } from './specific-permission.model';
import { RiskLevel } from 'app/shared/model/enumerations/risk-level.model';

export interface IOrder {
  id?: number;
  status?: OrderStatus | null;
  statusReason?: string | null;
  temporary?: boolean | null;
  scheduleTime?: Date | null;
  executionStartTime?: string | null;
  executionEndTime?: string | null;
  executionEstimatedDuration?: number | null;
  executionActualDuration?: number | null;
  orderValidUntil?: Date | null;
  approveDate?: string | null;
  employee?: IEmployee | null;
  orderType?: IOrderType | null;
  lastApprover?: IUser | null;
  dependsOn?: IOrder | null;
  createdBy?: IUser | null;
  dependentOrder?: IOrder | null;
  orderSummary?: string;
  riskLevel?: RiskLevel | null;
}

export interface ICreateOrder {
  order: IOrder;
  orderedUserIdentity?: IOrderedUserIdentity;
  orderedRole?: IOrderedRole;
  orderedGroup?: IOrderedGroup;
  orderedSpecificPermission?: IOrderedSpecificPermission;
  userIdentity?: IUserIdentity;
  userIdentityRole?: IUserIdentityRole;
  userGroup?: IUserGroup;
  specificPermission?: ISpecificPermission;
  employeeDelegation?: IEmployeeDelegation;
  startOrder?: boolean | null;
  allApps?: boolean | null;
  allAppsPassword?: string | null;
}

export const defaultValue: Readonly<IOrder> = {
  temporary: false,
};
