import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CompactExpressionBuilder from 'app/shared/components/expression-builder/compact-expression-builder';
import { IProfileUserGroup } from 'app/shared/model/profile-user-group.model';
import React from 'react';
import { Translate } from 'react-jhipster';
import { Link } from 'react-router-dom';
import { Button, Table } from 'reactstrap';
import { generateAssignedRoleGroupUrl } from './profile-users';

interface IAssignedProfileRoleGroupsTablesProps {
  profileRoleGroups: IProfileUserGroup[];
}

const AssignedProfileRoleGroupsTable = ({ profileRoleGroups }: IAssignedProfileRoleGroupsTablesProps) => {
  return (
    <div className="table-responsive">
      {profileRoleGroups.length ? (
        <Table responsive>
          <thead>
            <tr>
              <th>
                <Translate contentKey="iamdentityApp.profileUserGroup.roleGroup">Role Group</Translate>
              </th>
              <th>
                <Translate contentKey="iamdentityApp.profileUserGroup.roleGroupExpression">Role Group Expression</Translate>
              </th>
              <th>
                <Translate contentKey="iamdentityApp.profileUserGroup.descriptionExpression">Description Expression</Translate>
              </th>
              <th />
            </tr>
          </thead>
          <tbody>
            {profileRoleGroups.map((profileRoleGroup, i) => (
              <tr key={`role-group-${i}`}>
                <td>{profileRoleGroup.roleGroup ? profileRoleGroup.roleGroup.name : ''}</td>
                <td>
                  <CompactExpressionBuilder
                    tree={profileRoleGroup.roleGroupExpression}
                    expressionId={`role-group-${profileRoleGroup?.id || ''}`}
                  />
                </td>
                <td>
                  <CompactExpressionBuilder
                    tree={profileRoleGroup.descriptionExpression}
                    expressionId={`role-group-description--${profileRoleGroup?.id || ''}`}
                  />
                </td>
                <td className="text-end">
                  <div className="btn-group flex-btn-group-container">
                    <Button
                      tag={Link}
                      to={generateAssignedRoleGroupUrl(profileRoleGroup.profileUserIdentity, profileRoleGroup.id, 'VIEW')}
                      className="btn-md"
                      color="info"
                      data-cy="entityDetailsButton"
                    >
                      <FontAwesomeIcon icon="eye" />
                    </Button>
                    <Button
                      tag={Link}
                      to={generateAssignedRoleGroupUrl(profileRoleGroup.profileUserIdentity, profileRoleGroup.id)}
                      className="btn-md"
                      color="primary"
                      data-cy="entityEditButton"
                    >
                      <FontAwesomeIcon icon="pencil-alt" />
                    </Button>
                    <Button
                      className="btn-md"
                      tag={Link}
                      to={generateAssignedRoleGroupUrl(profileRoleGroup.profileUserIdentity, profileRoleGroup.id, 'DELETE')}
                      color="danger"
                      data-cy="entityDeleteButton"
                    >
                      <FontAwesomeIcon icon="trash" />
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <div className="alert alert-warning">
          <Translate contentKey="iamdentityApp.profileUserGroup.home.notFound">No Profile User Groups found</Translate>
        </div>
      )}
    </div>
  );
};

export default AssignedProfileRoleGroupsTable;
