import { faPen, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APP_DATETIME_SHORT_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { AuditRevType, IAudit, IAuditEntry } from 'app/shared/model/audit.model';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Translate } from 'react-jhipster';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Table } from 'reactstrap';
import { getRevisionsById } from './audit.reducer';

interface ITimelineElementProps {
  change: IAuditEntry;
}

const TimelineElement = ({ change }: ITimelineElementProps) => {
  const isInsert = change.revType == AuditRevType.ADD;

  return (
    <VerticalTimelineElement
      className="vertical-timeline-element--work"
      contentStyle={{ background: 'rgb(51, 179, 221)', color: '#fff' }}
      contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
      date={moment(change.modifiedAt).format(APP_DATETIME_SHORT_FORMAT)}
      iconStyle={{ background: 'rgb(232, 50, 131)', color: '#fff' }}
      icon={<FontAwesomeIcon icon={isInsert ? faPlus : faPen} />}
    >
      {/* <h3 className="vertical-timeline-element-title">{change.modifiedBy}</h3> */}
      <h4 className="vertical-timeline-element-subtitle">
        {isInsert ? (
          <Translate contentKey="iamdentityApp.audit.changes.insert" interpolate={{ user: change.modifiedBy }} />
        ) : change.fields.length == 1 ? (
          <Translate contentKey="iamdentityApp.audit.changes.updateOne" interpolate={{ user: change.modifiedBy }} />
        ) : (
          <Translate
            contentKey="iamdentityApp.audit.changes.updateMore"
            interpolate={{ user: change.modifiedBy, changesNumber: change.fields.length }}
          />
        )}
      </h4>
      <div>
        <Table>
          <thead>
            <tr>
              <th>field</th>
              {!isInsert && <th>previous values</th>}
              <th>current values</th>
            </tr>
          </thead>
          <tbody>
            {change.fields?.map(field => (
              <tr>
                <td>{field.name}</td>
                {!isInsert && <td className="table-danger">{field.previousValue}</td>}
                <td className="table-success">{field.currentValue}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </VerticalTimelineElement>
  );
};

interface IModalProps {
  isOpen: boolean;
  toggle: () => void;
  entityName: string;
  entityId: string;
}

const AuditModal = ({ isOpen, toggle, entityName, entityId }: IModalProps) => {
  const dispatch = useAppDispatch();

  const [entityChanges, setEntityChanges] = useState<IAuditEntry[]>([]);

  const audit: IAudit = useAppSelector(state => state.audit.entities);

  useEffect(() => {
    dispatch(getRevisionsById({ id: entityId, entityName }));
  }, [entityName, entityId]);

  useEffect(() => {
    let changes = [...(audit.entityChanges ?? []), ...(audit.customAttributesChanges ?? [])];
    setEntityChanges(changes.sort((a, b) => (moment(a.modifiedAt).isAfter(moment(b.modifiedAt)) ? -1 : 1)));
  }, [audit]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="xl" scrollable={true}>
      <ModalHeader toggle={toggle}>
        <Translate contentKey="iamdentityApp.audit.modal.title" />
      </ModalHeader>
      <ModalBody>
        {entityChanges?.length ? (
          <VerticalTimeline layout="1-column-left">
            {entityChanges?.map((change, index) => (
              <TimelineElement key={`change-${index}`} change={change} />
            ))}
          </VerticalTimeline>
        ) : (
          <em>
            <Translate contentKey="iamdentityApp.audit.modal.empty" />
          </em>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AuditModal;
