import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { TextFormat, translate, Translate } from 'react-jhipster';
import { Link, Route, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, Row, AccordionBody, AccordionHeader, AccordionItem, UncontrolledAccordion, Table, Spinner } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntitiesByObjectType as getCustomPropertiesByObjectType } from 'app/entities/custom-attribute-definition/custom-attribute-definition.reducer';

import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { AuditEntityName } from 'app/shared/model/audit.model';
import { CustomAttributeDtoKey } from 'app/shared/model/custom-attribute-definition.model';
import { CustomAttributeApplicableObjectType } from 'app/shared/model/enumerations/custom-attribute-applicable-object-type.model';
import { findCustomAttributeValueByKey } from 'app/shared/util/entity-utils';
import AuditModal from '../audit/audit-modal';
import { getEntity } from './application.reducer';
import { getEntities as getRoleDefinitiosEntities } from '../role-definition/role-definition.reducer';
import { getEntities as getRoleGroupEntities } from '../role-group/role-group.reducer';
import { fetchEntityList as getApplicationResourcesEntities } from '../application-resource/application-resource.reducer';
import { fetchEntityList as getPermissionDefinitionEntities } from '../permission-definition/permission-definition.reducer';
import { IRoleDefinition } from 'app/shared/model/role-definition.model';
import { IRoleGroup } from 'app/shared/model/role-group.model';
import { IApplicationResource } from 'app/shared/model/application-resource.model';
import { IPermissionDefinition } from 'app/shared/model/permission-definition.model';
import RoleDefinitionUpdate from '../role-definition/role-definition-update';
import PermissionDefinitionUpdate from '../permission-definition/permission-definition-update';
import RoleGroupUpdate from '../role-group/role-group-update';
import ApplicationResourceUpdate from '../application-resource/application-resource-update';
import axios from 'axios';
import { useQuery } from 'react-query';
import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';
import InteractionDrawer from '../orders/details-components/requested-users/drawer/interaction-drawer';
import RoleDefinitionDeleteDialog from '../role-definition/role-definition-delete-dialog';
import PermissionDefinitionDeleteDialog from '../permission-definition/permission-definition-delete-dialog';
import RoleGroupDeleteDialog from '../role-group/role-group-delete-dialog';
import ApplicationResourceDeleteDialog from '../application-resource/application-resource-delete-dialog';
import { convertDateFormat } from 'app/shared/util/date-utils';

export const ApplicationDetail = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [auditModal, setAuditModal] = useState<boolean>(false);

  const toggleAuditModal = () => {
    setAuditModal(!auditModal);
  };

  const { applicationId: id } = useParams<'applicationId'>();

  useEffect(() => {
    dispatch(getEntity(id));
    dispatch(getCustomPropertiesByObjectType(CustomAttributeApplicableObjectType.APPLICATION));
  }, []);

  const applicationEntity = useAppSelector(state => state.application.entity);
  const customAttributeDefinitions: CustomAttributeDtoKey[] = useAppSelector(state => state.customAttributeDefinition.entities);
  const locale = useAppSelector(state => state.locale.currentLocale);

  return (
    <React.Fragment>
      <Row>
        <Col md="3">
          <Card className="jh-card">
            <h2 data-cy="applicationDetailsHeading">
              <Translate contentKey="iamdentityApp.application.detail.title">Application</Translate>
            </h2>
            <dl className="jh-entity-details">
              <dt>
                <span id="id">
                  <Translate contentKey="global.field.id">ID</Translate>
                </span>
              </dt>
              <dd>{applicationEntity.id}</dd>
              <dt>
                <span id="name">
                  <Translate contentKey="iamdentityApp.application.name">Name</Translate>
                </span>
              </dt>
              <dd>{applicationEntity.name}</dd>
              <dt>
                <span id="code">
                  <Translate contentKey="iamdentityApp.application.code">Code</Translate>
                </span>
              </dt>
              <dd>{applicationEntity.code}</dd>
              <dt>
                <span id="description">
                  <Translate contentKey="iamdentityApp.application.description">Description</Translate>
                </span>
              </dt>
              <dd>{applicationEntity.description}</dd>
              <dt>
                <span id="startDate">
                  <Translate contentKey="iamdentityApp.application.startDate">Start Date</Translate>
                </span>
              </dt>
              <dd>
                {applicationEntity.startDate ? (
                  <TextFormat value={applicationEntity.startDate} type="date" format={convertDateFormat(locale, 'date')} />
                ) : null}
              </dd>
              <dt>
                <span id="endDate">
                  <Translate contentKey="iamdentityApp.application.endDate">End Date</Translate>
                </span>
              </dt>
              <dd>
                {applicationEntity.endDate ? (
                  <TextFormat value={applicationEntity.endDate} type="date" format={convertDateFormat(locale, 'date')} />
                ) : null}
              </dd>
              <dt>
                <span id="uniqueIdentityKey">
                  <Translate contentKey="iamdentityApp.application.uniqueIdentityKey">Unique Identity Key</Translate>
                </span>
              </dt>
              <dd>{applicationEntity.uniqueIdentityKey}</dd>
              <dt>
                <span id="roleRequired">
                  <Translate contentKey="iamdentityApp.application.roleRequired">Role Required</Translate>
                </span>
              </dt>
              <dd>{applicationEntity.roleRequired ? translate('global.yes') : translate('global.no')}</dd>
              <dt>
                <span id="roleRequirements">
                  <Translate contentKey="iamdentityApp.application.roleRequirements">Role Requirements</Translate>
                </span>
              </dt>
              <dd>{applicationEntity.roleRequirements}</dd>
              <dt>
                <Translate contentKey="iamdentityApp.application.system">System</Translate>
              </dt>
              <dd>{applicationEntity.system ? applicationEntity.system.name : ''}</dd>
              <dt>
                <span id="main">
                  <Translate contentKey="iamdentityApp.application.main">Main</Translate>
                </span>
              </dt>
              <dd>{applicationEntity.main ? translate('global.yes') : translate('global.no')}</dd>
              <dt>
                <span id="connectorTopic">
                  <Translate contentKey="iamdentityApp.application.connectorTopic">Connector Topic</Translate>
                </span>
              </dt>
              <dd>{applicationEntity.connectorTopic}</dd>
              <dt>
                <Translate contentKey="iamdentityApp.application.userDependsOnApplication">User Depends On Application</Translate>
              </dt>
              <dd>{applicationEntity.userDependsOnApplication ? applicationEntity.userDependsOnApplication.name : ''}</dd>
              <dt>
                <span id="connectorTimeout">
                  <Translate contentKey="iamdentityApp.application.connectorTimeout">Connector Timeout</Translate>
                </span>
              </dt>
              <dd>{applicationEntity.connectorTimeout}</dd>
              <dt>
                <span id="supportsUserSuspend">
                  <Translate contentKey="iamdentityApp.application.supportsUserSuspend">Supports User Suspend</Translate>
                </span>
              </dt>
              <dd>{applicationEntity.supportsUserSuspend ? translate('global.yes') : translate('global.no')}</dd>
              <dt>
                <span id="applicableBusinessValidityStartDateOnUser">
                  <Translate contentKey="iamdentityApp.application.applicableBusinessValidityStartDateOnUser">
                    Applicable Business Validity Start Date On User
                  </Translate>
                </span>
              </dt>
              <dd>{applicationEntity.applicableBusinessValidityStartDateOnUser ? translate('global.yes') : translate('global.no')}</dd>
              <dt>
                <span id="applicableBusinessValidityEndDateOnUser">
                  <Translate contentKey="iamdentityApp.application.applicableBusinessValidityEndDateOnUser">
                    Applicable Business Validity End Date On User
                  </Translate>
                </span>
              </dt>
              <dd>{applicationEntity.applicableBusinessValidityEndDateOnUser ? translate('global.yes') : translate('global.no')}</dd>
              <dt>
                <span id="applicableBusinessValidityStartDateOnRole">
                  <Translate contentKey="iamdentityApp.application.applicableBusinessValidityStartDateOnRole">
                    Applicable Business Validity Start Date On Role
                  </Translate>
                </span>
              </dt>
              <dd>{applicationEntity.applicableBusinessValidityStartDateOnRole ? translate('global.yes') : translate('global.no')}</dd>
              <dt>
                <span id="applicableBusinessValidityEndDateOnRole">
                  <Translate contentKey="iamdentityApp.application.applicableBusinessValidityEndDateOnRole">
                    Applicable Business Validity End Date On Role
                  </Translate>
                </span>
              </dt>
              <dd>{applicationEntity.applicableBusinessValidityEndDateOnRole ? translate('global.yes') : translate('global.no')}</dd>
              <dt>
                <span id="applicableBusinessValidityStartDateOnGroup">
                  <Translate contentKey="iamdentityApp.application.applicableBusinessValidityStartDateOnGroup">
                    Applicable Business Validity Start Date On Group
                  </Translate>
                </span>
              </dt>
              <dd>{applicationEntity.applicableBusinessValidityStartDateOnGroup ? translate('global.yes') : translate('global.no')}</dd>
              <dt>
                <span id="applicableBusinessValidityEndDateOnGroup">
                  <Translate contentKey="iamdentityApp.application.applicableBusinessValidityEndDateOnGroup">
                    Applicable Business Validity End Date On Group
                  </Translate>
                </span>
              </dt>
              <dd>{applicationEntity.applicableBusinessValidityEndDateOnGroup ? translate('global.yes') : translate('global.no')}</dd>
              <dt>
                <span id="applicableBusinessValidityStartDateOnPermission">
                  <Translate contentKey="iamdentityApp.application.applicableBusinessValidityStartDateOnPermission">
                    Applicable Business Validity Start Date On Permission
                  </Translate>
                </span>
              </dt>
              <dd>
                {applicationEntity.applicableBusinessValidityStartDateOnPermission ? translate('global.yes') : translate('global.no')}
              </dd>
              <dt>
                <span id="applicableBusinessValidityEndDateOnPermission">
                  <Translate contentKey="iamdentityApp.application.applicableBusinessValidityEndDateOnPermission">
                    Applicable Business Validity End Date On Permission
                  </Translate>
                </span>
              </dt>
              <dd>{applicationEntity.applicableBusinessValidityEndDateOnPermission ? translate('global.yes') : translate('global.no')}</dd>

              {customAttributeDefinitions?.map(cad => (
                <React.Fragment>
                  <dt>
                    <span id={cad.displayName}>{cad.displayName}</span>
                  </dt>
                  <dd>{findCustomAttributeValueByKey(cad, applicationEntity)}</dd>
                </React.Fragment>
              ))}
              <dt>
                <Translate contentKey="global.field.createdBy">Created By</Translate>
              </dt>
              <dd>{applicationEntity.createdBy ? applicationEntity.createdBy : ''}</dd>
              <dt>
                <Translate contentKey="global.field.createdDate">Created Date</Translate>
              </dt>
              <dd>
                {applicationEntity.createdDate ? (
                  <TextFormat value={applicationEntity.createdDate} type="date" format={convertDateFormat(locale, 'date')} />
                ) : null}
              </dd>
              <dt>
                <Translate contentKey="global.field.lastModifiedBy">Last Modified By</Translate>
              </dt>
              <dd>{applicationEntity.lastModifiedBy ? applicationEntity.lastModifiedBy : ''}</dd>
              <dt>
                <Translate contentKey="global.field.lastModifiedDate">Last Modified Date</Translate>
              </dt>
              <dd>
                {applicationEntity.lastModifiedDate ? (
                  <TextFormat value={applicationEntity.lastModifiedDate} type="date" format={convertDateFormat(locale, 'date')} />
                ) : null}
              </dd>
            </dl>
            <Button id="cancel-save" data-cy="entityDetailsBackButton" onClick={() => navigate(-1)} color="info">
              <FontAwesomeIcon icon="arrow-left" />{' '}
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.back">Back</Translate>
              </span>
            </Button>
            &nbsp;
            <Button tag={Link} to={`/application/${applicationEntity.id}/edit`} color="primary">
              <FontAwesomeIcon icon="pencil-alt" />{' '}
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.edit">Edit</Translate>
              </span>
            </Button>
            <Button onClick={toggleAuditModal} color="light">
              <FontAwesomeIcon icon={faClockRotateLeft} />{' '}
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.history">History</Translate>
              </span>
            </Button>
          </Card>
        </Col>

        <Col md="9">
          <RoleDefinition id={id} applicationEntity={applicationEntity} />
        </Col>
      </Row>
      <AuditModal isOpen={auditModal} toggle={toggleAuditModal} entityId={id} entityName={AuditEntityName.APPLICATION} />
    </React.Fragment>
  );
};

function RoleDefinition({ id, applicationEntity }) {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const roleDefinitionEntities: IRoleDefinition[] = useAppSelector(state => state.roleDefinition.entities);
  const roleGroupEntities: IRoleGroup[] = useAppSelector(state => state.roleGroup.entities);
  const applicationResourcesEntities: IApplicationResource[] = useAppSelector(state => state.applicationResource.entities);
  const permissionDefinitionEntities: IPermissionDefinition[] = useAppSelector(state => state.permissionDefinition.entities);
  const loading: boolean = useAppSelector(
    state => state.roleDefinition.loader || state.roleGroup.loader || state.applicationResource.loader || state.permissionDefinition.loader
  );

  const updatingRoleDefinition: boolean = useAppSelector(state => state.roleDefinition.updating);
  const updatingRoleGroup: boolean = useAppSelector(state => state.roleGroup.updating);
  const updatingApplicationResource: boolean = useAppSelector(state => state.applicationResource.updating);
  const updatingPermissionDefinition: boolean = useAppSelector(state => state.permissionDefinition.updating);
  const locale = useAppSelector(state => state.locale.currentLocale);

  const startProcessQueryRoleDefinition = async () => {
    return await axios.get(`api/custom-attribute-definitions-for-objectType/${CustomAttributeApplicableObjectType.ROLE_DEFINITION}`);
  };

  const startProcessQueryApplicationResource = async () => {
    return await axios.get(`api/custom-attribute-definitions-for-objectType/${CustomAttributeApplicableObjectType.APPLICATION_RESOURCE}`);
  };

  const startProcessQueryPermissionDefinition = async () => {
    return await axios.get(`api/custom-attribute-definitions-for-objectType/${CustomAttributeApplicableObjectType.PERMISSION_DEFINITION}`);
  };

  const {
    refetch: refetchRoleDefinition,
    isSuccess: successRoleDefinition,
    data: customAttributeRoleDefinition,
  } = useQuery('roleDefinition', startProcessQueryRoleDefinition, { refetchOnWindowFocus: false, enabled: true });
  const {
    refetch: refetchApplicationResource,
    isSuccess: successApplicationResource,
    data: customAttributeApplicationResource,
  } = useQuery('applicationResources', startProcessQueryApplicationResource, { refetchOnWindowFocus: false, enabled: true });
  const {
    refetch: refetchPermissionDefinition,
    isSuccess: successPermissionDefiniton,
    data: customAttributePermissionDefinition,
  } = useQuery('permissionDefinition', startProcessQueryPermissionDefinition, { refetchOnWindowFocus: false, enabled: true });

  useEffect(() => {
    if (location.pathname === `/application/${id}/` || location.pathname === `/application/${id}`) {
      dispatch(getApplicationResourcesEntities({ query: 'applicationId.equals=' + id.toString() + `&page=0&size=200` }));
      dispatch(getRoleDefinitiosEntities({ query: 'applicationId.equals=' + id.toString() + `&page=0&size=200` }));
      dispatch(getRoleGroupEntities({ query: 'applicationId.equals=' + id.toString() + `&page=0&size=200` }));
    }
  }, [id, location.pathname]);

  useEffect(() => {
    if (applicationResourcesEntities) {
      dispatch(
        getPermissionDefinitionEntities({
          query: 'applicationResourceId.in=' + applicationResourcesEntities.map(i => i.id).join(',') + `&page=0&size=200`,
        })
      );
    }
  }, [applicationResourcesEntities]);

  const generateRoleDefinitionUrl = (prepopulateWithApplicationDetails?: any, entity?: any, action?: 'VIEW' | 'DELETE') => {
    let result = 'role-definition-update';
    if (action === 'DELETE' && entity && prepopulateWithApplicationDetails) {
      return encodeURI((result += `/${entity.id}${action ? (action == 'DELETE' ? '/delete' : '/view') : ''}`));
    }

    if (entity && prepopulateWithApplicationDetails) {
      return encodeURI(
        (result += `/${entity.id}?application.id=${applicationEntity?.id}&application.name=${applicationEntity?.name}&application.startDate=${applicationEntity.startDate}&application.endDate=${applicationEntity.endDate}`)
      );
    }

    return encodeURI(
      (result += `?application.id=${prepopulateWithApplicationDetails?.id}&application.name=${
        prepopulateWithApplicationDetails?.name
      }&application.startDate=${applicationEntity.startDate}&application.endDate=${
        applicationEntity?.endDate ? applicationEntity.endDate : null
      }`)
    );
  };

  const generateRoleGroupUrl = (prepopulateWithApplicationDetails?: any, entity?: any, action?: 'VIEW' | 'DELETE') => {
    let result = 'role-group-update';
    if (action === 'DELETE' && entity) {
      return encodeURI((result += `/${entity.id}${action ? (action == 'DELETE' ? '/delete' : '/view') : ''}`));
    }

    if (entity && prepopulateWithApplicationDetails) {
      return encodeURI(
        (result += `/${entity.id}?application.id=${applicationEntity?.id}&application.name=${applicationEntity?.name}&application.startDate=${applicationEntity.startDate}&application.endDate=${applicationEntity.endDate}`)
      );
    }

    return encodeURI(
      (result += `?application.id=${prepopulateWithApplicationDetails?.id}&application.name=${prepopulateWithApplicationDetails?.name}&application.startDate=${applicationEntity.startDate}&application.endDate=${applicationEntity.endDate}`)
    );
  };

  const generateApplicationResourceUrl = (prepopulateWithApplicationDetails?: any, entity?: any, action?: 'VIEW' | 'DELETE') => {
    let result = 'application-resource-update';
    if (action === 'DELETE' && entity) {
      return encodeURI((result += `/${entity.id}${action ? (action == 'DELETE' ? '/delete' : '/view') : ''}`));
    }

    if (entity && prepopulateWithApplicationDetails) {
      return encodeURI(
        (result += `/${entity.id}?application.id=${prepopulateWithApplicationDetails?.id}&application.name=${prepopulateWithApplicationDetails?.name}&application.startDate=${applicationEntity.startDate}&application.endDate=${applicationEntity.endDate}`)
      );
    }

    return encodeURI(
      (result += `?application.id=${prepopulateWithApplicationDetails?.id}&application.name=${prepopulateWithApplicationDetails?.name}&application.startDate=${applicationEntity.startDate}&application.endDate=${applicationEntity.endDate}`)
    );
  };

  const generatePermissionDefinitionUrl = (prepopulateWithApplicationDetails?: any, entity?: any, action?: 'VIEW' | 'DELETE') => {
    //delete
    let result = 'permission-definition-update';
    if (action === 'DELETE' && entity) {
      return encodeURI((result += `/${entity.id}${action ? (action == 'DELETE' ? '/delete' : '/view') : ''}`));
    }

    if (entity && prepopulateWithApplicationDetails) {
      return encodeURI(
        (result += `/${entity.id}?application.id=${prepopulateWithApplicationDetails?.id}&application.startDate=${applicationEntity.startDate}&application.endDate=${applicationEntity.endDate}`)
      );
    }

    return encodeURI(
      (result += `?application.id=${prepopulateWithApplicationDetails?.id}&application.startDate=${applicationEntity.startDate}&application.endDate=${applicationEntity.endDate}`)
    );
  };
  return (
    <React.Fragment>
      <Row>
        <UncontrolledAccordion open="" defaultOpen="1">
          <AccordionItem>
            <AccordionHeader targetId="1">
              <Translate contentKey="iamdentityApp.roleDefinition.home.title">Role Definition</Translate>
            </AccordionHeader>
            <AccordionBody accordionId="1">
              <div className="flex-div full-width justify-content-end mr-1">
                <div className="btn-group flex-btn-group-container justify-content-end m-2">
                  <Button tag={Link} to={`${generateRoleDefinitionUrl(applicationEntity)}`} color="success" size="sm">
                    <FontAwesomeIcon icon="plus" />{' '}
                    <span className="d-none d-md-inline">
                      <Translate contentKey="entity.action.create">Create</Translate>
                    </span>
                  </Button>
                </div>
              </div>
              <div className="table-responsive">
                <Table responsive>
                  <thead>
                    <tr>
                      <th className="text-center">
                        <Translate contentKey="iamdentityApp.roleDefinition.name">Name</Translate>
                      </th>
                      <th className="text-center">
                        <Translate contentKey="iamdentityApp.roleDefinition.code">Code</Translate>
                      </th>
                      <th className="text-center">
                        <Translate contentKey="iamdentityApp.roleDefinition.description">Description</Translate>
                      </th>
                      <th className="text-center">
                        <Translate contentKey="iamdentityApp.roleDefinition.startDate">Start Date</Translate>
                      </th>
                      <th className="text-center">
                        <Translate contentKey="iamdentityApp.roleDefinition.endDate">End Date</Translate>
                      </th>
                      {successRoleDefinition
                        ? customAttributeRoleDefinition.data.map(attribute => <th className="text-center">{attribute.displayName}</th>)
                        : null}
                      <td />
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(roleDefinitionEntities) &&
                      roleDefinitionEntities.map(roleDefinitionEntity => (
                        <tr key={`roleDefinitionEntity-${roleDefinitionEntity.id}`} data-cy="roleDefinitionEntity">
                          <td>{roleDefinitionEntity?.name ? roleDefinitionEntity.name : ''}</td>
                          <td>{roleDefinitionEntity?.code ? roleDefinitionEntity.code : ''}</td>
                          <td>{roleDefinitionEntity?.description ? roleDefinitionEntity.description : ''}</td>
                          <td>
                            {roleDefinitionEntity?.startDate ? (
                              <TextFormat type="date" value={roleDefinitionEntity.startDate} format={convertDateFormat(locale, 'date')} />
                            ) : null}
                          </td>
                          <td>
                            {roleDefinitionEntity?.endDate ? (
                              <TextFormat type="date" value={roleDefinitionEntity.endDate} format={convertDateFormat(locale, 'date')} />
                            ) : null}
                          </td>
                          {successRoleDefinition
                            ? customAttributeRoleDefinition.data.map(value => (
                                <td>{findCustomAttributeValueByKey(value, roleDefinitionEntity)}</td>
                              ))
                            : null}

                          <td>
                            <div className="btn-group flex-btn-group-container">
                              <Button
                                tag={Link}
                                to={`${generateRoleDefinitionUrl(applicationEntity, roleDefinitionEntity)}`}
                                color="primary"
                                size="sm"
                              >
                                <FontAwesomeIcon icon="pencil-alt" />{' '}
                              </Button>

                              <Button
                                tag={Link}
                                to={generateRoleDefinitionUrl(applicationEntity, roleDefinitionEntity, 'DELETE')}
                                color="danger"
                                className="btn-md"
                              >
                                <FontAwesomeIcon icon="trash" />
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
                {(!roleDefinitionEntities || roleDefinitionEntities.length < 1) && !loading && (
                  <div className="alert alert-warning mt-1">
                    <Translate contentKey="iamdentityApp.roleDefinition.home.notFound">No Role Groups found</Translate>
                  </div>
                )}
                {loading || updatingRoleDefinition ? <Spinner className="d-flex justify-content-center align-items-center m-auto" /> : null}
              </div>
            </AccordionBody>
          </AccordionItem>

          <AccordionItem>
            <AccordionHeader targetId="2">
              <Translate contentKey="iamdentityApp.roleGroup.home.title">Role Group</Translate>
            </AccordionHeader>
            <AccordionBody accordionId="2">
              <div className="flex-div full-width justify-content-end mr-1">
                <div className="btn-group flex-btn-group-container justify-content-end m-2">
                  <Button tag={Link} to={`${generateRoleGroupUrl(applicationEntity)}`} color="success" size="sm">
                    <FontAwesomeIcon icon="plus" />{' '}
                    <span className="d-none d-md-inline">
                      <Translate contentKey="entity.action.create">Create</Translate>
                    </span>
                  </Button>
                </div>
              </div>
              <div className="table-responsive">
                <Table responsive>
                  <thead>
                    <tr>
                      <th className="text-center">
                        <Translate contentKey="iamdentityApp.roleGroup.name">Name</Translate>
                      </th>
                      <th className="text-center">
                        <Translate contentKey="iamdentityApp.roleGroup.startDate">Start Date</Translate>
                      </th>
                      <th className="text-center">
                        <Translate contentKey="iamdentityApp.roleGroup.endDate">End Date</Translate>
                      </th>
                      <th className="text-center">
                        <Translate contentKey="iamdentityApp.roleGroup.roleDefinition">Role Definition</Translate>
                      </th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(roleGroupEntities) &&
                      roleGroupEntities.map(roleGroupEntity => (
                        <tr key={`roleGroupEntity-${roleGroupEntity.id}`} data-cy="roleGroupEntity">
                          <td>{roleGroupEntity.name ? roleGroupEntity.name : ''}</td>
                          <td>
                            {roleGroupEntity?.startDate ? (
                              <TextFormat type="date" value={roleGroupEntity.startDate} format={convertDateFormat(locale, 'date')} />
                            ) : null}
                          </td>
                          <td>
                            {roleGroupEntity?.endDate ? (
                              <TextFormat type="date" value={roleGroupEntity.endDate} format={convertDateFormat(locale, 'date')} />
                            ) : null}
                          </td>
                          <td>
                            {roleGroupEntity?.roleDefinitions
                              ? roleGroupEntity?.roleDefinitions.map(roleDef => roleDef.name).join(', ')
                              : null}
                          </td>
                          <td>
                            <div className="btn-group flex-btn-group-container">
                              <Button
                                tag={Link}
                                to={`${generateRoleGroupUrl(applicationEntity, roleGroupEntity)}`}
                                color="primary"
                                size="sm"
                              >
                                <FontAwesomeIcon icon="pencil-alt" />{' '}
                              </Button>

                              <Button
                                tag={Link}
                                to={generateRoleGroupUrl(applicationEntity, roleGroupEntity, 'DELETE')}
                                color="danger"
                                className="btn-md"
                              >
                                <FontAwesomeIcon icon="trash" />
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
                {(!roleGroupEntities || roleGroupEntities.length < 1) && !loading && (
                  <div className="alert alert-warning mt-1">
                    <Translate contentKey="iamdentityApp.roleGroup.home.notFound">No Role Groups found</Translate>
                  </div>
                )}
                {loading || updatingRoleGroup ? <Spinner className="d-flex justify-content-center align-items-center m-auto" /> : null}
              </div>
            </AccordionBody>
          </AccordionItem>

          <AccordionItem>
            <AccordionHeader targetId="3">
              <Translate contentKey="iamdentityApp.applicationResource.home.title">Application Resources</Translate>
            </AccordionHeader>
            <AccordionBody accordionId="3">
              <div className="flex-div full-width justify-content-end mr-1">
                <div className="btn-group flex-btn-group-container justify-content-end m-2">
                  <Button tag={Link} to={`${generateApplicationResourceUrl(applicationEntity)}`} color="success" size="sm">
                    <FontAwesomeIcon icon="plus" />{' '}
                    <span className="d-none d-md-inline">
                      <Translate contentKey="entity.action.create">Create</Translate>
                    </span>
                  </Button>
                </div>
              </div>
              <div className="table-responsive">
                <Table responsive>
                  <thead>
                    <tr>
                      <th className="text-center">
                        <Translate contentKey="iamdentityApp.applicationResource.name">Name</Translate>
                      </th>
                      <th className="text-center">
                        <Translate contentKey="iamdentityApp.applicationResource.code">Code</Translate>
                      </th>
                      <th className="text-center">
                        <Translate contentKey="iamdentityApp.applicationResource.description">Description</Translate>
                      </th>
                      <th className="text-center">
                        <Translate contentKey="iamdentityApp.applicationResource.startDate">Start Date</Translate>
                      </th>
                      <th className="text-center">
                        <Translate contentKey="iamdentityApp.applicationResource.endDate">End Date</Translate>
                      </th>
                      {successApplicationResource
                        ? customAttributeApplicationResource.data.map(attribute => <th className="text-center">{attribute.displayName}</th>)
                        : null}
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(applicationResourcesEntities) &&
                      applicationResourcesEntities.map(applicationResourcesEntity => (
                        <tr key={`applicationResourcesEntity-${applicationResourcesEntity.id}`} data-cy="applicationResourcesEntity">
                          <td>{applicationResourcesEntity?.name ? applicationResourcesEntity?.name : ''}</td>
                          <td>{applicationResourcesEntity?.code ? applicationResourcesEntity?.code : ''}</td>
                          <td>{applicationResourcesEntity?.description ? applicationResourcesEntity?.description : ''}</td>
                          <td>
                            {applicationResourcesEntity?.startDate ? (
                              <TextFormat
                                type="date"
                                value={applicationResourcesEntity.startDate}
                                format={convertDateFormat(locale, 'date')}
                              />
                            ) : null}
                          </td>
                          <td>
                            {applicationResourcesEntity?.endDate ? (
                              <TextFormat
                                type="date"
                                value={applicationResourcesEntity.endDate}
                                format={convertDateFormat(locale, 'date')}
                              />
                            ) : null}
                          </td>
                          {successApplicationResource
                            ? customAttributeApplicationResource.data.map(value => (
                                <td>{findCustomAttributeValueByKey(value, applicationResourcesEntity)}</td>
                              ))
                            : null}
                          <td>
                            <div className="btn-group flex-btn-group-container">
                              <Button
                                tag={Link}
                                to={`${generateApplicationResourceUrl(applicationEntity, applicationResourcesEntity)}`}
                                color="primary"
                                size="sm"
                              >
                                <FontAwesomeIcon icon="pencil-alt" />{' '}
                              </Button>

                              <Button
                                tag={Link}
                                to={generateApplicationResourceUrl(applicationEntity, applicationResourcesEntity, 'DELETE')}
                                color="danger"
                                className="btn-md"
                              >
                                <FontAwesomeIcon icon="trash" />
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
                {(!applicationResourcesEntities || applicationResourcesEntities.length < 1) && !loading && (
                  <div className="alert alert-warning mt-1">
                    <Translate contentKey="iamdentityApp.applicationResource.home.notFound">No Application Resources found</Translate>
                  </div>
                )}
                {loading || updatingApplicationResource ? (
                  <Spinner className="d-flex justify-content-center align-items-center m-auto" />
                ) : null}
              </div>
            </AccordionBody>
          </AccordionItem>

          <AccordionItem>
            <AccordionHeader targetId="4">
              <Translate contentKey="iamdentityApp.permissionDefinition.home.title">Permission Definitions</Translate>
            </AccordionHeader>
            <AccordionBody accordionId="4">
              <div className="flex-div full-width justify-content-end mr-1">
                <div className="btn-group flex-btn-group-container justify-content-end m-2">
                  <Button tag={Link} to={`${generatePermissionDefinitionUrl(applicationEntity)}`} color="success" size="sm">
                    <FontAwesomeIcon icon="plus" />{' '}
                    <span className="d-none d-md-inline">
                      <Translate contentKey="entity.action.create">Create</Translate>
                    </span>
                  </Button>
                </div>
              </div>

              <div className="table-responsive">
                <Table responsive>
                  <thead>
                    <tr>
                      <th className="text-center">
                        <Translate contentKey="iamdentityApp.permissionDefinition.name">Name</Translate>
                      </th>
                      <th className="text-center">
                        <Translate contentKey="iamdentityApp.permissionDefinition.code">Code</Translate>
                      </th>
                      <th className="text-center">
                        <Translate contentKey="iamdentityApp.permissionDefinition.description">Description</Translate>
                      </th>
                      <th className="text-center">
                        <Translate contentKey="iamdentityApp.permissionDefinition.startDate">Start Date</Translate>
                      </th>
                      <th className="text-center">
                        <Translate contentKey="iamdentityApp.permissionDefinition.endDate">End Date</Translate>
                      </th>
                      <th className="text-center">
                        <Translate contentKey="iamdentityApp.permissionDefinition.applicationResource">Application Resource</Translate>
                      </th>

                      {successPermissionDefiniton
                        ? customAttributePermissionDefinition.data.map(attribute => (
                            <th className="text-center">{attribute.displayName}</th>
                          ))
                        : null}
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(permissionDefinitionEntities) &&
                      permissionDefinitionEntities.map(permissionDefinitionEntity => (
                        <tr key={`permissionDefinitionEntity-${permissionDefinitionEntity.id}`} data-cy="permissionDefinitionEntity">
                          <td>{permissionDefinitionEntity?.name ? permissionDefinitionEntity.name : ''}</td>
                          <td>{permissionDefinitionEntity?.code ? permissionDefinitionEntity.code : ''}</td>
                          <td>{permissionDefinitionEntity?.description ? permissionDefinitionEntity?.description : ''}</td>
                          <td>
                            {permissionDefinitionEntity?.startDate ? (
                              <TextFormat
                                type="date"
                                value={permissionDefinitionEntity.startDate}
                                format={convertDateFormat(locale, 'date')}
                              />
                            ) : null}
                          </td>
                          <td>
                            {permissionDefinitionEntity?.endDate ? (
                              <TextFormat
                                type="date"
                                value={permissionDefinitionEntity.endDate}
                                format={convertDateFormat(locale, 'date')}
                              />
                            ) : null}
                          </td>
                          <td>
                            {permissionDefinitionEntity?.applicationResource?.name
                              ? permissionDefinitionEntity?.applicationResource?.name
                              : ''}
                          </td>
                          {successPermissionDefiniton
                            ? customAttributePermissionDefinition.data.map(value => (
                                <td>{findCustomAttributeValueByKey(value, permissionDefinitionEntity)}</td>
                              ))
                            : null}
                          <td>
                            <div className="btn-group flex-btn-group-container">
                              <Button
                                tag={Link}
                                to={`${generatePermissionDefinitionUrl(applicationEntity, permissionDefinitionEntity)}`}
                                color="primary"
                                size="sm"
                              >
                                <FontAwesomeIcon icon="pencil-alt" />{' '}
                              </Button>

                              <Button
                                tag={Link}
                                to={generatePermissionDefinitionUrl(applicationEntity, permissionDefinitionEntity, 'DELETE')}
                                color="danger"
                                className="btn-md"
                              >
                                <FontAwesomeIcon icon="trash" />
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
                {(!permissionDefinitionEntities || permissionDefinitionEntities.length < 1) && !loading && (
                  <div className="alert alert-warning mt-1">
                    <Translate contentKey="iamdentityApp.permissionDefinition.home.notFound">No Permission Definitions found</Translate>
                  </div>
                )}
                {loading || updatingPermissionDefinition ? (
                  <Spinner className="d-flex justify-content-center align-items-center m-auto" />
                ) : null}
              </div>
            </AccordionBody>
          </AccordionItem>
        </UncontrolledAccordion>
      </Row>
      <ErrorBoundaryRoutes>
        <Route path="permission-definition-update">
          <Route
            index
            element={
              <InteractionDrawer>
                <PermissionDefinitionUpdate />
              </InteractionDrawer>
            }
          />
          <Route path=":permissionDefinitionUpdateId">
            <Route
              index
              element={
                <InteractionDrawer>
                  <PermissionDefinitionUpdate />
                </InteractionDrawer>
              }
            />
            <Route path="delete" element={<PermissionDefinitionDeleteDialog />} />
          </Route>
        </Route>

        <Route path="role-definition-update">
          <Route
            index
            element={
              <InteractionDrawer>
                <RoleDefinitionUpdate />
              </InteractionDrawer>
            }
          />
          <Route path=":roleDefinitionUpdateId">
            <Route
              index
              element={
                <InteractionDrawer>
                  <RoleDefinitionUpdate />
                </InteractionDrawer>
              }
            />
            <Route path="delete" element={<RoleDefinitionDeleteDialog />} />
          </Route>
        </Route>

        <Route path="role-group-update">
          <Route
            index
            element={
              <InteractionDrawer>
                <RoleGroupUpdate />
              </InteractionDrawer>
            }
          />
          <Route path=":roleGroupUpdateId">
            <Route
              index
              element={
                <InteractionDrawer>
                  <RoleGroupUpdate />
                </InteractionDrawer>
              }
            />
            <Route path="delete" element={<RoleGroupDeleteDialog />} />
          </Route>
        </Route>

        <Route path="application-resource-update">
          <Route
            index
            element={
              <InteractionDrawer>
                <ApplicationResourceUpdate />
              </InteractionDrawer>
            }
          />
          <Route path=":applicationResourceId">
            <Route
              index
              element={
                <InteractionDrawer>
                  <ApplicationResourceUpdate />
                </InteractionDrawer>
              }
            />
            <Route path="delete" element={<ApplicationResourceDeleteDialog />} />
          </Route>
        </Route>
      </ErrorBoundaryRoutes>
    </React.Fragment>
  );
}

export default ApplicationDetail;
