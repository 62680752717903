/* eslint-disable react/jsx-key */
import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { convertDateFormat } from 'app/shared/util/date-utils';
import { getEntity } from './recertification-session-specific-permission.reducer';
import { getEntitiesByObjectType as getCustomPropertiesByObjectType } from 'app/entities/custom-attribute-definition/custom-attribute-definition.reducer';
import { CustomAttributeDtoKey } from 'app/shared/model/custom-attribute-definition.model';
import { CustomAttributeApplicableObjectType } from 'app/shared/model/enumerations/custom-attribute-applicable-object-type.model';
import { findCustomAttributeValueByKey } from 'app/shared/util/entity-utils';

export const RecertificationSessionSpecificPermissionDetail = () => {
  const dispatch = useAppDispatch();

  const { recertificationSessionSpecificPermissionId: id } = useParams<'recertificationSessionSpecificPermissionId'>();

  useEffect(() => {
    dispatch(getEntity(id));
    dispatch(getCustomPropertiesByObjectType(CustomAttributeApplicableObjectType.SPECIFIC_PERMISSION));
  }, []);

  const recertificationSessionSpecificPermissionEntity = useAppSelector(state => state.recertificationSessionSpecificPermission.entity);
  const customAttributeDefinitions: CustomAttributeDtoKey[] = useAppSelector(state => state.customAttributeDefinition.entities);
  const locale = useAppSelector(state => state.locale.currentLocale);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="recertificationSessionSpecificPermissionDetailsHeading">
          <Translate contentKey="iamdentityApp.recertificationSessionSpecificPermission.detail.title">
            Recertification Session Specific Permission
          </Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{recertificationSessionSpecificPermissionEntity.id ? recertificationSessionSpecificPermissionEntity.id : ''}</dd>
          <dt>
            <Translate contentKey="iamdentityApp.recertificationSession.detail.title">Recertification Session</Translate>:
          </dt>
          <dd>
            {recertificationSessionSpecificPermissionEntity.recertificationSession
              ? recertificationSessionSpecificPermissionEntity.recertificationSession.name
              : ''}
          </dd>
          <dt>
            <Translate contentKey="iamdentityApp.recertificationSessionSpecificPermission.application">Application</Translate>:
          </dt>
          <dd>
            {recertificationSessionSpecificPermissionEntity.userSpecificPermission ? (
              <Link
                to={`/application/${
                  recertificationSessionSpecificPermissionEntity.userSpecificPermission.permissionDefinition?.applicationResource
                    ?.application?.id
                    ? recertificationSessionSpecificPermissionEntity.userSpecificPermission.permissionDefinition?.applicationResource
                        ?.application?.id
                    : ''
                }`}
              >
                {recertificationSessionSpecificPermissionEntity.userSpecificPermission
                  ? recertificationSessionSpecificPermissionEntity.userSpecificPermission.permissionDefinition?.applicationResource
                      ?.application?.name
                  : ''}
              </Link>
            ) : (
              ''
            )}
          </dd>
          <dt>
            <Translate contentKey="iamdentityApp.recertificationSessionSpecificPermission.permissionDefinition">
              Permission Definition
            </Translate>
            :
          </dt>
          <dd>
            {recertificationSessionSpecificPermissionEntity.userSpecificPermission ? (
              <Link
                to={`/permission-definition/${
                  recertificationSessionSpecificPermissionEntity.userSpecificPermission
                    ? recertificationSessionSpecificPermissionEntity.userSpecificPermission.permissionDefinition?.id
                    : ''
                }`}
              >
                {recertificationSessionSpecificPermissionEntity.userSpecificPermission
                  ? recertificationSessionSpecificPermissionEntity.userSpecificPermission.permissionDefinition?.name
                  : ''}
              </Link>
            ) : (
              ''
            )}
          </dd>
          <dt>
            <Translate contentKey="iamdentityApp.recertificationSessionSpecificPermission.status">Status</Translate>:
          </dt>
          <dd>
            {recertificationSessionSpecificPermissionEntity.userSpecificPermission
              ? recertificationSessionSpecificPermissionEntity.userSpecificPermission.status
              : ''}
          </dd>
          <dt>
            <Translate contentKey="iamdentityApp.recertificationSessionSpecificPermission.grantedBy">Granted By</Translate>:
          </dt>
          <dd>
            {recertificationSessionSpecificPermissionEntity.userSpecificPermission
              ? recertificationSessionSpecificPermissionEntity.userSpecificPermission.grantedBy
              : ''}
          </dd>
          <dt>
            <Translate contentKey="iamdentityApp.recertificationSessionSpecificPermission.validFrom">Valid From</Translate>:
          </dt>
          <dd>
            {recertificationSessionSpecificPermissionEntity.userSpecificPermission?.startDate ? (
              <TextFormat
                value={recertificationSessionSpecificPermissionEntity.userSpecificPermission.startDate}
                type="date"
                format={convertDateFormat(locale, 'instant')}
              />
            ) : (
              ''
            )}
          </dd>
          <dt>
            <Translate contentKey="iamdentityApp.recertificationSessionSpecificPermission.validTo">Valid To</Translate>:
          </dt>
          <dd>
            {recertificationSessionSpecificPermissionEntity.userSpecificPermission?.endDate ? (
              <TextFormat
                value={recertificationSessionSpecificPermissionEntity.userSpecificPermission.endDate}
                type="date"
                format={convertDateFormat(locale, 'instant')}
              />
            ) : (
              ''
            )}
          </dd>
          {recertificationSessionSpecificPermissionEntity.revoked !== null &&
          recertificationSessionSpecificPermissionEntity.revoked !== undefined ? (
            <div>
              <dt>
                <Translate contentKey="iamdentityApp.recertificationSessionSpecificPermission.revoked">Revoked</Translate>:
              </dt>
              <dd>
                {recertificationSessionSpecificPermissionEntity.revoked === true ? (
                  recertificationSessionSpecificPermissionEntity.order ? (
                    <div>
                      <Translate contentKey="iamdentityApp.recertificationSessionSpecificPermission.revokedByOrder">
                        Revoked by Order
                      </Translate>
                      :&nbsp;
                      <Link to={`/orders/${recertificationSessionSpecificPermissionEntity.order.id}`}>
                        {recertificationSessionSpecificPermissionEntity.order.id}
                      </Link>
                      &nbsp;
                      {recertificationSessionSpecificPermissionEntity?.order?.status ? (
                        <span className="fw-bold text-white d-inline-block">
                          ({recertificationSessionSpecificPermissionEntity?.order?.status})
                        </span>
                      ) : (
                        ''
                      )}
                    </div>
                  ) : (
                    <Translate contentKey="iamdentityApp.recertificationSessionSpecificPermission.revokedByOrder">
                      Revoked by Order
                    </Translate>
                  )
                ) : (
                  <Translate contentKey="iamdentityApp.recertificationSessionSpecificPermission.confirmed">Confirmed</Translate>
                )}
              </dd>
            </div>
          ) : null}
          {customAttributeDefinitions?.map(cad => (
            <React.Fragment>
              <dt>
                <span id={cad.displayName}>{cad.displayName}</span>
              </dt>
              <dd>
                {recertificationSessionSpecificPermissionEntity.userSpecificPermission
                  ? findCustomAttributeValueByKey(cad, recertificationSessionSpecificPermissionEntity.userSpecificPermission)
                  : ''}
              </dd>
            </React.Fragment>
          ))}
          <dt>
            <Translate contentKey="global.field.createdBy">Created By</Translate>:
          </dt>
          <dd>
            {recertificationSessionSpecificPermissionEntity.createdBy ? recertificationSessionSpecificPermissionEntity.createdBy : ''}
          </dd>
          <dt>
            <Translate contentKey="global.field.createdDate">Created Date</Translate>:
          </dt>
          <dd>
            {recertificationSessionSpecificPermissionEntity.createdDate ? (
              <TextFormat
                value={recertificationSessionSpecificPermissionEntity.createdDate}
                type="date"
                format={convertDateFormat(locale, 'instant')}
              />
            ) : (
              ''
            )}
          </dd>
          <dt>
            <Translate contentKey="global.field.lastModifiedBy">Last Modify By</Translate>:
          </dt>
          <dd>
            {recertificationSessionSpecificPermissionEntity.lastModifiedBy
              ? recertificationSessionSpecificPermissionEntity.lastModifiedBy
              : ''}
          </dd>
          <dt>
            <Translate contentKey="global.field.lastModifiedDate">Last Modify Date</Translate>:
          </dt>
          <dd>
            {recertificationSessionSpecificPermissionEntity.lastModifiedDate ? (
              <TextFormat
                value={recertificationSessionSpecificPermissionEntity.lastModifiedDate}
                type="date"
                format={convertDateFormat(locale, 'instant')}
              />
            ) : (
              ''
            )}
          </dd>
        </dl>
        <Button
          tag={Link}
          to={`/recertification-session/${
            recertificationSessionSpecificPermissionEntity.recertificationSession
              ? recertificationSessionSpecificPermissionEntity.recertificationSession.id
              : ''
          }`}
          replace
          color="info"
          data-cy="entityDetailsBackButton"
        >
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default RecertificationSessionSpecificPermissionDetail;
