import React, { useContext, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Input } from 'reactstrap';
import { Constant } from '../classes';
import { ExpressioNBuilderContext } from '../expression-builder';
import '../style.scss';
import { DropdownClickProps, ValueType } from '../types';
import { Constants, newNodeByValueAndType, parseNodeValue } from '../utils';
import NodeComponent from './node.component';

import { APP_GLOBAL_DATETIME_FORMAT, APP_GLOBAL_DATE_FORMAT } from 'app/config/constants';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';

interface ConstantComponentProps {
  node: Constant;
}

const ConstantComponent = ({ node }: ConstantComponentProps) => {
  const { tree, setTree } = useContext(ExpressioNBuilderContext);

  const [editing, setEditing] = useState<boolean>(false);
  const [value, setValue] = useState<string | number | boolean>(node.value);

  const save = () => {
    const newNode = newNodeByValueAndType(node.name, node.type) as Constant;
    newNode.value = value;
    tree.changeNode(node, newNode);
    setTree(tree);
    setEditing(false);
  };

  const onChangeClick = ({ value, type }: DropdownClickProps) => {
    const newNode = newNodeByValueAndType(value, type);
    tree.changeNode(node, newNode);
    setTree(tree);
  };

  const onBooleanChange = (val: boolean) => {
    const newNode = newNodeByValueAndType(node.name, node.type) as Constant;
    newNode.value = val;
    tree.changeNode(node, newNode);
    setTree(tree);
  };

  const onDateChange = (date: Date) => {
    const dateString = moment(date).format(node.name == Constants.CONST_DATE.name ? APP_GLOBAL_DATE_FORMAT : APP_GLOBAL_DATETIME_FORMAT);
    setValue(dateString);
  };

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      save();
    }
  };

  if (node.valueType == ValueType.BOOLEAN) {
    return (
      <div className="animate-first">
        <NodeComponent onChange={onChangeClick} items={node.getAlternatives()} type="change" color="white" />
        <div onClick={() => onBooleanChange(!value)}>
          <NodeComponent value={node.value} color={node.value ? 'green' : 'red'}>
            {node.value ? 'True' : 'False'}
          </NodeComponent>
        </div>
      </div>
    );
  } else if (node.valueType == ValueType.DATE) {
    if (editing) {
      return (
        <DatePicker
          onCalendarClose={() => save()}
          onBlur={() => save()}
          autoFocus
          showTimeSelect={node.name == Constants.CONST_DATETIME.name}
          selected={moment(value as string).toDate()}
          onChange={onDateChange}
        />
      );
    } else {
      return (
        <div className="animate-first">
          <NodeComponent onChange={onChangeClick} items={node.getAlternatives()} type="change" color="white" />
          <div onClick={() => setEditing(true)}>
            <NodeComponent value={node.value} color="orange">
              {node.value}
            </NodeComponent>
          </div>
        </div>
      );
    }
  } else {
    if (editing) {
      let parsedValue: string | number = null;
      if (node.valueType == ValueType.STRING) {
        parsedValue = String(value);
      } else if (node.valueType == ValueType.NUMBER) {
        parsedValue = Number(value);
      }
      return (
        <div>
          <Input
            autoFocus
            bsSize="sm"
            onBlur={() => save()}
            onChange={event => setValue(parseNodeValue(event.target.value, node.valueType))}
            onKeyPress={handleKeyPress}
            type={node.valueType === ValueType.STRING ? 'text' : 'number'}
            value={parsedValue}
          />
        </div>
      );
    } else {
      return (
        <div className="animate-first">
          <NodeComponent onChange={onChangeClick} items={node.getAlternatives()} type="change" color="white" />
          <div onClick={() => setEditing(true)}>
            <NodeComponent value={node.value} color="blue">
              {node.value}
            </NodeComponent>
          </div>
        </div>
      );
    }
  }
};

export default ConstantComponent;
